import { SodiumPlus, X25519PublicKey, X25519SecretKey } from 'sodium-plus';

export async function getClientPublicKey() {
  const sodium = await SodiumPlus.auto();

  const clientKeypair: any = await sodium.crypto_box_keypair();
  const clientSecret: any = await sodium.crypto_box_secretkey(clientKeypair);
  const clientPublic: any = await sodium.crypto_box_publickey(clientKeypair);

  const pKey = clientPublic.buffer.toString('base64');
  const sKey = clientSecret.buffer.toString('base64');
  return { publicKey: pKey, secretKey: sKey };
}

export async function getClientSecretKey() {
  const sodium = await SodiumPlus.auto();

  const clientKeypair: any = await sodium.crypto_box_keypair();
  const clientSecret: any = await sodium.crypto_box_secretkey(clientKeypair);
  const clientPublic: any = await sodium.crypto_box_publickey(clientKeypair);
  console.table({
    clientSecret: clientSecret.toString('base64'),
    clientPublic: clientPublic.toString('base64'),
    clientKeypair: clientKeypair,
  });
  const key = clientSecret.buffer.toString('base64');
  return key;
}
