import CreateBlastNotification from './CreateBlastNotification';
import EditBlastNotification from './EditBlastNotification';
import ListBlastNotifications from './ListBlastNotifications';
import ShowBlastNotification from './ShowBlastNotification';

export default {
  create: CreateBlastNotification,
  list: ListBlastNotifications,
  edit: EditBlastNotification,
  show: ShowBlastNotification,
};
