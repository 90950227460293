export const BlastNotificationFormValidation = (values: any) => {
  const errors = {} as any;

  if (!values.notificationTitle) {
    errors.notificationTitle = 'Notification title is required';
  }

  if (!values.notificationContent) {
    errors.notificationContent = 'Notification Content is required';
  }
  if (!values.userType) {
    errors.userType = 'User type is required';
  }

  if (values.schedule) {
    const currentDate = new Date();
    const scheduleDate = new Date(values.schedule);

    if (scheduleDate.getTime() <= currentDate.getTime()) {
      errors.schedule = 'Please select a future schedule date and time';
    }
  }

  return errors;
};

export const PersonalizedNotificationFormValidation = (values: any) => {
  const errors = {} as any;

  if (!values.notificationTitle) {
    errors.notificationTitle = 'Notification title is required';
  }

  if (!values.notificationContent) {
    errors.notificationContent = 'Notification Content is required';
  }

  return errors;
};
