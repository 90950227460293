//react-admin
import { SaveButton, Toolbar } from 'react-admin';

//hooks
import { useFormState } from 'react-final-form';
// -----------------------------------------------------------------------------------------------
/**
 * CustomToolbar component for rendering a custom toolbar with SaveButton.
 * @param {Object} props - Component props
 * @param {boolean} props.isValidationFailed - Indicates if form validation has failed
 * @returns {JSX.Element} - JSX element representing the CustomToolbar component
 */
const CustomToolbar = (props: any) => {
  const { isValidationFailed } = props;
  const { dirty, submitting, values } = useFormState();
  const label =
    values?.schedule && values.schedule != 'Invalid Date' ? 'Schedule' : 'Send';
  return (
    <Toolbar {...props}>
      <SaveButton
        label={label}
        disabled={!dirty || submitting || isValidationFailed}
      />
    </Toolbar>
  );
};

export default CustomToolbar;
