import {
  Collapse,
  List,
  ListItemIcon,
  MenuItem as MUIMenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { MenuItem, MultiLevelMenu } from '@rg-admin/ra-navigation';
import querystring from 'query-string';
import { FC, ReactElement, ReactNode, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { AppState } from '../../interface/types';
import visitors from '../../module/demo/visitors';
import { segments } from '../../module/demo/visitors/segments';
import { newCustomerFilter, visitorsFilter } from './Menu';
import products from '../../module/demo/products';
import orders from '../../module/demo/orders';
import invoices from '../../module/demo/invoices';

const CustomMobileMenu: FC<{
  logout?: ReactNode;
  onMenuClick?: () => void;
}> = ({ logout, onMenuClick }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [menuState, setMenuState] = useState({
    sales: false,
    catalog: false,
    customers: false,
    segments: false,
    reviews: false,
    demo: false,
  });
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
  const handleToggle = (
    menu: 'sales' | 'catalog' | 'customers' | 'segments' | 'reviews' | 'demo'
  ): void => {
    setMenuState((state) => ({ ...state, [menu]: !state[menu] }));
  };
  const DemoMenuItem = ({
    onMenuClick,
  }: {
    onMenuClick?: () => void;
  }): ReactElement => {
    return (
      <SubMenu
        icon={<visitors.icon />}
        handleToggle={(): void => handleToggle('demo')}
        isOpen={menuState.demo}
        sidebarIsOpen={open}
        label={translate('pos.menu.demo')}
      >
        <SubMenu
          icon={<visitors.icon />}
          handleToggle={(): void => handleToggle('customers')}
          isOpen={menuState.customers}
          sidebarIsOpen={open}
          label={translate(`resources.customers.name`, {
            smart_count: 2,
          })}
        >
          <MenuItem
            className={classes.noIconPadding}
            name="customers.all_customers"
            to={`/customers?filter={}`}
            onClick={onMenuClick}
            label={translate(`pos.menu.all_customers`, {
              smart_count: 2,
            })}
          />
          <MenuItem
            className={classes.noIconPadding}
            name="customers.newcomers"
            to={`/customers?filter=${JSON.stringify(newCustomerFilter)}`}
            onClick={onMenuClick}
            label={translate(`pos.menu.new_customers`, {
              smart_count: 2,
            })}
          />
          <MenuItem
            className={classes.noIconPadding}
            name="customers.visitors"
            to={`/customers?filter=${JSON.stringify(visitorsFilter)}`}
            onClick={onMenuClick}
            label={translate(`pos.menu.visitors`, {
              smart_count: 2,
            })}
          />
        </SubMenu>

        <SubMenu
          icon={<visitors.icon />}
          handleToggle={(): void => handleToggle('segments')}
          isOpen={menuState.segments}
          sidebarIsOpen={open}
          label={translate('resources.segments.name')}
        >
          {segments.map((segment) => (
            <MenuItem
              className={classes.noIconPadding}
              key={segment}
              name={`segments.${segment}`}
              to={`/customers?${querystring.stringify({
                filter: JSON.stringify({ groups: segment }),
              })}`}
              onClick={onMenuClick}
              label={translate(`resources.segments.data.${segment}`, {
                smart_count: 2,
              })}
            />
          ))}
        </SubMenu>

        <SubMenu
          icon={<products.icon />}
          handleToggle={(): void => handleToggle('sales')}
          isOpen={menuState.sales}
          sidebarIsOpen={open}
          label={translate(`pos.menu.sales`)}
        >
          <MenuItem
            name="commands"
            to="/commands"
            icon={<orders.icon />}
            onClick={onMenuClick}
            label={translate(`resources.commands.name`, {
              smart_count: 2,
            })}
          />
          <MenuItem
            name="invoices"
            to="/invoices?filter={}"
            icon={<invoices.icon />}
            onClick={onMenuClick}
            label={translate(`resources.invoices.name`, {
              smart_count: 2,
            })}
          />
        </SubMenu>
      </SubMenu>
    );
  };

  return (
    <div className={classes.menu}>
      <MultiLevelMenu>
        <MenuItem
          name="dashboard"
          to="/"
          exact
          label="ra.page.dashboard"
          icon={<DashboardIcon />}
          onClick={onMenuClick}
        />
        <DemoMenuItem />
        <span style={{ paddingLeft: 8 }}>{logout}</span>
      </MultiLevelMenu>
    </div>
  );
};

export default CustomMobileMenu;

const SubMenu: FC<{
  dense?: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  label: string;
  sidebarIsOpen: boolean;
}> = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  icon,
  label,
  children,
  dense = false,
}) => {
  const classes = useStyles();

  const header = (
    <MUIMenuItem
      className={classes.subMenuItem}
      dense={dense}
      button
      onClick={handleToggle}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {label}
      </Typography>
    </MUIMenuItem>
  );

  return (
    <>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={label} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          className={classes.subMenuContainer}
          dense={dense}
          component="div"
          disablePadding
        >
          {children}
        </List>
      </Collapse>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: '50vw',
  },
  icon: {
    minWidth: theme.spacing(5),
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  subMenuItem: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
  },
  subMenuContainer: {
    paddingLeft: theme.spacing(1.5),
  },
  noIconPadding: {
    paddingLeft: theme.spacing(1),
  },
}));
