import axios from 'axios';
import { AuthProvider } from 'react-admin';
import {
  api,
  getToken,
  getadminProfile,
  removeToken,
  setToken,
  setPermission,
  setUserProfile,
  removeUseProfile,
} from './api';
import { encrypt, decrypt } from '../utils/crypto';
import { getClientPublicKey } from '../component/common/EncryptionAndDescryption/ClientKeySet';
// decrypt,encrypt
const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const data = {
      email: username,
      password,
      realm: 'MYRX',
      userType: 'Admin',
    };
    const publicKey = (await getClientPublicKey())?.publicKey;
    localStorage.setItem('Public_Key', publicKey);
    return axios
      .get(`${process.env.REACT_APP_ADMIN_API_URL}/login`, {
        headers: {
          'Content-type': 'application/json',
          email: username,
          password: password,
          // Public_Key: publicKey,
        },
      })
      .then(async (response: any) => {
        // const { access_token, profile }: any = response.data['data'];
        const adminProfile = response?.data['data'];

        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        // if (profile.userType != 'Admin' || profile.userType != 'Superadmin') {
        //   throw new Error('You are not an administrator');
        // }

        // setPermission(encrypt(profile));
        const encryptedAdminProfile = encrypt(adminProfile);
        // return setToken(access_token);
        return setUserProfile(encryptedAdminProfile);
      })
      .catch((error) => {
        // return Promise.reject(error.response.data.message);
        return Promise.reject('Invalid email or password, please try again');
      });
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403 || status === 500) {
      removeToken();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return getadminProfile()
      ? Promise.resolve()
      : Promise.reject({ redirectTo: '/login' });
  },
  logout: () => {
    removeUseProfile();
    return Promise.resolve();
  },
  getIdentity: () => {
    const role: any = localStorage.getItem('permission') || '';
    const profile: any = role !== '' ? decrypt(role) : {};
    try {
      if (role && profile) {
        const { id, firstName: fullName, photo: avatar } = profile;
        return Promise.resolve({ id, fullName, avatar });
      }
      return Promise.reject();
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const role: any = localStorage.getItem('permission') || '';
    const profile: any = role !== '' ? decrypt(role) : {};
    return profile
      ? Promise.resolve(profile.userType)
      : Promise.resolve(profile);
  },
};

export default authProvider;
