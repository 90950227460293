import axios, { AxiosRequestConfig } from 'axios';
import { resolve } from 'dns';
import { api } from '../provider/api';

const uploadToCloudinary = async (folder: any, file: File) => {
  const s3BucketName: any = process.env.AWS_PUBLIC_S3BUCKET;
  const url = `${process.env.REACT_APP_ADMIN_API_URL}/s3/s3-zip-upload/${folder}`;

  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  const config: AxiosRequestConfig = {
    onUploadProgress: (progressEvent) => {
      const progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );

      // onProgress && onProgress(progress);
    },
  };

  localStorage.setItem('type', folder);
  // bodyFormData.append('bucketName', s3BucketName)

  return api.post(url, bodyFormData, config);
};

export const uploadZipFile: any = (folder: string, file: File) => {
  return uploadToCloudinary(folder, file);
};
