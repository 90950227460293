// react
import { useState } from 'react';

// react-admin
import {
  Edit,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

// components
import CustomToolbar from './components/CustomToolbar';
import BlastNotificationForm from './components/BlastNotificationForm';

// utils
import { ListActions } from '../../utils/common';
import { BlastNotificationFormValidation } from '../../utils/formValidations';

/**
 * Component for editing an existing blast notification.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the EditBlastNotification component
 */
const EditBlastNotification = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  // state
  const [isValidationFailed, setIsValidationFailed] = useState<boolean>(false);

  // Transform function to prepare payload for submission
  const transform = (data: any) => {
    const payload = {
      ...data,
    };
    if (payload.schedule == 'Invalid Date') {
      delete payload.schedule;
    }
    return payload;
  };

  const onSuccess = () => {
    notify(`Successfully updated.`, { type: 'success' });
    refresh();
    redirect('/blast-notification');
  };

  const onError = (error: any) => {
    notify(error, { type: 'error' });
  };

  // Custom validation function for the form
  const formValidation = (values: any) => {
    const errors = BlastNotificationFormValidation(values);
    const hasErrors = Object.keys(errors).some((field) => errors[field]);
    setIsValidationFailed(hasErrors);
    return hasErrors ? errors : {};
  };

  return (
    <>
      <Edit
        transform={transform}
        actions={<ListActions />}
        mutationMode="pessimistic"
        onSuccess={onSuccess}
        onFailure={onError}
        title="Edit Blast Notification"
        {...props}
      >
        <SimpleForm
          mode="onChange"
          validate={formValidation}
          toolbar={<CustomToolbar isValidationFailed={isValidationFailed} />}
        >
          <BlastNotificationForm />
        </SimpleForm>
      </Edit>
    </>
  );
};
export default EditBlastNotification;
