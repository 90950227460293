// react
import { FC } from 'react';

// react admin
import {
  CreateButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ListProps,
  TextField,
  TopToolbar,
} from 'react-admin';

// Components
import EmptyList from '../../component/EmptyList';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import {
  renderContentSliced,
  renderPlanType,
  renderPrice,
} from '../symptom/components/helperFunction';
import AsideFilter from './components/AsideFilter';

// utils
import { renderUserStatus } from '../../utils/helper';

// ----------------------------------------------------------------------------------------------------
const ListActions = (props) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
      {/* create option commented
      <CreateButton />  */}
    </TopToolbar>
  );
};

// Columns to be displayed in the datagrid
const columns = [
  <TextField source="plan" label="Plan" key="plan" />,
  <FunctionField
    label="Price"
    key="price"
    render={(record) => renderPrice(record?.price)}
  />,

  <FunctionField
    label="Plan Type"
    key="type"
    sortable={false}
    render={(record) => renderPlanType(record)}
  />,
  <TextField
    source="InAppReference"
    label="In App Reference"
    key="InAppReference"
  />,
  <FunctionField
    label="Details"
    key="planDetails"
    sortable={false}
    render={(record) => renderContentSliced(record?.planDetails, 50)}
  />,
  <FunctionField
    key="isActive"
    label="Status"
    sortable={false}
    render={(record) => (record ? renderUserStatus(record?.isActive) : null)}
  />,
  // edit option commented
  // <EditButton key="edit" />,
];

/**
 * ListSubscription component to  list subscription.
 * @param {ListProps} props - The props for the ListSubscription component.
 * @returns {JSX.Element} - JSX element representing the ListSubscription component.
 */
const ListSubscription: FC<ListProps> = (props) => {
  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      aside={<AsideFilter />}
      actions={<ListActions />}
      pagination={<PaginationWithCustomEmpty />}
      title="Subscription Plans"
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid
        rowClick="show" // row click leads to detailed show screen
        empty={<EmptyList resource={'Subscriptions'} />}
      >
        {columns}
      </Datagrid>
    </List>
  );
};

export default ListSubscription;
