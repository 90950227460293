// eslint-disable-next-line
import { Route } from 'react-router-dom';
import Segments from './module/demo/segments/Segments';
import TourLauncher from './module/demo/tours/TourLauncher';
import TourList from './module/demo/tours/TourList';
import { Dash } from './module/demo/dash';
export default [
    <Route key="segments" exact path="/segments" render={() => <Segments />} />,
    <Route key="dash" exact path="/dash" render={() => <Dash />} />,
    <Route key="tours" path="/tours" render={() => <TourList />} />,
    <Route
        key="tour-launcher"
        path="/tours/:tour"
        render={() => <TourLauncher />}
    />,
];
