import { Theme, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { FC } from 'react';
import { AppBar } from 'react-admin';
import { useLocation } from 'react-router-dom';
import Logo from './Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    // background: '#283593',
  },
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingLeft: theme.spacing(1),
    color: '#fff',
    textAlign: 'center',
  },
  logo: {
    padding: theme.spacing(0, 2),
  },
}));

const CustomAppBar: FC = (props) => {
  const classes = useStyles();
  const location = useLocation<{ pathname: string }>();

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  return (
    <AppBar className={classes.root} {...props} elevation={1}>
      {isXSmall ? (
        <>
          {location.pathname === '/' && <Logo className={classes.logo} />}
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
        </>
      ) : (
        <>
          <Logo className={classes.logo} />
          <Typography
            variant="h6"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
          />
        </>
      )}
    </AppBar>
  );
};

export default CustomAppBar;
