import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import { MedicineCreate } from './MedicineCreate';
import { MedicineEdit } from './MedicineEdit';
import MedicineList from './MedicineList';
import { MedicineView } from './MedicineView';

export default {
  list: MedicineList,
  create: MedicineCreate,
  edit: MedicineEdit,
  show: MedicineView,
  icon: LocalHospitalIcon,
};
