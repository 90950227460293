import {
  Create,
  ImageField,
  ImageInput,
  number,
  NumberInput,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
} from 'react-admin';
import { Button, Grid, makeStyles } from '@material-ui/core';
import './MedicineForm.css';
import { useState } from 'react';
import { useNotify, useRedirect } from 'ra-core';

const useStyles = makeStyles({
  card: {
    padding: 0,
    width: '100%',
  },
  disableTopMargin: {
    marginTop: '0px',
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0px 6px',
  },
});
export const MedicineCreate = (props) => {
  const notify = useNotify();
  const onFailure = (error) => {
    if (error.code == 123) {
      notify(
        'Could not save changes: concurrent edition in progress',
        'warning'
      );
    } else {
      notify(error, 'warning');
    }
  };

  const classes = useStyles();

  const [previousImg, setPreviousImg] = useState(true);

  const clickToCancel = () => {
    props.history.push(`/medication`);
  };

  const CustomToolbar: React.FC = (props: any) => (
    <Toolbar {...props}>
      <SaveButton
        label="Save"
        transform={(data) => ({ ...data, notify: true })}
        submitOnEnter={false}
      />
      <Button
        className={classes.cancelButton}
        onClick={clickToCancel}
        variant="contained"
      >
        CANCEL
      </Button>
    </Toolbar>
  );

  return (
    <Create undoable={false} {...props} onFailure={onFailure}>
      <SimpleForm toolbar={<CustomToolbar />} redirect="list">
        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="hamcode" />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="category" />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="subcategory" />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={4}>
            <TextInput
              validate={required()}
              fullWidth
              source="finest_category"
            />
          </Grid>
          <Grid item xs={4}>
            <NumberInput
              validate={[required(), number()]}
              fullWidth
              source="flc"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="upc_10" />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="upc_11" />
          </Grid>

          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="upc_12" />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="gtin" />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={6}>
            <TextInput
              fullWidth
              validate={required()}
              source="description"
              multiline={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              validate={required()}
              fullWidth
              source="internet_desc"
              multiline={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="product_size" />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              validate={required()}
              fullWidth
              source="unit_of_measure"
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="brand" />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="manufacturer" />
          </Grid>
          <Grid item xs={4}>
            <TextInput fullWidth source="marketing_language" />
          </Grid>
          <Grid item xs={4}>
            <TextInput validate={required()} fullWidth source="active_ingred" />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={6}>
            <TextInput fullWidth source="inactive_ingred" multiline={true} />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              validate={required()}
              fullWidth
              source="uses"
              multiline={true}
            />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={6}>
            <TextInput
              fullWidth
              validate={required()}
              source="directions"
              multiline={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput validate={required()} fullWidth source="purpose" />
          </Grid>
        </Grid>

        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={6}>
            <TextInput
              validate={required()}
              fullWidth
              source="warnings"
              multiline={true}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput fullWidth source="dnums" />
          </Grid>
        </Grid>
        {/* 
        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={6}>
            <ImageInput
              source="pictures"
              label="Change Image"
              placeholder="Drop a picture to upload, or click to select it."
              accept="image/*"
              onChange={handleImageChange}
            >
              <ImageField source="image" title="title" />
            </ImageInput>

            {previousImg && <ImageField source="image" title="title" />}
          </Grid>
        </Grid> */}
      </SimpleForm>
    </Create>
  );
};
