import { FC } from 'react';
import { Card as MuiCard, CardContent } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOnOutlined';
import MailIcon from '@material-ui/icons/MailOutline';
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import { SavedQueriesList } from '@rg-admin/ra-preferences';
import {
  endOfYesterday,
  startOfWeek,
  subWeeks,
  startOfMonth,
  subMonths,
} from 'date-fns';

import segments from '../segments/data';

const Card = withStyles((theme) => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch />
      {/* <span id="persisted-queries">
        <SavedQueriesList />
      </span> */}
      <FilterList
        label="resources.customers.filters.last_visited"
        icon={<AccessTimeIcon />}
      >
        <FilterListItem
          label="resources.customers.filters.today"
          value={{
            last_seen_gte: endOfYesterday().toISOString(),
            last_seen_lte: undefined,
          }}
        />
        <FilterListItem
          label="resources.customers.filters.this_week"
          value={{
            last_seen_gte: startOfWeek(new Date()).toISOString(),
            last_seen_lte: undefined,
          }}
        />
        <FilterListItem
          label="resources.customers.filters.last_week"
          value={{
            last_seen_gte: subWeeks(startOfWeek(new Date()), 1).toISOString(),
            last_seen_lte: startOfWeek(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="resources.customers.filters.this_month"
          value={{
            last_seen_gte: startOfMonth(new Date()).toISOString(),
            last_seen_lte: undefined,
          }}
        />
        <FilterListItem
          label="resources.customers.filters.last_month"
          value={{
            last_seen_gte: subMonths(startOfMonth(new Date()), 1).toISOString(),
            last_seen_lte: startOfMonth(new Date()).toISOString(),
          }}
        />
        <FilterListItem
          label="resources.customers.filters.earlier"
          value={{
            last_seen_gte: undefined,
            last_seen_lte: subMonths(startOfMonth(new Date()), 1).toISOString(),
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
