// react-admin
import { useRecordContext } from 'react-admin';
// mui
import { Avatar } from '@material-ui/core';
import { Box, Typography } from '@mui/material';
// -------------------------------------------------------------------------------------------------
/**
 * DrugDetails component for rendering drug image and name.
 * @returns {JSX.Element} - JSX element representing the DrugDetails component.
 */
const DrugDetails = () => {
  const record = useRecordContext();

  // If no record or drug details are present, render placeholder
  if (!record || !record?.drugDetails || record?.drugDetails.length === 0)
    return <>--</>;
  return (
    <Box display="flex" flexDirection="column" mt={2} gap={2}>
      {record?.drugDetails.map(
        (drugDetail, index) =>
          drugDetail && (
            <Box key={index} display="flex" gap={2} alignItems="center">
              <Avatar
                src={
                  drugDetail?.image ? drugDetail.image : '/rx_place_holder.png'
                }
                alt={drugDetail?.drugName}
                variant="square"
                style={{
                  width: 70,
                  height: 80,
                  borderRadius: '5px',
                }}
              />
              <Typography fontSize="12px">{drugDetail?.drugName}</Typography>
            </Box>
          )
      )}
    </Box>
  );
};

export default DrugDetails;
