// in src/comments.js
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';

import {
  EditButton,
  EmailField,
  TextField,
  useListContext,
  useTranslate,
} from 'react-admin';
import AvatarField from '../../../component/AvatarField';

const useStyles = makeStyles((theme) => ({
  root: { margin: '1em' },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  name: {
    textTransform: 'capitalize',
  },
  cardContent: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

const MobileGrid: FC = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { ids, data, basePath } = useListContext(props);

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardHeader
            title={
              <div className={classes.cardTitleContent}>
                <h2 className={classes.name}>{`${data[id].firstName}`}</h2>
                <EditButton
                  resource="users/all"
                  basePath={basePath}
                  record={data[id]}
                />
              </div>
            }
            avatar={<AvatarField record={data[id]} size="45" />}
          />
          <CardContent className={classes.cardContent}>
            <div>
              {translate('resources.users.fields.email')}
              &nbsp;
              <EmailField record={data[id]} source="email" />
            </div>
            <div>
              {translate('resources.users.fields.gender')}
              &nbsp;:&nbsp;
              <TextField record={data[id]} source="gender" />
            </div>
            <div>
              {translate('resources.users.fields.status')}
              &nbsp;:&nbsp;
            </div>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default MobileGrid;
