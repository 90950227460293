// react
import { FC } from 'react';
// react-admin
import { FilterWithSave } from '@rg-admin/ra-preferences';
import {
  Datagrid,
  EmailField,
  FunctionField,
  ListProps,
  SearchInput,
  TopToolbar,
} from 'react-admin';
// mui
import { Theme, useMediaQuery } from '@material-ui/core';
// components
import EmptyList from '../../component/EmptyList';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import EnterpriseList from '../../component/ra-enterprise/List';
import CustomerLinkField from './components/CustomerLinkField';
import DesktopGrid from './components/DesktopGrid';
import MobileGrid from './components/MobileGrid';
import Aside from './components/UsersListAside';
// helper functions
import { renderAge, renderGender, renderUserStatus } from '../../utils/helper';
// ---------------------------------------------------------------------------------------------------
const UserFilter: FC = (props: any) => (
  <FilterWithSave {...props}>
    <SearchInput source="searchKey" alwaysOn />
  </FilterWithSave>
);

const UserListColumn = [
  <CustomerLinkField sortable={false} key="user" />,
  <EmailField sortable={false} label="Email" source="email" key="email" />,
  <FunctionField
    key="gender"
    label="Gender"
    sortable={false}
    source="gender"
    render={(record) => renderGender(record)}
  />,
  <FunctionField
    key="age"
    sortable={false}
    label="Age"
    render={(record: any) => renderAge(record)}
  />,
  <FunctionField
    key="isActive"
    label="Status"
    sortable={false}
    source="isActive"
    render={(record) => (record ? renderUserStatus(record?.isActive) : null)}
  />,
];
const ListActions = (props: any) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
    </TopToolbar>
  );
};
/**
 * ListSignedUpUsers component for displaying a list of signed-up users.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the ListSignedUpUsers component
 */
const ListSignedUpUsers: FC<ListProps> = (props) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <EnterpriseList
      {...props}
      preferenceKey="signed-up-users.list"
      hasColumnsSelector={false}
      bulkActionButtons={false}
      defaultColumns={UserListColumn}
      hasViewSelector
      filters={isSmall ? <UserFilter /> : undefined}
      aside={<Aside />}
      actions={<ListActions />}
      perPage={25}
      exporter={false}
      hasCreate={false}
      title="Signed-Up Users"
      pagination={<PaginationWithCustomEmpty />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      {({
        view,
        hasShow,
        hasList,
        hasEdit,
        syncWithLocation,
        ...rest
      }): JSX.Element => {
        if (isXsmall) {
          return <MobileGrid />;
        }
        if (view === 'grid') {
          return <DesktopGrid fields={UserListColumn} />;
        }
        return (
          <Datagrid
            noDelete
            // rowClick="show"
            empty={<EmptyList resource={'Users'} />}
            {...rest}
          >
            {UserListColumn}
          </Datagrid>
        );
      }}
    </EnterpriseList>
  );
};

export default ListSignedUpUsers;
