import { Theme, useMediaQuery } from '@material-ui/core';
import { useDefineAppLocation } from '@rg-admin/ra-navigation';
import { FilterWithSave } from '@rg-admin/ra-preferences';
import { FC } from 'react';
import {
  Datagrid,
  EmailField,
  FunctionField,
  ListProps,
  SearchInput,
  TopToolbar,
} from 'react-admin';
import EmptyList from '../../component/EmptyList';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';
import {
  isActive,
  renderAge,
  renderGender,
  renderUserStatus,
} from '../../utils/helper';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import EnterpriseList from '../../component/ra-enterprise/List';
import CustomerLinkField from './CustomerLinkField';
import DesktopGrid from './DesktopGrid';
import MobileGrid from './MobileGrid';
import SegmentInput from './SegmentInput';
import UsersListAside from './UsersListAside';

const UserFilter: FC = (props: any) => (
  <FilterWithSave {...props}>
    <SearchInput source="searchKey" alwaysOn />
    <SegmentInput />
  </FilterWithSave>
);

const UserListColumn = {
  firstName: <CustomerLinkField sortable={false} />,
  email: <EmailField sortable={false} label="Email" source="email" />,
  gender: (
    <FunctionField
      label="Gender"
      sortable={false}
      source="gender"
      render={(record) => renderGender(record)}
    />
  ),
  age: (
    <FunctionField
      sortable={false}
      label="Age"
      render={(record: any) => renderAge(record)}
    />
  ),
  isActive: (
    <FunctionField
      label="Activation Status"
      sortable={false}
      source="isActive"
      render={(record) => (record ? renderUserStatus(record?.isActive) : null)}
    />
  ),
};
const ListActions = (props: any) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
    </TopToolbar>
  );
};

const UserList: FC<ListProps> = (props) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  useDefineAppLocation('users/all');
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <EnterpriseList
      {...props}
      preferenceKey="visitors.list"
      hasColumnsSelector={false}
      bulkActionButtons={false}
      defaultColumns={UserListColumn}
      hasViewSelector
      filters={isSmall ? <UserFilter /> : undefined}
      aside={<UsersListAside />}
      actions={<ListActions />}
      perPage={25}
      exporter={false}
      hasCreate={false}
      title="Users"
      pagination={<PaginationWithCustomEmpty />}
      sort={{ field: 'id', order: 'DESC' }}
    >
      {({
        columns,
        view,
        hasShow,
        hasList,
        hasEdit,
        syncWithLocation,
        ...rest
      }): JSX.Element => {
        if (isXsmall) {
          return <MobileGrid />;
        }
        if (view === 'grid') {
          return <DesktopGrid fields={columns} />;
        }
        return (
          <Datagrid
            noDelete
            rowClick="show"
            empty={<EmptyList resource={'Users'} />}
            {...rest}
          >
            {columns}
          </Datagrid>
        );
      }}
    </EnterpriseList>
  );
};

export default UserList;
