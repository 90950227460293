import { FC } from 'react';
import {
  DateField,
  useTranslate,
  useGetList,
  TextField,
  Record as RaRecord,
  ListProps,
} from 'react-admin';
import PropTypes from 'prop-types';
import { Typography, Card, CardContent, Box } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

import { makeStyles } from '@material-ui/core/styles';
import { Order, Review } from '../../interface/types';

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const Aside: FC<{ record?: RaRecord; basePath?: string }> = ({
  record,
  basePath,
}) => {
  const classes = useAsideStyles();
  return (
    <div className={classes.root}>
      {record && <EventList record={record} basePath={basePath} />}
    </div>
  );
};

Aside.propTypes = {
  record: PropTypes.any,
  basePath: PropTypes.string,
};

const EventList: FC<ListProps & { record?: RaRecord }> = ({ record }) => {
  const translate = useTranslate();

  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {translate('resources.users.fieldGroups.history')}
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {translate('resources.users.fields.updated_at')}
                    </Typography>
                    <DateField record={record} source="updatedAt" />
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {translate('resources.users.fields.updated_by')}
                    </Typography>
                    <TextField source="updatedBy" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
export default Aside;
