import { Link } from 'react-admin';
import PureFullNameField from '../../../component/FullNameField';

const renderUrl = (record: any) => {
  if (record.user?.userType === 'profile') {
    return `/users/all/${record?.user?.id}/show?userId=${record?.user?.accountId}`;
  } else
    return `/users/all/${record?.user?.id ? record?.user?.id : record.id}/show`;
};
const CustomerLinkField = (props) =>
  props.record ? (
    <Link to={renderUrl(props.record)}>
      <PureFullNameField {...props} />
    </Link>
  ) : null;

CustomerLinkField.defaultProps = {
  source: 'firstName',
  addLabel: true,
  label: 'resources.users.fields.firstName',
};

export default CustomerLinkField;
