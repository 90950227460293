// react
import { FC } from 'react';

// react admin
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';

// mui
import { CardContent, Card as MuiCard, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Iconify
import Iconify from '../../../component/iconify/Iconify';
import { Box } from '@mui/material';
import { TRIGGERS } from '../../../utils/constants';

const Card = withStyles((theme) => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch source={'searchKey'} />
      <Typography variant="body2" style={{ color: 'grey' }}>
        Search Plan
      </Typography>

      <FilterList
        label="Plan Type"
        icon={<Iconify icon="ph:currency-circle-dollar" />}
      >
        <FilterListItem
          label="Free"
          value={{
            type: 'FREE',
          }}
        />
        <FilterListItem
          label="Paid"
          value={{
            type: 'PAID',
          }}
        />
      </FilterList>

      <FilterList label="Is Active?" icon={<Iconify icon="mdi:list-status" />}>
        <FilterListItem
          label="Active"
          value={{
            isActive: 1,
          }}
        />
        <FilterListItem
          label="Inactive"
          value={{
            isActive: 0,
          }}
        />
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
const RenderIcon = ({ icon }: any) => {
  return <Iconify icon={icon} />;
};
