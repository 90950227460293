import VisitorIcon from '@material-ui/icons/People';
import UserDetailsEdit from './UserDetailsShow';
import UserDetailsView from './UserDetailsView';
import UserList from './UserList';

export default {
  list: UserList,
  // create: VisitorCreate,
  edit: UserDetailsEdit,
  show: UserDetailsView,
  icon: VisitorIcon,
};
