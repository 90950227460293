import { useTheme } from '@material-ui/core';
import { Admin } from '@rg-admin/ra-enterprise';
import { reducer as tree } from '@rg-admin/ra-tree';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { ReactElement } from 'react';
import { Resource } from 'react-admin';
import './App.css';
import { Layout, Login } from './component/layout';
import { getThemes } from './component/layout/themes';
import englishMessages from './i18n/en';
import { Dashboard } from './module/dashboard';
import authProvider from './provider/authProvider';
// demo
import users from './module/user-management';
//custom route
import { createBrowserHistory } from 'history';
import blastNotification from './module/blast-notification';
import faq from './module/faq';
import medicine from './module/medicine';
import MedicineUpload from './module/medicine/MedicineUpload';
import MedicineZipFileUpload from './module/medicine/MedicineZipFileUpload';
import personalizedNotifications from './module/personalized-notifications';
import symptoms from './module/symptom';
import { DataProvider } from './provider/dataProvider';
import customRoutes from './routes';
import ListSignedUpUsers from './module/signed-up-users/ListSignedUpUsers';
import ListSubscribedUsers from './module/subscribed-users/ListSubscribedUsers';
import { Analytics } from './module/analytics';
import subscription from './module/subscription';

// bugsnag
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

//constants
import { BUGSNAG_API_KEY, RELEASE_STAGE } from './utils/constants';

const i18nProvider = polyglotI18nProvider(
  () => {
    return englishMessages;
  },
  'en',
  { allowMissing: true }
);

// const enhancedDataProvider = addEventsForMutations(dataProvider, authProvider);

const App = (): ReactElement => {
  const theme = useTheme();
  const { darkTheme, lightTheme } = getThemes(theme);
  const history = createBrowserHistory({ basename: process.env.PUBLIC_URL });

  //bugsnag
  if (BUGSNAG_API_KEY && RELEASE_STAGE) {
    Bugsnag.start({
      apiKey: `${BUGSNAG_API_KEY}`,
      plugins: [new BugsnagPluginReact()],
      releaseStage: `${RELEASE_STAGE}`,
      enabledReleaseStages: ['production'],
    });
  }

  return (
    <Admin
      title="MyRX Admin"
      history={history}
      dataProvider={DataProvider}
      customRoutes={customRoutes}
      customReducers={{ tree }}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      i18nProvider={i18nProvider}
      // Ra-enterprise configuration
      lightTheme={lightTheme}
      darkTheme={darkTheme}
      disableTelemetry
    >
      <Resource name="users/all" {...users} />
      <Resource name="users/signed-up-users" list={ListSignedUpUsers} />
      <Resource name="users/subscribed-users" list={ListSubscribedUsers} />
      <Resource name="medication" {...medicine} />
      <Resource name="review" {...faq} />
      <Resource name="Profile" />
      <Resource name="Medications" />
      <Resource name="medicationsBulkFileUpload" list={MedicineUpload} />
      <Resource name="MedicineZipFileUpload" list={MedicineZipFileUpload} />
      {/* Module name is still symptoms */}
      <Resource name="symptoms" {...symptoms} />
      <Resource name="blast-notification" {...blastNotification} />
      <Resource
        name="personalized-notification"
        {...personalizedNotifications}
      />
      {/*  Otherwise ReferenceArrayInput throws error */}
      <Resource name="drugList" />
      <Resource name="analytics" list={Analytics} />
      <Resource name="subscription" {...subscription} />
    </Admin>
  );
};

export default App;
