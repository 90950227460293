// mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

export const ConfirmModal = (props: any) => {
  const {
    isOpen,
    loading,
    title,
    contentText,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      sx={{
        '.MuiPaper-root': {
          padding: '1em',
          maxHeight: 'auto',
          maxWidth: 'auto',
          backgroundColor: '#fff',
        },
      }}
    >
      <DialogTitle sx={{ color: '#463d51' }}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ color: '#463d51', fontWeight: 500 }}>
          {contentText}
        </DialogContentText>
      </DialogContent>
      <Box
        display="flex"
        mt={2}
        gap={1}
        flexDirection={{ xs: 'row-reverse', sm: 'row-reverse' }}
      >
        <LoadingButton
          loading={loading}
          onClick={onConfirm}
          disabled={loading}
          size="small"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
            backgroundColor: loading ? '#7b7287' : '#283593',
            color: loading ? '#7b7287' : '#FFF',
            border: '1px solid #c4c4c4',
            width: '100px',
            borderRadius: '8px',
          }}
        >
          {confirmText}
        </LoadingButton>
        <LoadingButton
          disabled={loading}
          onClick={onCancel}
          size="small"
          style={{
            fontSize: '14px',
            fontWeight: 400,
            textAlign: 'center',
            backgroundColor: loading ? '#7b7287' : '#283593',
            color: '#FFF',
            border: '1px solid #c4c4c4',
            width: '100px',
            borderRadius: '8px',
          }}
        >
          {cancelText}
        </LoadingButton>
      </Box>
    </Dialog>
  );
};
