// import SubscriptionCreate from './CreateSubscription';
import SubscriptionList from './ListSubscription';
// import SubscriptionEdit from './EditSubscription';
import SubscriptionShow from './ShowSubscription';

export default {
  // create: SubscriptionCreate,
  // edit: SubscriptionEdit,
  list: SubscriptionList,
  show: SubscriptionShow,
};
