//react-admin
import { SaveButton, Toolbar } from 'react-admin';

//hooks
import { useFormState } from 'react-final-form';
// -------------------------------------------------------------------------------------------------
/**
 * CustomToolbar component for rendering a custom toolbar with SaveButton.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the CustomToolbar component
 */
const CustomToolbar = (props: any) => {
  const { isValidationFailed } = props;
  const { dirty, submitting, values } = useFormState();

  // Function to validate if content or notificationData's content is present
  const validate = () =>
    !(
      values?.content ||
      (values?.notificationData && values?.notificationData[0]?.content)
    );
  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={!dirty || submitting || isValidationFailed || validate()}
      />
    </Toolbar>
  );
};

export default CustomToolbar;
