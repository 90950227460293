import { stringify } from 'query-string';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
  UPDATE_MANY,
} from 'react-admin';
import {
  dateFormatChange,
  decryption,
  encryptionFields,
  parseDataBasedOnKey,
} from '../component/common';
import encryption from '../component/common/EncryptionAndDescryption/Encryption';
import { getS3ImgUrl } from '../hooks/useUploadFile';
// import HttpError from './HttpError';
import { api } from './api';

export const DataProvider = (type: string, resource: string, params: any) => {
  switch (type) {
    case GET_LIST: {
      // console.log('resource is :', { resource });
      const { page, perPage } = params.pagination;
      const { filter, sort, startDate, endDate } = params;
      const query = {
        limit: perPage,
        skip: (page - 1) * perPage,
        startDate,
        endDate,
        ...filter,
        ...sort,
      };

      let url: any = `/${resource}?${stringify(query)}`;
      if (resource == 'Profile') {
        // url = `/profile/users/all-profiles/${Number(
        //   params?.filter?.id
        // )}?${stringify(query)}`;
        url = `/adminprofile/profiles?${stringify(query)}`;
      } else if (resource === 'profile/users') {
        url = `/users?${stringify(query)}`;
      } else if (resource === 'Medications') {
        url = `/getMedications?${stringify(query)}`;
      } else if (resource === 'FAQ') {
        url = `/reviews?${stringify(query)}`;
      } else if (resource === 'review') {
        url = `/reviews?${stringify(query)}`;
      } else if (resource === 'dashboard') {
        url = `/${resource}?${stringify(query)}`;
      } else if (resource === 'medication') {
        url = `/drugs?${stringify(query)}`;
      } else if (resource === 'symptoms') {
        url = `/symptoms/admin/all?${stringify(query)}`;
      } else if (resource === 'blast-notification') {
        url = `/blastnotification/blastnotification?${stringify(query)}`;
      } else if (resource === 'personalized-notification') {
        url = `/personalizednotification/personalizednotification?${stringify(
          query
        )}`;
      } else if (resource === 'subscription') {
        url = `/subscription/getAll?${stringify(query)}`;
      } else if (resource === 'drugList') {
        const query = {
          searchKey: filter.q,
        };

        url = `/drugList?${stringify(query)}`;
      } else if (resource === 'analytics') {
        return Promise.resolve({
          // dummy
          data: [{ id: 1 }],
          total: 1,
        });
      }

      return api.get(url).then(async (response: any) => {
        let finalData: any = [];
        switch (resource) {
          case 'Medications':
            // finalData = await convertToArrayOfObjectKey(response.data.data);

            return {
              data: response.data.data,
              total: Number(response.data.count),
            };
          case 'Profile':
            // finalData = await convertToArrayOfObjectKey(response.data.data);
            const accountId = filter?.accountId;
            finalData = await response?.data?.Profile.filter(
              (data: any) => data?.id != accountId
            );
            return {
              data: finalData,
              total:
                finalData?.length === 0 ? 0 : Number(response.data.total - 1),
            };
          case 'profile/users':
            // finalData = await convertToArrayOfObjectKey(response.data.data);
            finalData = response.data.data;

            return {
              data: finalData,
              total: Number(response.data.total),
            };
          case 'FAQ':
            finalData = response?.data?.dataList;
            return {
              data: finalData,
              total: Number(response?.data?.total),
            };
          case 'review':
            finalData = response?.data?.dataList;
            return {
              data: finalData,
              total: Number(response?.data?.total),
            };
          case 'drugList':
            finalData = response.data.data.map((resource: any) => ({
              ...resource,
              id: resource.drugs,
            }));
            return {
              data: finalData,
              total: Number(response?.data?.total),
            };
          default:
            return {
              data: response.data.data,
              total: Number(response.data.total),
            };
        }
      });
    }

    case GET_ONE: {
      // console.log('Resource', resource);
      // console.log('params', { params });

      let url = `/${resource}/${params.id}`;
      if (resource == 'profiles') {
        url = `/profile/users/${params.id}`;
      } else if (resource == 'users/all') {
        const queryparams = new URLSearchParams(window.location.search);
        let query;
        if (queryparams.has('userId')) {
          query = {
            accountId: queryparams.get('userId'),
            profileId: params?.id,
          };
        } else
          query = {
            accountId: params?.id,
          };

        url = `/adminprofile/profile?${stringify(query)}`;
      } else if (resource === 'review') {
        const queryParams = new URLSearchParams(window.location.search);
        const query = {
          id: params?.id,
          userType: queryParams.get('userType'),
        };
        url = `/reviews?${stringify(query)}`;
      } else if (resource === 'symptoms') {
        url = `/symptoms/admin/${params.id}`;
      } else if (resource === 'blast-notification') {
        const query = {
          id: params?.id,
        };
        url = `/blastnotification/blast?${stringify(query)}`;
      } else if (resource === 'personalized-notification') {
        const query = {
          id: params?.id,
        };
        url = `/personalizednotification/personalized?${stringify(query)}`;
      } else if (resource === 'subscription') {
        url = `/subscription/getPlan/${params.id}`;
      }
      return api
        .get(url)
        .then(async (response: any) => {
          switch (resource) {
            // case 'profile/users':
            //   const finalData: any = await convertToObjectKey(
            //     response.data.data
            //   );
            //   if (finalData.DOB) {
            //     finalData.DOB = dateFormatChangeToRender(finalData.DOB);
            //   }
            //   return { data: finalData };
            case 'users/all':
              return { data: response.data.profile };

            default:
              return { data: response.data.data };
          }
        })
        .catch((error) => {
          return ErrorMessage(error);
        });
    }

    case GET_MANY: {
      if (resource === 'drugList') {
        const query = {
          drugNumber: params.ids[0],
        };
        const url = `${resource}?${stringify(query)}`;
        return api
          .get(url)
          .then((response: any) => {
            const modifiedData = response.data.data.map((resource: any) => ({
              ...resource,
              id: resource.drugs,
            }));
            return { data: modifiedData };
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      }
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      };
      const url = `${resource}?${stringify(query)}`;
      return api
        .get(url)
        .then((response: any) => ({ data: response.data }))
        .catch((error) => {
          return ErrorMessage(error);
        });
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const rangeStart = (page - 1) * perPage;
      const rangeEnd = page * perPage - 1;

      const query = {
        sort: JSON.stringify([field, order]),
        page: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        range: JSON.stringify([rangeStart, rangeEnd]),
        filter: JSON.stringify({
          ...params.filter,
          [params.target]: params.id,
        }),
      };
      const url = `${resource}?${stringify(query)}`;
      return api
        .get(url)
        .then((response: any) => ({ data: response.data, total: 0 }))
        .catch((error) => {
          return ErrorMessage(error);
        });
    }
    case CREATE: {
      let url = '';
      switch (resource) {
        case 'FAQ':
          url = `/faq/add-or-update`;
          break;
        case 'medication':
          url = `/${resource}/add-or-update-medicine`;
          break;
        case 'blast-notification':
          url = `/blastnotification/create-blast`;
          break;
        case 'personalized-notification':
          url = `/personalizednotification`;
          break;
        default:
          url = `/${resource}`;
          break;
      }
      const body = JSON.stringify(params.data);

      if (resource === '/medication') {
        // return getS3ImgUrl(params.data).then((response) => {
        //   data.image = response;
        return api
          .post(url, body)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
        // });
      } else {
        return api
          .post(url, body)
          .then((response: any) => {
            switch (resource) {
              case 'case1':
                return { data: response.data.data };
              default:
                return { data: response.data.data };
            }
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      }
    }

    case UPDATE: {
      let url = '';
      const data = params.data;
      switch (resource) {
        case 'profile/users':
          url = `/on-boarding/admin-update`;
          break;
        case 'FAQ':
          url = `/faq/add-or-update`;
          break;
        case 'medication':
          params.data.flc = Number(params.data?.flc);
          url = `/${resource}/add-or-update-medicine`;
          break;
        case 'blast-notification':
          url = `/blastnotification`;
          break;
        case 'personalized-notification':
          url = `/personalizednotification`;
          break;
        default:
          url = `/${resource}/${params.id}`;
          break;
      }
      const body = JSON.stringify(params.data);
      if (resource === 'medication') {
        return api
          .post(url, body)
          .then((response) => {
            return { data: response.data.data };
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      } else if (resource === 'profile/users') {
        return getS3ImgUrl(params.data?.photo?.url, 'profile').then(
          async (response) => {
            if (response?.data) {
              data.photo = {
                url: response?.data?.Location,
                key: response?.data?.Key,
                fileType: params.data?.photo?.rawFile?.type,
                fileMeta: {
                  size: params.data?.photo?.rawFile?.size,
                  originalname: params.data?.photo?.rawFile?.name,
                },
              };
              // } else if (params.data?.photo?.url == null)
              // {
              //   data.photo = null;
            }
            if (data.DOB) {
              data.DOB = dateFormatChange(data.DOB);
            }
            const dataset = await convertToObjectEncryptedKey(data);
            return api
              .post(url, dataset)
              .then((response) => {
                return { data: response.data.data };
              })
              .catch((error) => {
                return ErrorMessage(error);
              });
          }
        );
      } else if (resource === 'FAQ') {
        return api
          .post(url, body)
          .then((response) => {
            return { data: response.data.data };
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      } else if (resource === 'blast-notification') {
        return api
          .put(url, body)
          .then((response) => {
            return { data: response.data.data };
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      } else if (resource === 'personalized-notification') {
        return api
          .put(url, body)
          .then((response) => {
            return { data: response.data.data };
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      } else if (resource === 'subscription') {
        return api
          .put(url, body)
          .then((response) => {
            return { data: response.data.data };
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      } else {
        return api
          .patch(url, body)
          .then((response: any) => {
            switch (resource) {
              case 'case1':
                return { data: response.data.data };
              default:
                return { data: response.data.data };
            }
          })
          .catch((error) => {
            return ErrorMessage(error);
          });
      }
    }
    case UPDATE_MANY: {
      return Promise.all(
        params.ids.map((id) => {
          let url = '';
          switch (resource) {
            case 'case1':
              url = `/case1/update/${id}`;
              break;
            default:
              url = `${resource}/${id}`;
              break;
          }
          const body = JSON.stringify(params.data);
          api.patch(url, body);
        })
      )
        .then((responses) => ({ data: responses.map((json: any) => json.id) }))
        .catch((error) => {
          return Promise.reject(error.response.data.message);
        });
    }

    case DELETE: {
      const data = params.previousData || params.data || {};
      const { id } = data;
      let url = '';
      switch (resource) {
        case 'case1/delete':
          url = ``;
          break;
        default:
          url = `/${resource}/${id}`;
          break;
      }
      return api.del(url).then(() => ({ data }));
    }

    case DELETE_MANY: {
      return Promise.all(params.ids.map((id) => api.del(`${resource}/${id}`)))
        .then((responses) => ({
          data: responses.map((response: any) => response.id),
        }))
        .catch((error) => {
          return Promise.reject(error.response.data.message);
        });
    }

    // custom get data provider function
    case 'getData': {
      return api
        .get(resource)
        .then(async (response: any) => {
          return { data: response.data };
        })
        .catch((error) => {
          return ErrorMessage(error);
        });
    }

    default: {
      return Promise.reject('Error');
    }
  }
};

function ErrorMessage(error) {
  return Promise.reject(error.response.data.message);
}

// decrypted value assign the key of object

async function convertToObjectKey(obj) {
  const x = {};

  for (const [key, value] of Object.entries(obj)) {
    if (encryptionFields(key) && value) {
      if (typeof value == 'object') {
        const y = {};
        for (const [key2, value2] of Object.entries(obj[key])) {
          if (encryptionFields(key2) && value2) {
            const data = await decryption(value2);
            y[key2] = await parseDataBasedOnKey(key2, data);
          } else {
            y[key2] = value2;
          }
        }
        x[key] = y;
      } else {
        const data = await decryption(value);
        x[key] = await parseDataBasedOnKey(key, data);
      }
    } else {
      x[key] = value;
    }
  }

  return x;
}
async function convertToArrayOfObjectKey(array) {
  const tempArr: any = await Promise.all(
    array.map(async (obj: any) => {
      const x: any = {};
      obj = obj?.user ? obj?.user : obj;
      for (const [key, value] of Object.entries(obj)) {
        if (encryptionFields(key) && value) {
          if (typeof value == 'object') {
            const y = {};
            for (const [key2, value2] of Object.entries(obj[key])) {
              if (encryptionFields(key2) && value2) {
                const data = await decryption(value2);
                y[key2] = await parseDataBasedOnKey(key2, data);
              } else {
                y[key2] = value2;
              }
            }
            x[key] = y;
          } else {
            const data = await decryption(value);
            x[key] = await parseDataBasedOnKey(key, data);
          }
        } else {
          x[key] = value;
        }
      }

      return x;
    })
  );

  return tempArr;
}

// encrypted value assign the key value of object

async function convertToObjectEncryptedKey(obj) {
  const x = {};

  for (const [key, value] of Object.entries(obj)) {
    if (encryptionFields(key) && value) {
      if (typeof value == 'object') {
        const y = {};
        for (const [key2, value2] of Object.entries(obj[key])) {
          if (encryptionFields(key2) && value2) {
            const data = await encryption(value2);
            y[key2] = data;
          } else {
            y[key2] = value2;
          }
        }
        x[key] = y;
      } else {
        const data = await encryption(value);
        x[key] = data;
      }
    } else {
      x[key] = value;
    }
  }
  return x;
}
