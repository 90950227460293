export default [
  {
    id: 2,
    image: 'images/tours/ra-preferences.jpg',
    title: 'ra-preferences',
    tour: 'ra-preferences',
    comment: 'tours.ra-preferences.comment',
    href: 'http://marmelab.com/react-admin',
  },
  {
    id: 7,
    image: 'images/tours/ra-search.png',
    title: 'ra-search',
    tour: 'ra-search',
    comment: 'tours.ra-search.comment',
    href: 'http://marmelab.com/react-admin',
  },
  {
    id: 6,
    image: 'images/tours/ra-navigation-breadcrumb.jpg',
    title: 'ra-navigation: Breadcrumb',
    tour: 'ra-navigation-breadcrumb',
    comment: 'tours.ra-navigation-breadcrumb.comment',
    href: 'http://marmelab.com/react-admin',
  },
  {
    id: 4,
    image: 'images/tours/ra-realtime.jpg',
    title: 'ra-realtime',
    tour: 'ra-realtime',
    comment: 'tours.ra-realtime.comment',
    href: 'http://marmelab.com/react-admin',
  },
  {
    id: 5,
    image: 'images/tours/ra-editable-datagrid.jpg',
    title: 'ra-editable-datagrid',
    tour: 'ra-editable-datagrid',
    comment: 'tours.ra-editable-datagrid.comment',
    href: 'http://marmelab.com/react-admin',
  },
  {
    id: 3,
    image: 'images/tours/ra-tree.jpg',
    title: 'ra-tree',
    tour: 'ra-tree',
    comment: 'tours.ra-tree.comment',
    href: 'http://marmelab.com/react-admin',
  },
  {
    id: 1,
    image: 'images/tours/ra-markdown.jpg',
    title: 'ra-markdown',
    tour: 'ra-markdown',
    comment: 'tours.ra-markdown.comment',
    href: 'http://marmelab.com/react-admin',
  },
];
