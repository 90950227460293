import { ITagCategory } from '../interface/options';
import { api } from './api';
import { stringify } from 'query-string';

/**
 * Function to fetch all tags or tags by category
 */

export const getTags = (category?: ITagCategory) => {
  const query = category ? `?category=${category}` : '';
  return api.get('/tags' + query);
};

export const handleReviewVisiblity = (reviewId: number, isVisible: boolean) => {
  const query = {
    commentId: reviewId,
    flag: isVisible ? 0 : 1,
  };
  return api.put(`/reviews?${stringify(query)}`, {}).then((response: any) => {
    return { response };
  });
};
// update is active status for both blast and personalized notifications
export const updateNotificationStatus = (record: any, url) => {
  return api
    .put(url, {
      ...record,
      isActive: record?.isActive ? 0 : 1,
    })
    .then((response: any) => {
      return { response };
    })
    .catch((error) => {
      return Promise.reject({ ...error });
    });
};

// user analytics api
export const userAnalytics = (
  label: string,
  startDate: string,
  endDate: string,
  sortOrder?: string
) => {
  return api
    .post('session/userCount', {
      label: label,
      startDate: startDate,
      endDate: endDate,
      order: sortOrder,
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject({ ...error });
    });
};

// Most used features and most active  screen analytics
export const appAnalytics = (
  endpoint: string,
  startDate: string,
  endDate: string,
  sortOrder: string
) => {
  const cleanedStartDate = JSON.stringify(startDate).replace(/"/g, '');
  const cleanedEndDate = JSON.stringify(endDate).replace(/"/g, '');
  return api
    .get(
      `event/${endpoint}?startDate=${cleanedStartDate}&endDate=${cleanedEndDate}&order=${sortOrder}`
    )
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject({ ...error });
    });
};

// Most used features and most active  screen analytics
export const waveChartAnalytics = (
  label: string,
  startDate: string,
  endDate: string
) => {
  return api
    .post('/user-growth/count', {
      label: label,
      startDate: startDate,
      endDate: endDate,
    })
    .then((response: any) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject({ ...error });
    });
};
