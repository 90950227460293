// react-admin
import { Show, SimpleForm, TopToolbar, SimpleShowLayout } from 'react-admin';

// components
import SubscriptionPlanForm from './components/SubscriptionPlanForm';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import SubscriptionDetailsShowForm from './components/SubscriptionDetailsShowForm';

/**
 *
 * @function SubscriptionShow
 * @returns This will show existing Subscription Plan.
 */

const SubscriptionShow = (props: any) => {
  const SubscriptionShowBreadCrumb = () => (
    <TopToolbar>
      <CustomBreadcrumb variant="actions" />
    </TopToolbar>
  );

  return (
    <Show
      {...props}
      actions={<SubscriptionShowBreadCrumb {...props} />}
      title="Subscription Plan"
    >
      <SimpleShowLayout>
        <SubscriptionDetailsShowForm />
      </SimpleShowLayout>
    </Show>
  );
};

export default SubscriptionShow;
