// react
import { useState } from 'react';
// mui
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
// date-pickers
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
// dayjs
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
// ---------------------------------------------------------------------------------------------------------

/**
 * DateFilter component for selecting date range and filtering options.
 * @param {Object} props - Component props
 * @param {Function} props.setStartDate - Callback to set the start date
 * @param {Function} props.setEndDate - Callback to set the end date
 * @param {Date} props.startDate - Start date of the selected range
 * @param {Date} props.endDate - End date of the selected range
 * @returns {JSX.Element} - JSX element representing the DateFilter component
 */
const DateFilter = (props: any) => {
  const { setStartDate, setEndDate, startDate, endDate } = props;

  const today = dayjs();
  const [show, setShow]: any = useState(false);
  const [name, setName] = useState('Last 7 days');

  /**
   * Handles date range selection based on the selected filter option.
   * @param {string} data - Filter option ('last_7_days', 'week', 'month', 'custom', 'reset')
   */
  const handleDates = (data: any) => {
    if (data == 'month') {
      setShow(false);
      const startOfPreviousMonth = today.subtract(1, 'month').startOf('month');
      setStartDate(startOfPreviousMonth);
      setEndDate(startOfPreviousMonth.endOf('month'));
    } else if (data == 'week') {
      setShow(false);
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      setStartDate(prevWeek.startOf('week'));
      setEndDate(prevWeek.endOf('week'));
    } else if (data == 'last_7_days') {
      setShow(false);
      setStartDate(today.subtract(7, 'day'));
      setEndDate(today);
    } else if (data == 'custom') {
      setStartDate(null);
      setEndDate(null);
      setShow(true);
    } else if (data == 'reset') {
      setStartDate(null);
      setEndDate(null);
      setShow(false);
    }
  };

  /**
   * Handles the change in the selected filter option.
   * @param {Object} event - The event object containing the selected option
   */
  const handleChange = (event: any) => {
    setName(event.target.value);
  };
  return (
    <>
      <Box
        style={{ textAlign: 'right', paddingRight: '4%' }}
        sx={{ display: show ? 'unset' : 'none' }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start date"
            onChange={(e: any) => setStartDate(e.add(6, 'hour'))} // why i have added and subtracted 6 hours bcoz, we were 1 day less without it.
            value={startDate ? startDate.subtract(6, 'hour') : null}
          />
          &nbsp;
          <DatePicker
            label="End date"
            onChange={(e: any) => setEndDate(e.add(6, 'hour'))} // why i have added and subtracted 6 hours bcoz, we were 1 day less without it.
            maxDate={today}
            value={endDate ? endDate.subtract(6, 'hour') : null}
          />
        </LocalizationProvider>
      </Box>

      <Box>
        <FormControl fullWidth sx={{ minWidth: '200px' }}>
          <InputLabel id="demo-simple-select-label">Filter By Date </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={name}
            label="Filter By Date"
            onChange={handleChange}
            style={{ width: '100%' }}
          >
            <MenuItem
              onClick={() => handleDates('last_7_days')}
              value="Last 7 days"
            >
              Last 7 days
            </MenuItem>
            <MenuItem onClick={() => handleDates('week')} value="Last Week">
              Last Week
            </MenuItem>
            <MenuItem onClick={() => handleDates('month')} value="Last Month">
              Last Month
            </MenuItem>
            <MenuItem onClick={() => handleDates('custom')} value="Custom">
              Custom
            </MenuItem>
            <MenuItem onClick={() => handleDates('reset')}>Reset</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};
export default DateFilter;
