import * as CryptoJS from 'crypto-js';

export const encrypt = (data: any) => {
  if (data) {
    if (typeof data === 'object') {
      data = JSON.stringify(data);
    }
    return CryptoJS.AES.encrypt(data, 'process.env.SECRETKEY').toString();
  }
  return data;
};

export const decrypt = (data: any) => {
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data, 'process.env.SECRETKEY');
    const dData = bytes.toString(CryptoJS.enc.Utf8);
    return dData;
  }
  return data;
};
