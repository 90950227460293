// react-admin
import {
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

// components
import PersonalizedNotificationForm from './components/PersonalizedNotificationForm';

// utils
import { ListActions } from '../../utils/common';
import { TRIGGERS } from '../../utils/constants';
import CustomToolbar from './components/CustomToolbar';

// ------------------------------------------------------------------------------------------------------
/**
 * CreatePersonalizedNotification component for creating personalized notifications.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the CreatePersonalizedNotification component
 */
const CreatePersonalizedNotification = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  // transform
  const transform = (data: any) => {
    // Find the selected trigger based on the triggerValue in the input data
    const selectedTrigger = TRIGGERS.find(
      (trigger) => trigger?.id === data.triggerValue
    );

    // Create an initial payload object with properties from the input data
    const payload = {
      ...data,
      notificationData: [],
      userType: selectedTrigger?.userType,
    };

    // If title and content are provided, create a notification object
    if (data.title && data.content) {
      const notificationObject = {
        title: data.title,
        content: data.content,
      };
      payload.notificationData.push(notificationObject);
    } else {
      // Iterate through provided notificationData
      if (data?.notificationData.length > 0) {
        data.notificationData.forEach((notificationObj) => {
          // Check if both title and content are present
          if (notificationObj.title && notificationObj.content) {
            // Create an object with title and content and push to payload
            payload.notificationData.push({
              title: notificationObj.title,
              content: notificationObj.content,
            });
          }
        });
      }
    }

    // Remove title and content properties from payload
    delete payload.title;
    delete payload.content;

    // Return the transformed payload for submission
    return payload;
  };

  const onSuccess = () => {
    notify(`Personalized notification created successfully.`, {
      type: 'success',
    });
    redirect('/personalized-notification');
    refresh();
  };
  const onError = (error: any) => {
    notify(error, { type: 'error' });
  };

  return (
    <Create
      transform={transform}
      actions={<ListActions />}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
      onFailure={onError}
      title="Create Personalized Notification"
      {...props}
    >
      <SimpleForm mode="onChange" toolbar={<CustomToolbar />}>
        <PersonalizedNotificationForm />
      </SimpleForm>
    </Create>
  );
};

export default CreatePersonalizedNotification;
