import dayjs from 'dayjs';

export const handleCreateSlug = (name: string) => {
  const slug = name
    .toLowerCase()
    .replace(/ /g, '-')
    .replace(/[^\w-]+/g, '');
  return slug;
};

export const dateFormat = (dateString: string | null, format: string) => {
  if (dateString === null) {
    return null;
  }

  return dayjs(dateString).format(format);
};

import { Chip } from '@mui/material';

export function isActive(status) {
  switch (status) {
    case true:
      return 'Active';
    default:
      return 'Inactive';
  }
}
export const renderAge = (record: any) => {
  let value;
  if (record?.age > 0) value = record?.age;
  else value = '--';
  return value;
};

export const capitalizeString = (str) => {
  const result = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  return result;
};

export const renderGender = (record: any) => {
  let value;
  if (record?.gender !== '') {
    value =
      record?.gender == 'Other'
        ? 'Prefer not to say'
        : capitalizeString(record?.gender);
  } else value = '--';
  return value;
};

export const renderUserStatus = (status: boolean | number) => {
  const color = status ? 'success' : 'warning';
  const label = status ? 'Active' : 'Inactive';

  return (
    <Chip label={label} color={color} sx={{ height: '20px', width: '92px' }} />
  );
};
