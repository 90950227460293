import {
  Box,
  Button,
  Card,
  CardContent,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  Confirm,
  DateInput,
  Edit,
  FormWithRedirect,
  ImageField,
  ImageInput,
  ListProps,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useDataProvider,
  useDelete,
  useEditController,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
} from 'react-admin';
import './UserView.css';
import queryString from 'query-string';
import React, { FC, Fragment, ReactElement, useState } from 'react';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import { EditActions } from '@rg-admin/ra-enterprise';
import Aside from './Aside';
import TabbedDatagrid from './UserDetailsTab';
import Validate from '../../component/common';
// import { DateInput, TimeInput, DateTimeInput } from 'react-admin-date-inputs';
const useStyles = makeStyles({
  card: {
    padding: 0,
    width: '100%',
  },
  disableTopMargin: {
    marginTop: '0px',
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0px 6px',
  },
});

const UserDetailsEdit = (props: any): ReactElement => {
  const { location } = props;
  const { record } = useEditController(props);
  const parsed = queryString.parse(location.search);
  const WithProps = ({ children, ...props }) => children(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify(`Saved successfully`, 'success', {}, false);
    redirect('/users/all');
    refresh();
  };
  return (
    <Fragment>
      <Edit
        onSuccess={onSuccess}
        title={'User Details'}
        undoable={false}
        aside={<Aside />}
        onFailure={onSuccess}
        actions={
          <EditActions breadcrumb={<CustomBreadcrumb variant="actions" />} />
        }
        component="div"
        {...props}
      >
        <UserForm {...props} />
      </Edit>
      <DetailsList title=" " record={record} {...props} />
    </Fragment>
  );
};

const DetailsList: FC<ListProps> = (props: any) => (
  <TabbedDatagrid
    title=" "
    records={props?.record}
    sort={{ field: 'date', order: 'DESC' }}
    {...props}
    filterValues={{
      status: props?.record?.userType == 'user' ? 'profile' : 'medication',
      id: props.id,
    }}
  />
);

const PreviewImage = ({ record, source }) => {
  if (typeof record == 'string') {
    record = {
      [source]: record,
    };
  }
  return <ImageField record={record} source={source} />;
};

const UserForm = (props: any): ReactElement => {
  const [previousImg, setPreviousImg] = useState(true);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const params = new URLSearchParams(props?.location?.search);
  const userId = params.get('userId');
  const clickToCancel = () => {
    if (props?.record?.userType == 'Profile') {
      props.history.push(`/users/all/${userId}`);
    } else {
      props.history.push(`/users/all`);
    }
  };

  const classes = useStyles();

  const handleImageChange = (event) => {
    const { id } = props;
    if (event == null && props?.record?.photo !== null) {
      dataProvider
        .delete('profile/delete-profile-image', {
          id: id,
          previousData: props?.record,
        })
        .then((response) => {
          // success side effects go here
          notify('Picture removed successfully', { type: 'success' });
          refresh();
        })
        .catch((error) => {
          notify(`${error.message}`, { type: 'warning' });
        });
    }
  };

  const translate = useTranslate();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps: any): ReactElement => (
        <Card>
          <form>
            <CardContent>
              <Box display={{ md: 'block', lg: 'flex' }}>
                <Box flex={2} mr={{ md: 0, lg: '1em' }}>
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.users.fieldGroups.details')}
                  </Typography>
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <TextInput
                        label="Full Name"
                        source={'name'}
                        fullWidth
                        validate={Validate.alphabets()}
                      />
                    </Box>
                    <Box flex={0} ml={{ xs: 0, sm: '0.5em' }}>
                      <SelectInput
                        source="gender"
                        fullWidth
                        choices={[
                          { id: 'M', name: 'Male' },
                          { id: 'F', name: 'Female' },
                          { id: 'O', name: 'Prefer not to say' },
                        ]}
                      />
                    </Box>
                    <Box flex={0} ml={{ xs: 0, sm: '0.5em' }}>
                      <SelectInput
                        fullWidth
                        label="Active/Inactive"
                        source="isActive"
                        choices={[
                          { id: true, name: 'Active' },
                          { id: false, name: 'Inactive' },
                        ]}
                      />
                    </Box>
                  </Box>
                  {props?.record?.userType == 'User' && (
                    <TextInput
                      type="email"
                      source={'email'}
                      validation={{ email: true }}
                      fullWidth
                      validate={required()}
                    />
                  )}
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <DateInput source="dob" label="Bate Of Birth" fullWidth />
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} />
                  </Box>

                  <Box mt="1em" />
                  <Box display={{ xs: 'block', sm: 'flex' }}>
                    <Box flex={1} mr={{ xs: 0, sm: '0.5em' }}>
                      <ImageInput
                        source="pictureUrl"
                        label="Change Image"
                        placeholder="Drop a picture to upload, or click to select it."
                        accept="image/*"
                        onChange={handleImageChange}
                      >
                        <PreviewImage source="pictureUrl" {...props} />
                      </ImageInput>
                    </Box>
                    <Box flex={2} ml={{ xs: 0, sm: '0.5em' }} />
                  </Box>

                  <Box mt="1em" />
                </Box>
              </Box>
            </CardContent>
            <Toolbar
              record={formProps.record}
              basePath={formProps.basePath}
              undoable={true}
              invalid={formProps.invalid}
              handleSubmit={formProps.handleSubmit}
              saving={formProps.saving}
              resource="users/all"
            >
              <SaveButton
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                resource="users/all"
              />
              <Button
                className={classes.cancelButton}
                onClick={clickToCancel}
                variant="contained"
              >
                CANCEL
              </Button>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default UserDetailsEdit;
