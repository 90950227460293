// react
import { useState } from 'react';
// react admin
import { useNotify, useRecordContext, useRefresh } from 'react-admin';
// components
import { ConfirmModal } from '../../../component/ConfirmModal';
// mui
import { Switch } from '@mui/material';
// api
import { updateNotificationStatus } from '../../../provider/options';

/**
 * IsActiveSwitch component for rendering a switch to handle active/inactive status of blast notification in List component.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the IsActiveSwitch component
 */
const IsActiveSwitch = (props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const record = useRecordContext();

  const refresh = useRefresh();
  const notify = useNotify();

  if (!record) return null;

  const color =
    record?.status === 1
      ? '#c9cacb !important'
      : record?.isActive === 1
      ? 'green !important'
      : 'red !important';

  // handler to open the modal when click on a switch
  const handleModalOpen = (e) => {
    e.stopPropagation();
    setIsOpenModal(true);
  };
  // handler to close the modal
  const handleModalClose = (e) => {
    e.stopPropagation();
    setIsOpenModal(false);
  };
  // handler for API call to update notification active status
  const handleNotificationActiveStatusChange = async (e) => {
    e.stopPropagation();
    setIsLoading(true);
    await updateNotificationStatus(record, `/blastnotification`)
      .then(() => {
        notify(`Successfully updated.`, { type: 'success' });
        refresh();
      })
      .catch((error) => notify(error, { type: 'error' }))
      .finally(() => setIsLoading(false));
  };
  return (
    <>
      <Switch
        checked={record.isActive}
        onClick={(e) => handleModalOpen(e)}
        inputProps={{ 'aria-label': 'Is Active?' }}
        disabled={record?.status}
        sx={{
          '& .MuiSwitch-track': { backgroundColor: color },
          '& .MuiSwitch-thumb': { backgroundColor: color },
        }}
      />
      {isOpenModal && (
        <ConfirmModal
          onConfirm={(e) => handleNotificationActiveStatusChange(e)}
          isOpen={isOpenModal}
          onCancel={(e) => handleModalClose(e)}
          title="Please Confirm!"
          contentText={`Are you sure you want to make '${
            record?.notificationTitle
          }' ${record?.isActive ? 'inactive' : 'active'}?`}
          confirmText="Yes"
          cancelText="Cancel"
          loading={isLoading}
        />
      )}
    </>
  );
};
export default IsActiveSwitch;
