/* eslint-disable react/no-unescaped-entities */
import { makeStyles, Step, StepButton, Stepper } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import './csvImport.css';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '600px',
  },
  paper: {
    border: 'none',
  },
  importDialogContent: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  uploadIcon: {
    width: '50px',
    height: '50px',
    background: 'url(./upload.png) no-repeat center center',
    backgroundSize: '100%',
    textAlign: 'center',
    margin: '0 auto',
    paddingTop: '30px',
  },
}));

const CsvImport = (props: any) => {
  const classes = useStyles();
  const { onDrop, completed, steps, activeStep, handleStep } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'text/xml',
  });

  return (
    <>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        className={classes.paper}
      >
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div className="dropeZone" {...getRootProps()}>
        <div className={classes.uploadIcon}></div>
        <input {...getInputProps()} />
        <p>
          Drag 'n' Drop the file here or&nbsp;
          <u style={{ color: 'blue', cursor: 'pointer' }}>click</u> to upload
        </p>
      </div>
    </>
  );
};

export default CsvImport;
