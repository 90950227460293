// react
import { FC } from 'react';

// react admin
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';

// mui
import { CardContent, Card as MuiCard, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

// Iconify
import Iconify from '../../../component/iconify/Iconify';
import { Box } from '@mui/material';
import { TRIGGERS } from '../../../utils/constants';

const Card = withStyles((theme) => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => (
  <Card>
    <CardContent>
      {/* <FilterLiveSearch source={'searchKey'} />
      <Typography variant="body2" style={{ color: 'grey' }}>
        Search by Title
      </Typography> */}
      <FilterList
        label="Is Active?"
        icon={<Iconify icon="material-symbols:notifications-active-outline" />}
      >
        <FilterListItem
          label="Active"
          value={{
            isActive: 1,
          }}
        />
        <FilterListItem
          label="Inactive"
          value={{
            isActive: 0,
          }}
        />
      </FilterList>

      <FilterList label="User Type" icon={<Iconify icon="mdi:target-user" />}>
        <FilterListItem
          label="Free"
          value={{
            userType: 'F',
          }}
        />
        <FilterListItem
          label="Paid"
          value={{
            userType: 'P',
          }}
        />
        <FilterListItem
          label="Both"
          value={{
            userType: 'B',
          }}
        />
      </FilterList>
      <FilterList
        label="Triggers"
        icon={<RenderIcon icon="tabler:hand-click" />}
      >
        <Box
          sx={{
            maxHeight: 365,
            marginBottom: 1,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              width: 5,
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: 2,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#c9cacb',
              borderRadius: 2,
            },
          }}
        >
          {TRIGGERS.map((trigger) => (
            <Box mb={0.7} key={trigger.id}>
              <FilterListItem
                label={trigger.name}
                value={{
                  triggerValue: trigger.id,
                }}
              />
            </Box>
          ))}
        </Box>
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;
const RenderIcon = ({ icon }: any) => {
  return <Iconify icon={icon} />;
};
