import { FC } from 'react';
import {
  CreateProps,
  FormTab,
  NumberInput,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  required,
} from 'react-admin';
import { InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Create } from '@rg-admin/ra-enterprise';
import { MarkdownInput } from '@rg-admin/ra-markdown';
import { useDefineAppLocation } from '@rg-admin/ra-navigation';
import { CreateActions } from '../../../component/layout/CreateActions';

export const styles = {
  price: { width: '7em' },
  width: { width: '7em' },
  height: { width: '7em' },
  stock: { width: '7em' },
  widthFormGroup: { display: 'inline-block' },
  heightFormGroup: { display: 'inline-block', marginLeft: 32 },
};

const useStyles = makeStyles(styles);

const ProductCreate: FC<CreateProps> = (props) => {
  useDefineAppLocation('catalog.products.create');
  const classes = useStyles();
  return (
    <Create actions={<CreateActions />} {...props}>
      <TabbedForm>
        <FormTab label="resources.products.tabs.image">
          <TextInput source="image" fullWidth validate={required()} />
          <TextInput source="thumbnail" fullWidth validate={required()} />
        </FormTab>
        <FormTab label="resources.products.tabs.details" path="details">
          <TextInput source="reference" validate={required()} />
          <NumberInput
            source="price"
            validate={required()}
            className={classes.price}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">€</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="width"
            validate={required()}
            className={classes.width}
            formClassName={classes.widthFormGroup}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">cm</InputAdornment>
              ),
            }}
          />
          <NumberInput
            source="height"
            validate={required()}
            className={classes.height}
            formClassName={classes.heightFormGroup}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">cm</InputAdornment>
              ),
            }}
          />
          <ReferenceInput
            source="category_id"
            reference="categories"
            allowEmpty
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <NumberInput
            source="stock"
            validate={required()}
            className={classes.stock}
          />
        </FormTab>
        <FormTab label="resources.products.tabs.description" path="description">
          <MarkdownInput source="description" label="" />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default ProductCreate;
