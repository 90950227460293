import { useTranslate } from 'ra-core';
import { Pagination } from 'react-admin';
const PaginationWithCustomEmpty = ({ ...props }) => {
  return props.ids.length != 0 ? (
    <Pagination limit={<PaginationLimit />} {...props} />
  ) : null;
};
export default PaginationWithCustomEmpty;

export const PaginationLimit = () => {
  const translate = useTranslate();
  return null;
};
