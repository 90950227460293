import { FC, useEffect } from 'react';
import { Redirect, useParams } from 'react-router';
import { useTour } from '@rg-admin/ra-tour';

const TourLauncher: FC = () => {
  const { tour } = useParams<{ tour: string }>();
  const [{ running }, { start }] = useTour();

  useEffect(() => {
    if (start && !running) {
      start(tour);
      return;
    }
  });

  return running ? <Redirect to="/" /> : null;
};

export default TourLauncher;
