import { Box, Card, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import Iconify from '../../component/iconify/Iconify';
import cartouche from './cartouche.png';
import cartoucheDark from './cartoucheDark.png';
interface Props {
  icon: FC<any>;
  to: string;
  title?: string;
  subtitle?: string | number;
}

const useStyles = makeStyles((theme) => ({
  card: {
    minHeight: 52,
    flex: '1',
    '& a': {
      textDecoration: 'none',
      color: 'inherit',
    },
  },
  main: {
    overflow: 'inherit',
    padding: 16,
    background: `url(${
      theme.palette.type === 'dark' ? cartoucheDark : cartouche
    }) no-repeat`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .icon': {
      color: theme.palette.type === 'dark' ? 'inherit' : '#dc2440',
    },
  },
  title: {},
}));

const CardDetails = (props: any) => {
  const { icon, title, subtitle, children } = props;
  const classes = useStyles(props);

  return (
    <Card className={classes.card}>
      {/* <Link to={to}> */}
      <div className={classes.main}>
        <Box width="3em" className="icon">
          <Iconify icon={icon} width={40} height={40} />
        </Box>
        <Box textAlign="right">
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>
          <Typography variant="h5" component="h2">
            {subtitle || '0'}
          </Typography>
        </Box>
      </div>
      {/* </Link> */}
      {children && <Divider />}
      {children}
    </Card>
  );
};

export default CardDetails;
