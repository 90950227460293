// react
import { FC } from 'react';

// react admin
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  SearchInput,
  ShowButton,
  TextField,
} from 'react-admin';

// mui
import { Theme, useMediaQuery } from '@material-ui/core';

// rg admin
import { FilterWithSave } from '@rg-admin/ra-preferences';

// Components
import EmptyList from '../../component/EmptyList';
import AsideFilter from './components/AsideFilter';
import CustomerLinkField from './components/CustomerLinkField';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';

// utils
import { ListActions } from '../../utils/common';
import { dateFormat } from '../../utils/helper';

// helper function
import {
  renderArrayContentSliced,
  renderContent,
} from './components/helperFunction';

// ----------------------------------------------------------------------------------------------------

// UserFilter component for filtering the list of users
const UserFilter: FC = (props: any) => (
  <FilterWithSave {...props}>
    <SearchInput source="searchKey" alwaysOn />
  </FilterWithSave>
);

// Columns to be displayed in the datagrid
const columns = [
  <CustomerLinkField key="name" />,
  <FunctionField
    label="Profile Name"
    key="profileName"
    render={(record) => renderContent(record?.profileName)}
    style={{ whiteSpace: 'nowrap' }}
  />,
  <FunctionField
    label="Symptoms"
    render={(record: any) => renderArrayContentSliced(record?.symptoms)}
    key="symptoms"
  />,
  ,
  <FunctionField
    sortBy="createdAt"
    label="Created At"
    render={(record: any) => dateFormat(record?.createdAt, 'MM-DD-YYYY')}
    key="createdAt"
    style={{ whiteSpace: 'nowrap' }}
  />,
  <ShowButton key="show" />,
];

/**
 * ListSymptoms component to display the list of symptoms.
 * @param {ListSymptomsProps} props - The props for the ListSymptoms component.
 * @returns {JSX.Element} - JSX element representing the ListSymptoms component.
 */
const ListSymptoms: FC<ListProps> = (props) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <List
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={isSmall ? <UserFilter /> : undefined}
      aside={<AsideFilter />}
      actions={<ListActions />}
      pagination={<PaginationWithCustomEmpty />}
      bulkActionButtons={false}
      title="Symptoms"
      {...props}
    >
      <Datagrid rowClick={'show'} empty={<EmptyList resource={'Symptoms'} />}>
        {columns}
      </Datagrid>
    </List>
  );
};

export default ListSymptoms;
