import ArchiveIcon from '@material-ui/icons/Archive';
import { Fragment, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  Button,
  Confirm,
  useCreate,
  useNotify,
  useRefresh,
  useMutation,
} from 'react-admin';
import Unarchive from '@material-ui/icons/Unarchive';
import { handleReviewVisiblity } from '../provider/options';
const useStyles = makeStyles({
  icon_action_button_show: {
    color: 'green',
    border: '1px solid #0080004f',
  },
  icon_action_button_hide: {
    color: 'red',
    border: '1px solid #ff000030',
  },
});
const ShowHideButton = (props) => {
  const { record, resource } = props;
  const [open, setOpen] = useState(props.open);
  const [loading, setloading] = useState(false);
  const refresh = useRefresh();
  const classes = useStyles();
  const notify = useNotify();
  //   const resourceUrl =`reviews?id=${record.id}&flag=${show}`;

  const updateFlag = (event: any) => {
    event.stopPropagation();
    setloading(true);
    handleReviewVisiblity(props?.record?.id, props?.record?.isVisible)
      .then((res: any) => {
        // console.log(res, ': res in handle review visiblity response');
        refresh();
      })
      .finally(() => setloading(false));
  };
  //   const [create, { loading }] = useCreate(resourceUrl, {
  //     onSuccess: () => {
  //       refresh();
  //       notify('Successfully copy');
  //     },
  //     onFailure: (error) => notify('Error: item not found', 'warning'),
  //   });
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = (e) => {
    // create();
    updateFlag(e);
    setOpen(false);
  };
  const confirmContent = `${
    props?.record?.isVisible
      ? 'Are you sure you want to flag this comment from the drug reviews screen in the app?'
      : 'Are you sure you want to unflag this comment from the drug reviews screen in the app?'
  }`;
  return (
    <Fragment>
      <Button
        label={props?.record?.isVisible ? 'Active' : 'Flagged'}
        color="primary"
        onClick={handleClick}
        style={{
          padding: '7px 14px',
        }}
        className={
          props?.record?.isVisible
            ? classes.icon_action_button_show
            : classes.icon_action_button_hide
        }
      >
        {props?.record?.isVisible ? <Unarchive /> : <ArchiveIcon />}
      </Button>
      <Confirm
        isOpen={open}
        loading={loading}
        title={'Are you sure?'}
        content={confirmContent}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default ShowHideButton;
