import { ReactElement } from 'react';
import { Layout } from '@rg-admin/ra-enterprise';

import AppBar from './AppBar';
// import Menu from './Menu';
import CustomMenu from './CustomMenu';

const CustomLayout = (props: any): ReactElement => {
  return <Layout {...props} appBar={AppBar} menu={CustomMenu} />;
};
export default CustomLayout;
