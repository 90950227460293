// react
import { FC } from 'react';
// react-admin
import { Link } from 'react-admin';
// components
import FullNameField from './FullNameField';
// types
import { FieldProps } from '../../../interface/types';
// ------------------------------------------------------------------------------------------

/**
 * Function to render the URL for the customer profile
 * @param {Object} record - Customer record
 * @returns {string} - URL for the customer profile
 */
const renderUrl = (record: any) => {
  return `/users/all/${record?.userId}/show`;
};

const handleClick = (e) => {
  e.stopPropagation();
};

/**
 * CustomerLinkField component for rendering a link to the customer's profile
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the CustomerLinkField component
 */
const CustomerLinkField: FC<FieldProps<any>> = (props) =>
  props.record ? (
    <Link to={renderUrl(props.record)} onClick={(e) => handleClick(e)}>
      <FullNameField {...props} />
    </Link>
  ) : null;

CustomerLinkField.defaultProps = {
  source: 'name',
  addLabel: true,
};

export default CustomerLinkField;
