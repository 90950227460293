import { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Record } from 'react-admin';

interface Props {
  className?: string;
  record?: Record;
  size?: string;
}

const AvatarField: FC<Props> = ({ record, size = '25', className }) => {
  // Extract the image and url from the record or provide default values
  const image = record?.image || null;
  const url = record?.url || null;

  // Construct the avatar source URL
  const avatarSrc = url
    ? url
    : image
    ? `https://rxprofiles3.s3.amazonaws.com/${image}`
    : '/profile-placeholder.jpeg';

  return record ? (
    <Avatar
      src={avatarSrc}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;
};
export default AvatarField;
