import { Box, Card } from '@material-ui/core';
import React, { CSSProperties, useState, useEffect } from 'react';
import { IsDrugTypeChart } from '../../utils/Interface';
import PieChartSelection from './Chart';
import ChartTable from './chartTable';

const styles = {
  pichartSection: { height: '100%' },
  pichartBoxSection: { padding: '16px' },
  tittle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
    fontWeight: '600',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    paddingBottom: '2%',
  },
};

const RxOTChart = (props: any) => {
  const { drugTypeData } = props;
  const [series, setSeries]: any = useState([]);
  const [drugTypeChartData, setdrugTypeChartData] = useState<
    IsDrugTypeChart[] | []
  >([]);
  const title = 'RX vs OTC';

  const columns = [
    { field: 'type', headerName: 'Type' },
    { field: 'count', headerName: 'Number of users' },
  ];

  const [options, setOptions]: any = useState({
    chart: {
      width: 380,
      type: 'pie',
      id: 'rxot',
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
          chart: {
            id: 'rxot',
            width: 400,
          },
          legend: {
            // position: 'bottom'
          },
        },
      },
    ],
  });

  const handleUpdateDrugTypeChartData = async (drugTypeData) => {
    const drugTypeList = await Object.keys(drugTypeData);
    const tempData = await drugTypeList?.map((drugType) => {
      return {
        type: drugType,
        count: drugTypeData[drugType],
      };
    });

    setdrugTypeChartData(tempData);
  };

  const setArrayofSeries = () => {
    const label: any = [];
    const result = drugTypeChartData.map((res: any) => {
      label.push(res.type);
      return res.count;
    });
    setSeries(result);
    setOptions({ ...options, labels: label });
  };

  useEffect(() => {
    // if (drugTypeChartData?.length > 0){
    setArrayofSeries();
    // }
  }, [drugTypeChartData]);

  useEffect(() => {
    if (drugTypeData) {
      handleUpdateDrugTypeChartData(drugTypeData);
    }
  }, [drugTypeData]);

  const data = drugTypeChartData
    ?.map((item) => item.count)
    .every((count) => count === 0);

  return (
    <Card style={styles.pichartSection as CSSProperties}>
      <Box style={styles.pichartBoxSection as CSSProperties}>
        <div style={styles.tittle as CSSProperties}>{title}</div>
        {!data && (
          <PieChartSelection
            series={series}
            options={options}
            title={title}
            next="rxto"
          />
        )}

        <ChartTable rows={drugTypeChartData} columns={columns} />
      </Box>
    </Card>
  );
};

export default RxOTChart;
