import { format } from 'date-fns';

export default function dateFormatChange(date) {
  date = new Date(date);
  const datestring = format(date, 'MM/DD/YYYY');
  return datestring;
}

export function dateFormatChangeToRender(date) {
  date = new Date(date);
  const datestring = format(date, 'YYYY-MM-DD');

  return datestring;
}
