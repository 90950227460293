import { Typography } from '@mui/material';

const NoDataFound = ({ children, sx }) => {
  return (
    <Typography
      sx={{
        fontWeight: 600,
        color: '#9A9B9E',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '25px 0 10px 0',
        width: '100%',
        ...sx,
      }}
      variant="h6"
    >
      {children}
    </Typography>
  );
};

export default NoDataFound;
