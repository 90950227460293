import { makeStyles } from '@material-ui/core/styles';
import Inbox from '@material-ui/icons/Inbox';
import { Box, Typography } from '@mui/material';

const EmptyList = (props) => {
  const { resource } = props;
  const classes = useStyles(props);

  return (
    <Box textAlign="center" m={1} className={classes.message}>
      <Inbox className={classes.icon} />
      <Typography style={{ textTransform: 'initial' }} variant="h4" paragraph>
        No results found
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  message: {
    textAlign: 'center',
    paddingRight: '6%',
    opacity: theme.palette.type === 'light' ? 0.5 : 0.8,
    margin: '0 1em',
    color:
      theme.palette.type === 'light' ? 'inherit' : theme.palette.text.primary,
  },
  icon: {
    width: '9em',
    height: '9em',
  },
}));

export default EmptyList;
