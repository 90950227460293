import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const ChartTable = (props: any) => {
  const { rows, columns, alignLeft } = props;

  return (
    <div style={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  style={{ fontWeight: 600, textTransform: 'capitalize' }}
                  align={alignLeft ? 'left' : 'center'}
                  key={column?.field}
                >
                  {column?.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row) => (
              <TableRow
                key={row}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {columns?.map((column) => (
                  <TableCell
                    key={column?.field}
                    align={alignLeft ? 'left' : 'center'}
                    style={{ textTransform: 'capitalize' }}
                  >
                    {row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ChartTable;
