import React, { Fragment, useCallback, useState } from 'react';
import { Create, SimpleForm, useNotify } from 'react-admin';
import CsvImport from '../../component/common/CsvImport';
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { uploadFile } from '../../utils/upload';
import { api } from '../../provider/api';

import './medicineUpload.css';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#4caf50',
  },
  importDialogContent: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  div: {
    width: '100%',
    fontSize: '0.875rem',
  },
  span: {
    fontWeight: 'bold',
  },
  fileTypeLogo: {
    background: 'url(./generic.png) no-repeat center center',
  },
}));

const MedicineUpload = (props: any) => {
  const classes = useStyles();
  const notify = useNotify();
  const [fileName, setFileName] = useState<any>({});
  const [isLoader, setIsLoader] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alert, setAlert] = useState(false);
  const [activeStep, setActiveStep] = React.useState<any>({ count: 0 });
  const [activeStepName, setActiveStepName] = React.useState<any>({
    name: 'Hamacher BOP CSV Import',
  });
  const [completed, setCompleted] = React.useState({});
  const steps = ['Hamacher BOP CSV Import', 'Hamacher Descriptive CSV Import'];

  const handleStep = (step: any) => () => {
    setActiveStepName({ name: steps[step] });
    setActiveStep({ count: step });
  };

  const handleNext = () => {
    const newActiveStep: any = activeStep.count + 1;
    setActiveStep({ count: newActiveStep });
    activeStep.count = newActiveStep;

    if (activeStep?.count == 2) {
      props.history.push(`/medication`);
    }
    setActiveStepName({ name: steps[newActiveStep] });
    activeStepName.name = steps[newActiveStep];
  };

  const handleLoaderStart = () => {
    setIsLoader(true);
  };

  const handleLoaderEnd = () => {
    setIsLoader(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleLoaderStart();

    const file = acceptedFiles[0];
    const uploadFolder = 'HamacherXml';

    if (file) {
      let alertMessage: any = '';

      let filename = '';

      if (activeStep?.count == 0) {
        filename = 'HamacherBOP.xml';

        setFileNameFn('bop', file?.name);
      } else {
        filename = 'HamacherDescriptive.xml';
        setFileNameFn('descriptive', file?.name);
      }

      uploadFile(uploadFolder, file, filename)
        .then((response: any) => {
          let url = '';
          let key = '';
          if (activeStep?.count == 0) {
            url = 'hamcher-bop-csv-import';
          } else if (activeStep?.count == 1) {
            url = 'hamcher-descriptive-csv-import';
          }
          key = response?.data?.data?.Key;
          const body = JSON.stringify({
            key: key,
          });
          api
            .post(`${process.env.REACT_APP_ADMIN_API_URL}/profile/${url}`, body)
            .then((res: any) => {
              if (res?.data?.data?.data) {
                alertMessage = 'CSV Uploaded successfully ';
              } else {
                alertMessage = 'Something went to wrong!';
              }
              handleNext();
              handleLoaderEnd();
              notify(alertMessage, { type: 'success' });
            })
            .catch((error: any) => {
              notify(error?.message, { type: 'error' });
              setTimeout(() => {
                setAlert(false);
              }, 40000);
              return;
            });
        })
        .catch((err: any) => {
          // console.log(err, 'errorrrrrr');
          // showSnackBar(err?.message);
          notify(err?.message, { type: 'error' });
          if (activeStep?.count == 0) {
            setFileNameFn('bop', '');
          } else {
            setFileNameFn('descriptive', '');
          }
          handleLoaderEnd();
        });
    }
  }, []);

  const setFileNameFn = (key, value) => {
    setFileName((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  return (
    <Fragment>
      <Create {...props} className={'mainForm'} title="Medication Bulk Upload">
        <SimpleForm {...props} toolbar={null}>
          <CsvImport
            steps={steps}
            activeStep={activeStep?.count}
            activeStepName={activeStepName?.name}
            onDrop={onDrop}
            handleStep={handleStep}
            completed={completed}
          />
          <div className="file-display-container">
            <div className="file-status-bar">
              {fileName?.bop && (
                <div>
                  <div
                    className={`${classes.fileTypeLogo} file-type-logo`}
                  ></div>
                  <div className="file-type">
                    {fileName?.bop.split('.').pop()}
                  </div>
                  <span className="file-name">{fileName?.bop}</span>
                </div>
              )}
              {fileName?.descriptive && (
                <div>
                  <div
                    className={`${classes.fileTypeLogo} file-type-logo`}
                  ></div>
                  <div className="file-type">
                    {fileName?.descriptive.split('.').pop()}
                  </div>
                  <span className="file-name">{fileName?.descriptive}</span>
                </div>
              )}
            </div>
          </div>
        </SimpleForm>
      </Create>

      <Backdrop className={classes.backdrop} open={isLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
};
export default MedicineUpload;
