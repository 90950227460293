import { api } from '../provider/api';

export const getS3ImgUrl = async (image: any, source: any) => {
  let s3ImgUrl: any = {};
  if (image) {
    s3ImgUrl = await getImageRawFile(image, source);
  }
  return s3ImgUrl;
};

export const getImageRawFile = async (image: any, source) => {
  const result = await handles3ImgUpload(image.rawFile, source);
  return result;
};

const handles3ImgUpload = async (image: any, source) => {
  if (image) {
    const data = new FormData();
    data.append('file', image);
    const url = `/s3/s3-file-upload/${source}`;
    return api.post(url, data).then((response) => {
      // console.log(response, 'responseresponse');

      return response?.data;
    });
  } else {
    return null;
  }
};
