import { Button, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import {
  Labeled,
  Show,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useShowController,
} from 'react-admin';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';

import './MedicineForm.css';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: 0,
    width: '100%',
  },
  disableTopMargin: {
    marginTop: '0px',
  },
  image: {
    width: '90%',
    maxHeight: '15em',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20em',
    },
  },
}));
export const MedicineView = (props) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);

  const backToHome = () => {
    props.history.push(`/medication`);
  };
  const UserShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <CustomBreadcrumb variant="actions" />
    </TopToolbar>
  );
  const classes = useStyles();
  const onImageError = (ev) => {
    ev.target.src = './dummy_medicine.jpg';
  };
  return (
    <Show
      {...props}
      title="Medication Details"
      actions={<UserShowActions {...props} />}
    >
      <SimpleShowLayout>
        <Grid container spacing={2}>
          <Grid container item xs={12} lg={3} md={3} justifyContent="center">
            <img
              src={record?.drug_image ? record?.drug_image[0] : null}
              alt="User"
              className={classes.image}
              onError={onImageError}
            />
          </Grid>
          <Grid
            container
            item
            xs={12}
            lg={8}
            md={8}
            spacing={2}
            className="main_content"
          >
            <Grid item xs={8} className="sub_content">
              <Labeled label="Internet Description">
                <TextField source="internet_desc" />
              </Labeled>
            </Grid>
            <Grid item xs={8} className="sub_content">
              <Labeled label="Purpose">
                <TextField source="purpose" />
              </Labeled>
            </Grid>
            <Grid item xs={8} className="sub_content">
              <Labeled label="Active Ingred">
                <TextField source="active_ingred" />
              </Labeled>
            </Grid>
            <Grid item xs={8} className="sub_content">
              <Labeled label="Uses">
                <TextField source="uses" label="uses" />
              </Labeled>
            </Grid>
          </Grid>
        </Grid>
      </SimpleShowLayout>
    </Show>
  );
};
