import { Box } from '@material-ui/core';
import { CSSProperties, useEffect, useState } from 'react';
import PieChartSelection from '../../dashboard/Chart';

const PieChart = (props) => {
  const { data, id } = props;

  const [series, setSeries]: any = useState([]);
  const [options, setOptions]: any = useState({
    chart: {
      width: 380,
      type: 'pie',
      id: id || undefined,
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
          chart: {
            width: 400,
          },
          legend: {},
        },
      },
    ],
  });

  const setArrayofSeries = () => {
    const label: any = [];
    const result = data.map((res: any) => {
      label.push(res.label);
      return Number(res.count);
    });
    setSeries(result);
    setOptions({ ...options, labels: label });
  };

  useEffect(() => {
    setArrayofSeries();
  }, [data]);

  const styles = {
    pichartSection: {
      height: 'auto',
    },
  };
  return (
    <Box style={styles.pichartSection as CSSProperties}>
      <PieChartSelection series={series} options={options} />
    </Box>
  );
};

export default PieChart;
