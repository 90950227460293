import { FC } from 'react';
import Button from '@material-ui/core/Button';
import ThumbUp from '@material-ui/icons/ThumbUp';
import {
  useTranslate,
  useUpdate,
  useNotify,
  useRedirect,
  Record,
} from 'react-admin';

/**
 * This custom button demonstrate using useUpdate to update data
 */
const AcceptButton: FC<{ record: Record }> = ({ record }) => {
  const translate = useTranslate();
  const notify = useNotify();
  const redirectTo = useRedirect();

  const [approve, { loading }] = useUpdate(
    'reviews',
    record.id,
    { status: 'accepted' },
    record,
    {
      undoable: true,
      onSuccess: () => {
        notify(
          'resources.reviews.notification.approved_success',
          'info',
          {},
          true
        );
        redirectTo('/reviews');
      },
      onFailure: () => {
        notify('resources.reviews.notification.approved_error', 'warning');
      },
    }
  );

  return record && record.status === 'pending' ? (
    <Button
      variant="outlined"
      color="primary"
      size="small"
      onClick={approve}
      disabled={loading}
    >
      <ThumbUp
        color="primary"
        style={{ paddingRight: '0.5em', color: 'green' }}
      />
      {translate('resources.reviews.action.accept')}
    </Button>
  ) : (
    <span />
  );
};

export default AcceptButton;
