// react-admin
import {
  Edit,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

// components
import PersonalizedNotificationForm from './components/PersonalizedNotificationForm';

// utils
import { ListActions } from '../../utils/common';
import { TRIGGERS } from '../../utils/constants';
import CustomToolbar from './components/CustomToolbar';

/**
 * EditPersonalizedNotification component for editing personalized notifications.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the EditPersonalizedNotification component
 */
const EditPersonalizedNotification = (props: any) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  // transform
  const transform = (data: any) => {
    // Find the selected trigger based on the triggerValue in the input data
    const selectedTrigger = TRIGGERS.find(
      (trigger) => trigger?.id === data.triggerValue
    );

    // Create an initial payload object with properties from the input data
    const payload = {
      ...data,
      notificationData: [],
      userType: selectedTrigger?.userType,
    };

    // If the trigger allows multiple contents, iterate through notificationData
    if (selectedTrigger?.isMultipleContents) {
      data.notificationData.forEach((notificationObj) => {
        if (notificationObj.title && notificationObj.content) {
          payload.notificationData.push({
            title: notificationObj.title,
            content: notificationObj.content,
          });
        }
      });
    } // If the trigger allows a single content
    else {
      const notificationObject = {
        title: data.title,
        content: data.content,
      };
      payload.notificationData = [];
      payload.notificationData.push(notificationObject);
    }

    // Remove title and content properties from payload
    delete payload.title;
    delete payload.content;

    // Return the transformed payload for submission
    return payload;
  };

  const onSuccess = () => {
    notify(`Successfully updated.`, { type: 'success' });
    refresh();
    redirect('/personalized-notification');
  };

  const onError = (error: any) => {
    notify(error, { type: 'error' });
  };
  return (
    <>
      <Edit
        transform={transform}
        actions={<ListActions />}
        mutationMode="pessimistic"
        onSuccess={onSuccess}
        onFailure={onError}
        title="Edit Personalized Notification"
        {...props}
      >
        <SimpleForm toolbar={<CustomToolbar />}>
          <PersonalizedNotificationForm isEdit />
        </SimpleForm>
      </Edit>
    </>
  );
};
export default EditPersonalizedNotification;
