export var GET_LOCK = 'RA/GET_LOCK';
export var GET_LOCK_LOADING = 'RA/GET_LOCK_LOADING';
export var GET_LOCK_SUCCESS = 'RA/GET_LOCK_SUCCESS';
export var GET_LOCK_FAILURE = 'RA/GET_LOCK_FAILURE';
export var GET_LOCKS = 'RA/GET_LOCKS';
export var GET_LOCKS_LOADING = 'RA/GET_LOCKS_LOADING';
export var GET_LOCKS_SUCCESS = 'RA/GET_LOCKS_SUCCESS';
export var GET_LOCKS_FAILURE = 'RA/GET_LOCKS_FAILURE';
export var LOCK = 'RA/LOCK';
export var LOCK_LOADING = 'RA/LOCK_LOADING';
export var LOCK_SUCCESS = 'RA/LOCK_SUCCESS';
export var LOCK_FAILURE = 'RA/LOCK_FAILURE';
export var UNLOCK = 'RA/UNLOCK';
export var UNLOCK_LOADING = 'RA/UNLOCK_LOADING';
export var UNLOCK_SUCCESS = 'RA/UNLOCK_SUCCESS';
export var UNLOCK_FAILURE = 'RA/UNLOCK_FAILURE';
