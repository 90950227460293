import { FC } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Record } from 'react-admin';
import { decryption, parseDataBasedOnKey } from '../../component/common';

interface Props {
  className?: string;
  record?: Record;
  size?: string;
}

const AvatarField: FC<Props> = ({ record, size = '25', className }) =>
  record ? (
    <Avatar
      src={`${
        record?.photo
          ? record?.photo?.url
          : record?.url
          ? record?.url
          : record?.user?.url
      }?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      className={className}
    />
  ) : null;

export default AvatarField;
