import { FC } from 'react';
import { FilterList, FilterListItem } from 'react-admin';
import WcIcon from '@material-ui/icons/Wc';
import AvTimerIcon from '@material-ui/icons/AvTimer';
const AgeFilter: FC = () => (
  <FilterList label="resources.users.filters.age" icon={<AvTimerIcon />}>
    <FilterListItem
      label="resources.users.filters.lessthan18"
      value={{
        age: 'lessthan18',
      }}
    />
    <FilterListItem
      label="resources.users.filters.18to24"
      value={{
        age: '18to24',
      }}
    />
    <FilterListItem
      label="resources.users.filters.25to35"
      value={{
        age: '25to35',
      }}
    />
    <FilterListItem
      label="resources.users.filters.36to50"
      value={{
        age: '36to50',
      }}
    />
    <FilterListItem
      label="resources.users.filters.50above"
      value={{
        age: '50above',
      }}
    />
  </FilterList>
);

export default AgeFilter;
