import { Box, Card } from '@material-ui/core';
import { Typography } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { IsGenderChartData } from '../../utils/Interface';
import PieChartSelection from './Chart';
import ChartTable from './chartTable';

const styles = {
  pichartSection: { height: '100%' },
  pichartBoxSection: { padding: '16px' },
  tittle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
    fontWeight: '600',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    paddingBottom: '2%',
  },
};

const GenderChart = (props: any) => {
  const { genderData, totalAccount, id } = props;
  const [series, setSeries]: any = useState([]);
  const [totalCountGender, setTotalCountGender]: any = useState(0);
  const [genderChartData, setgenderChartData] = useState<
    IsGenderChartData[] | []
  >([]);
  const title = 'Gender';

  const columns = [
    { field: 'gender', headerName: 'Gender', with: 150 },
    { field: 'count', headerName: 'Number of users', with: 150 },
  ];

  const [options, setOptions]: any = useState({
    chart: {
      // width: 380,
      type: 'pie',
      id: 'gender',
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
          chart: {
            // width: 400
          },

          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  });
  const setArrayofSeries = () => {
    const label: any = [];
    const result = genderChartData.map((res: any) => {
      label.push(res.gender);
      return Number(res.count);
    });
    setSeries(result);
    setOptions({ ...options, labels: label });
  };
  const getTotalPercentage = async (data) => {
    if (data) {
      const Total = data.female + data.male + data.preferNotToSay;
      const sum = (Total / totalAccount) * 100;
      const finalResult = sum.toFixed(1);
      await setTotalCountGender(finalResult);
    }
  };

  const handleUpdateGenderChartData = (genderDatas) => {
    const tempData = [
      { gender: 'Male', count: genderDatas['male'] },
      {
        gender: 'Female',
        count: genderDatas['female'],
      },
      {
        gender: 'Other',
        count: genderDatas['preferNotToSay'],
      },
    ];
    setgenderChartData(tempData);
  };

  React.useEffect(() => {
    if (genderChartData?.length > 0) {
      setArrayofSeries();
    }
  }, [genderChartData]);

  React.useEffect(() => {
    if (genderData) {
      getTotalPercentage(genderData);
      handleUpdateGenderChartData(genderData);
    }
  }, [genderData]);

  // React.useEffect(() => {
  //   console.log({ series, options });
  // }, [series, options]);

  return (
    <Card style={styles.pichartSection as CSSProperties}>
      <Box style={styles.pichartBoxSection as CSSProperties}>
        <div style={styles.tittle as CSSProperties}>{title}</div>

        {totalAccount != 0 && (
          <PieChartSelection
            series={series}
            options={options}
            title={title}
            subTitile={`${totalCountGender}% of all the profiles provide gender`}
            next="gender"
          />
        )}

        <ChartTable rows={genderChartData} columns={columns} />
      </Box>
    </Card>
  );
};

export default GenderChart;
