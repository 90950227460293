// mui
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
// ---------------------------------------------------------------------------------------------------------

/**
 * Sort component for selecting the sorting order.
 * @param {Object} props - Component props
 * @param {Function} props.setSortOrder - Callback to set the sorting order
 * @param {string} props.sortOrder - Current sorting order ('DESC' or 'ASC')
 * @returns {JSX.Element} - JSX element representing the Sort component
 */
const Sort = (props: any) => {
  const { setSortOrder, sortOrder } = props;

  /**
   * Handles sorting order selection.
   * @param {string} sort - The selected sorting order ('DESC' or 'ASC')
   */
  const handleSort = (sort: string) => {
    switch (sort) {
      case 'DESC':
        setSortOrder('DESC');
        break;
      case 'ASC':
        setSortOrder('ASC');
        break;
      default:
        setSortOrder('DESC');
        break;
    }
  };

  return (
    <Box>
      <FormControl fullWidth sx={{ minWidth: '150px' }}>
        <InputLabel id="demo-simple-select-label">Sort </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={sortOrder}
          label="Sort"
          style={{ width: '100%' }}
        >
          <MenuItem onClick={() => handleSort('DESC')} value="DESC">
            Descending
          </MenuItem>
          <MenuItem onClick={() => handleSort('ASC')} value="ASC">
            Ascending
          </MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default Sort;
