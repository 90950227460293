import { FC } from 'react';
import {
  ListProps,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  CreateButton,
  TopToolbar,
  TextInput,
  FunctionField,
} from 'react-admin';
import { useMediaQuery, Theme } from '@material-ui/core';
import FAQMenu from './FAQMenu';
import { List, ListActions } from '@rg-admin/ra-enterprise';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import CustomerLinkField from '../user-management/CustomerLinkField';
import EnterpriseList from '../../component/ra-enterprise/List';
import StarRatingField from './StarRatingField';
import FAQListAction from './FAQListAction';
import EmptyList from '../../component/EmptyList';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';

const FAQFilter = (props) => (
  <div className={'filterClass'}>
    <Filter {...props}>
      <SearchInput
        style={{ minWidth: 400 }}
        source="searchKey"
        placeholder="Search By Medicine Name, Comment and Title"
        alwaysOn
      />
    </Filter>
  </div>
);

const ReviewListColumn = {
  medicine_name: (
    <TextField label="Medicine Name" sortable={false} source="medicine_name" />
  ),
  title: <TextField label="Title" sortable={false} source="title" />,
  comment: <TextField label="Comment" sortable={false} source="comment" />,
  rating: <StarRatingField sortable={false} />,
};

const FAQList: FC<ListProps> = (props: any) => {
  const isXsmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const rowClickFunction = (id, basePath, record) => {
    props.history.push(
      `/review/${record?.id}/show?userType=${record?.userType}`
    );
  };

  return (
    <EnterpriseList
      {...props}
      preferenceKey="faq.list"
      bulkActionButtons={false}
      // defaultColumns={ReviewListColumn}
      hasViewSelector={false}
      hasColumnsSelector={false}
      filters={<FAQFilter />}
      actions={
        <ListActions breadcrumb={<CustomBreadcrumb variant="actions" />} />
      }
      perPage={25}
      exporter={false}
      hasCreate={false}
      title="Reviews"
      sort={{ field: 'id', order: 'DESC' }}
      pagination={<PaginationWithCustomEmpty />}
    >
      {({
        columns,
        view,
        hasShow,
        hasList,
        hasEdit,
        syncWithLocation,
        ...rest
      }): JSX.Element => {
        return (
          <Datagrid
            noDelete
            rowClick={rowClickFunction}
            empty={<EmptyList resource={'Reviews'} />}
            {...rest}
          >
            <TextField
              label="Medicine Name"
              sortable={false}
              source="medicine_name"
            />
            <TextField label="Title" sortable={false} source="title" />,
            <TextField label="Comment" sortable={false} source="comment" />,
            <StarRatingField sortable={false} />,
            <FunctionField
              label="Status"
              render={(record: any) => <FAQListAction record={record} />}
            />
          </Datagrid>
        );
      }}
    </EnterpriseList>
    // <List
    //   {...props}
    //   resource="FAQ"
    //   actions={
    //     <ListActions breadcrumb={<CustomBreadcrumb variant="actions" />} />
    //   }
    //   filters={<FAQFilter />}
    //   perPage={25}
    //   bulkActionButtons={false}
    //   exporter={false}
    //   title="Reviews"
    //   hasList
    // >
    //   <Datagrid rowClick="edit">
    //     {/* <TextField label="User" sortable={false} source="user" /> */}

    //     {/* <FAQMenu {...props} /> */}
    //   </Datagrid>
    // </List>
  );
};

export default FAQList;
