export default function encryptionFields(key) {
  switch (key) {
    case 'firstName':
      return true;
    case 'email':
      return true;
    case 'DOB':
      return true;
    case 'photo':
      return true;
    case 'updatedAt':
      return true;
    case 'updatedBy':
      return true;
    case 'url':
      return true;
    case 'key':
      return true;
    case 'dosage':
      return true;
    case 'end_date':
      return true;
    case 'hamcode':
      return true;
    case 'medication_type':
      return true;
    case 'medication_type_image':
      return true;
    case 'medicine_name':
      return true;
    case 'start_date':
      return true;
    case 'unit':
      return true;
    default:
      return false;
  }
}
