// react
import { useState } from 'react';

// react-admin
import {
  Create,
  SimpleForm,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';

// components
import BlastNotificationForm from './components/BlastNotificationForm';

// utils
import CustomToolbar from './components/CustomToolbar';
import { ListActions } from '../../utils/common';
import { BlastNotificationFormValidation } from '../../utils/formValidations';

// ------------------------------------------------------------------------------------------------------
/**
 * Component for creating a new blast notification.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the CreateBlastNotification component
 */
const CreateBlastNotification = (props: any) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const refresh = useRefresh();

  // State
  const [isValidationFailed, setIsValidationFailed] = useState<boolean>(false);
  let isScheduled = false;

  // Transform function to prepare payload for submission
  const transform = (data: any) => {
    if (data?.schedule) {
      isScheduled = true;
    }
    const payload = {
      ...data,
      notificationType: 'B',
      status: 0,
      isActive: 1,
    };

    return payload;
  };

  const onSuccess = () => {
    notify(
      `Blast notification ${isScheduled ? 'scheduled' : 'sent'} successfully.`,
      {
        type: 'success',
      }
    );
    redirect('/blast-notification');
    refresh();
  };
  const onError = (error: any) => {
    notify(error, { type: 'error' });
  };
  // // Custom validation function for the form
  const formValidation = (values: any) => {
    const errors = BlastNotificationFormValidation(values);
    const hasErrors = Object.keys(errors).some((field) => errors[field]);

    setIsValidationFailed(hasErrors);

    return hasErrors ? errors : {};
  };

  return (
    <Create
      transform={transform}
      actions={<ListActions />}
      mutationMode="pessimistic"
      onSuccess={onSuccess}
      onFailure={onError}
      title="Create Blast Notification"
      {...props}
    >
      <SimpleForm
        mode="onChange"
        validate={formValidation}
        toolbar={<CustomToolbar isValidationFailed={isValidationFailed} />}
      >
        <BlastNotificationForm />
      </SimpleForm>
    </Create>
  );
};

export default CreateBlastNotification;
