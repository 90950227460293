import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Create, SimpleForm } from 'react-admin';
import CsvImport from '../../component/common/CsvImport';
import refresh from 'ra-core/esm/sideEffect/refresh';
import {
  Backdrop,
  CircularProgress,
  Snackbar,
  IconButton,
  makeStyles,
  Portal,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { api } from '../../provider/api';
import ZipFileUpload from '../../component/common/ZipFileUpload';
import { uploadZipFile } from '../../utils/zipUpload';

const useStyles = makeStyles((theme) => ({
  importDialogContent: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const MedicineZipFileUpload = (props: any) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [alert, setAlert] = useState(false);
  const [activeStep, setActiveStep] = React.useState<any>({ count: 0 });
  const [activeStepName, setActiveStepName] = React.useState<any>({
    name: 'Hamcher BOP CSV Import',
  });
  const [completed, setCompleted] = React.useState({});
  const steps = ['Hamcher BOP CSV Import', 'Hamcher Descriptive CSV Import'];

  const totalSteps = () => {
    return steps.length;
  };
  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep?.count === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep: any =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep.count + 1;
    setActiveStep({ count: newActiveStep });
    activeStep.count = newActiveStep;
    if (activeStep?.count == 2) {
      // window.opener = null;
      // window.open('', '_self');
      // window.close();
    }
    setActiveStepName({ name: steps[newActiveStep] });
    activeStepName.name = steps[newActiveStep];
  };

  const handleLoaderStart = () => {
    setIsLoader(true);
  };

  const handleLoaderEnd = () => {
    setIsLoader(false);
  };

  const showSnackBar = (message: any) => {
    setAlert(true);
    setAlertContent(message);
  };

  const closeSnackBar = (
    event: React.SyntheticEvent | React.MouseEvent,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlert(false);
  };

  const onDrop = useCallback((acceptedFiles) => {
    handleLoaderStart();

    const file = acceptedFiles[0];
    const uploadFolder = 'multum';
    if (file) {
      uploadZipFile(uploadFolder, file)
        .then((response: any) => {
          let key = '';
          // if (activeStep?.count == 0) {
          //   url = 'hamcher-bop-csv-import';
          // } else if (activeStep?.count == 1) {
          //   url = 'hamcher-descriptive-csv-import';
          // }
          key = response?.data?.data?.Key;
          const body = JSON.stringify({
            key: key,
          });
          // console.log(body, 'bodybodybody');

          // api
          //   .post(`${process.env.REACT_APP_ADMIN_API_URL}/profile/${url}`, body)
          //   .then((res: any) => {
          //     console.log(res.data, 'res.data');
          //     if (res?.data?.data?.data) {
          //       alertMessage = 'CSV Uploaded successfully ';
          //     } else {
          //       alertMessage = 'Something went to wrong!';
          //     }
          //     handleNext();
          handleLoaderEnd();
          //     showSnackBar(alertMessage);
          //   })
          //   .catch((error: any) => {
          //     showSnackBar(error?.message);
          //     return;
          //   });
        })
        .catch((err: any) => {
          console.error(err);
          handleLoaderEnd();
        });
    }
  }, []);

  return (
    <Fragment>
      <Create {...props} className={'mainForm'} title="Medication Bulk Upload">
        <SimpleForm {...props} toolbar={null}>
          <ZipFileUpload
            isLoader={isLoader}
            handleLoaderStart={handleLoaderStart}
            handleLoaderEnd={handleLoaderEnd}
            showSnackBar={showSnackBar}
            steps={steps}
            activeStep={activeStep?.count}
            activeStepName={activeStepName?.name}
            onDrop={onDrop}
            completed={completed}
          />
        </SimpleForm>
      </Create>

      <Backdrop className={classes.backdrop} open={isLoader}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Portal>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={alert}
          autoHideDuration={10000}
          onClose={closeSnackBar}
          message={alertContent}
          action={
            <>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={closeSnackBar}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </Portal>
    </Fragment>
  );
};
export default MedicineZipFileUpload;
