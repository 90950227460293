//material ui
import { Card, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

//react admin
import { useTranslate } from 'react-admin';

//components
import CardDetails from './CardDetails';
//---//

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 16px 50px 16px',
    border: 'none',
  },
  subRoot: {
    boxShadow: '3px 3px 8px 0px #b5b5b5',
  },
  typography: {
    flexGrow: 1,
    textAlign: 'center',
  },
  headingTypography: {
    flexGrow: 1,
    textAlign: 'center',
    padding: '16px 0',
    marginTop: '0.35em',
    fontWeight: 600,
  },
}));

const Welcome = (props: any) => {
  const { dashboardData } = props;

  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Typography
        variant="h4"
        className={classes.headingTypography}
        gutterBottom
        component="div"
      >
        Dashboard
      </Typography>
      <Grid container spacing={6} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4} md={3}>
          <CardDetails
            icon={'fa-solid:users'}
            title={translate('Total App Users')}
            subtitle={dashboardData?.totalUsers}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <CardDetails
            icon={'mdi:users'}
            title={translate('Total Profiles')}
            subtitle={dashboardData?.totalProfiles}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={4} md={3}>
          <CardDetails
            icon={'fontisto:male'}
            title={translate('Male')}
            subtitle={dashboardData?.userAnalytics?.male}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <CardDetails
            icon={'fontisto:famale'}
            title={translate('Female')}
            subtitle={dashboardData?.userAnalytics?.female}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <CardDetails
            icon={'healthicons:person'}
            title={translate('Prefer not to say')}
            subtitle={dashboardData?.userAnalytics?.preferNotToSay}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default Welcome;
