import React from 'react';
import {
  Show,
  TopToolbar,
  SimpleShowLayout,
  Labeled,
  TextField,
  useGetOne,
} from 'react-admin';
import { Grid, makeStyles, Theme } from '@material-ui/core';

import './ReviewShow.css';

//components
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import StarRatingField from './StarRatingField';
import CustomerLinkField from '../user-management/CustomerLinkField';
import ShowHideButton from '../../component/ShowHideButton';
import ReviewDateinview from './ReviewDateinview';
const ReviewShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <CustomBreadcrumb variant="actions" />
  </TopToolbar>
);

const ReviewShow = (props: any) => {
  return (
    <Show
      {...props}
      title="Review Details"
      actions={<ReviewShowActions {...props} />}
    >
      <SimpleShowLayout>
        <Labeled label="Medicine Name">
          <TextField source="medicine_name" className="review_medicine_name" />
        </Labeled>

        <ReviewDateinview />

        <TextField label="" source="title" className="review_title" />
        <StarRatingField />

        <TextField label="" source="comment" />
        <ShowHideButton {...props} />
      </SimpleShowLayout>
    </Show>
  );
};

export default ReviewShow;
