import { useRecordContext } from 'react-admin';
import AvatarField from '../../../component/AvatarField';
// -------------------------------------------------------------------------------------------------

/**
 * UserAvatar component for rendering the user's avatar.
 * @returns {JSX.Element} - JSX element representing the UserAvatar component.
 */
const UserAvatar = () => {
  const record = useRecordContext();
  if (!record) return null;
  return <AvatarField record={record} size={'150'} />;
};

export default UserAvatar;
