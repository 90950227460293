// react admin
import { FunctionField, Show, TextField } from 'react-admin';

// mui
import { Card, Grid, Typography } from '@mui/material';

// helper functions
import { ListActions } from '../../utils/common';
import { dateFormat } from '../../utils/helper';
import {
  renderNotificationStatus,
  renderUserType,
} from '../symptom/components/helperFunction';
// -------------------------------------------------------------------------------------------------------
/**
 * Component to show symptoms details.
 * @param {object} props - The component props.
 * @returns {JSX.Element} - The JSX element representing the show symptoms view.
 */
const ShowBlastNotification = (props) => {
  return (
    <Show
      {...props}
      actions={<ListActions />}
      title={`Blast Notification Details`}
    >
      <Card
        sx={{
          margin: 2,
          padding: 2,
          width: { xs: '100%', lg: '60%' },
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Label text="Title" />
            <TextField source="notificationTitle" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Date" />
            <FunctionField
              render={(record) => dateFormat(record?.schedule, 'MM-DD-YYYY')}
            />
          </Grid>
          <Grid item xs={12}>
            <Label text="Content" />
            <TextField source="notificationContent" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="User Type" />
            <FunctionField
              key="userType"
              render={(record) => renderUserType(record?.userType)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Status" />
            <FunctionField
              label="Status"
              key="status"
              render={(record) => renderNotificationStatus(record?.status)}
            />
          </Grid>
        </Grid>
      </Card>
    </Show>
  );
};
const Label = ({ text }: { text: string }) => (
  <Typography color="rgba(0, 0, 0, 0.54)" fontSize="12px">
    {text}
  </Typography>
);
export default ShowBlastNotification;
