const ALPHABETS = /^[A-Za-z\s!@#$%^&*()_+=-`~\\\]\[{}|'";:/.,?><]*$/;

const MESSAGE_ALPHABETS = 'Enter a valid name'; //'Allow only alphabets'

interface IBasePatternValidator {
  value?: string;
  pattern: RegExp;
  message: string;
  minNumber?: any;
  maxNumber?: any;
  fileType?: any;
}

const basePatternValueValidator = ({
  value,
  pattern,
  message,
  minNumber,
}: IBasePatternValidator) => {
  if (value) {
    const regex = new RegExp(pattern, 'i');

    return regex.test(value.trim()) ? undefined : message;
  }
};

export const alphabets = () => {
  return (value?: string) =>
    basePatternValueValidator({
      value,
      pattern: ALPHABETS,
      message: MESSAGE_ALPHABETS,
    });
};
