// import ReviewIcon from '@material-ui/icons/Comment';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import { FAQCreate } from './FAQCreate';
import { FAQEdit } from './FAQEdit';
import FAQList from './FAQList';
import { FAQView } from './FAQView';
import ReviewShow from './ReviewShow';

export default {
  list: FAQList,
  create: FAQCreate,
  edit: FAQEdit,
  show: ReviewShow,
  icon: QuestionAnswerIcon,
};
