import { Chip } from '@mui/material';

export const renderContent = (content: string) => (content ? content : '--');
export const renderContentSliced = (content: string, size: number) => {
  if (content && content.length > size) {
    return content.slice(0, size) + '...';
  } else {
    return content ? content : '--';
  }
};
export const renderArrayContentSliced = (array) => {
  if (Array.isArray(array) && array?.length > 0) {
    return (
      <>
        {array?.map((item, index) => (
          <Chip
            key={index}
            label={item.length > 12 ? item.slice(0, 9) + '...' : item}
            sx={{ height: '20px', width: '92px', margin: '0 5px 5px 0' }}
          />
        ))}
      </>
    );
  } else {
    return '--';
  }
};

// export const renderArrayContent = (array) => {
//   if (Array.isArray(array) && array?.length > 0) {
//     return (
//       <ul style={{ marginLeft: -20 }}>
//         {array?.map((item, index) => (
//           <li key={index}>{item}</li>
//         ))}
//       </ul>
//     );
//   } else {
//     return '--';
//   }
// };

export const renderArrayContent = (array) => {
  if (Array.isArray(array) && array?.length > 0) {
    if (array.length === 1) {
      return <span>{array[0]}</span>;
    } else {
      const lastItem = array[array.length - 1];
      const otherItems = array.slice(0, -1).join(', ');
      return (
        <span>
          {otherItems} and {lastItem}
        </span>
      );
    }
  } else {
    return '--';
  }
};

export const renderUserType = (type: string) => {
  switch (type) {
    case 'F':
      return 'Free';
    case 'P':
      return 'Paid';
    default:
      return 'Both';
  }
};

export const renderNotificationStatus = (status: number) => {
  const color = status === 1 ? 'success' : 'warning';
  const label = status === 1 ? 'Sent' : 'Scheduled';

  return (
    <Chip label={label} color={color} sx={{ height: '20px', width: '92px' }} />
  );
};

export const renderPrice = (price: string | number) => {
  return `$${price}`;
};

export const renderPlanType = (record: any) => {
  const label = record?.type === 'FREE' ? 'Free' : 'Paid';
  return <Chip label={label} sx={{ height: '20px', width: '92px' }} />;
};
