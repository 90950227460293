import axios, { AxiosInstance } from 'axios';
import { decrypt } from '../utils/crypto';

export const getToken = () => localStorage.getItem('access_token');

export const getadminProfile = () => sessionStorage.getItem('admin_profile');

let instance: AxiosInstance = generateInstance(getadminProfile());

// instance.interceptors.request.use(async function (config) {
//   await getClientPublicKey().then(res => {
//     console.log(res, 'res keypair');

//     config.headers.Public_Key = res?.publicKey;
//     localStorage.setItem('secretKey', res?.secretKey);
//   })
//   return config;
// });

function generateInstance(adminProfile: string | null) {
  const user = JSON.parse(decrypt(adminProfile));
  // token = token !== '' ? token : '';
  return axios.create({
    baseURL: process.env.REACT_APP_ADMIN_API_URL,
    headers: {
      'Content-type': 'application/json',
      email: user?.email,
      // password: user?.password,
      password: '08a00f15e2aed801bd0bf2cb26add1e9',

      // Authorization: `Bearer ${token}`,
      clientPublicKey: localStorage.getItem('Public_Key'),
    },
  });
}

export const setToken = (token: string) => {
  localStorage.setItem('access_token', token);
  instance = generateInstance(token);
};

export const setUserProfile = (adminProfile: string) => {
  sessionStorage.setItem('admin_profile', adminProfile);
  instance = generateInstance(adminProfile);
};

export const setPermission = (permission: string) => {
  localStorage.setItem('permission', permission);
};
export const setUserId = (id: any) => {
  localStorage.setItem('userId', id);
};

export const removeToken = () => {
  localStorage.removeItem('access_token');
  localStorage.removeItem('userId');
  localStorage.removeItem('secretKey');
  localStorage.removeItem('Public_Key');
};

export const removeUseProfile = () => {
  sessionStorage.removeItem('admin_profile');
};

export function get<T = any>(url: string, header?: any) {
  return instance.get<T>(url, header);
}

export function post(url: string, data: any, header?: any) {
  return instance.post(url, data, header);
}

export function patch<T = any>(url: string, data: any, header?: any) {
  return instance.patch<T>(url, data, header);
}

export function put<T = any>(url: string, data: any, header?: any) {
  return instance.put<T>(url, data, header);
}

export function del(url: string, data?: any) {
  return instance.delete(url, { data });
}

// function formatResponse(response) {
//   return Promise.resolve({
//     status: response.status,
//     statusText: response.statusText,
//     headers: response.headers,
//     data: response.data,
//   });
// }

export const api = {
  get,
  post,
  patch,
  put,
  del,
};
