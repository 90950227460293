import React, { useEffect } from 'react';
import './chart.css';
import Chart from 'react-apexcharts';
import { Button, Typography } from '@mui/material';

export default class PieChartSelection extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      options: props.options,
      series: props.series,
      title: props.title,
      width: props.width,
      subTitile: props.subTitile,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.series !== prevProps.series) {
      this.setState({
        options: this.props.options,
        series: this.props.series,
        title: this.props.title,
        width: this.props.width,
        subTitile: this.props.subTitile,
      });
    }
  }

  public render(): JSX.Element {
    return (
      <div className="container APEchart">
        {/* <div className="title">{this.state.title}</div> */}
        <Typography
          sx={{
            fontSize: '15px',
            color: '#00000078',
            fontFamily: 'inherit',
            fontWeight: 500,
          }}
        >
          {this.state.subTitile}
        </Typography>
        <div className="container chart-section">
          <Chart
            options={this.state.options}
            series={this.state.series}
            type={'pie'}
            width={this.state.width ? this.state.width : '100%'}
            key={this.state.series.length}
          />{' '}
        </div>
      </div>
    );
  }
}
