import { makeStyles } from '@material-ui/core';

import React, { Fragment } from 'react';
import { useRefresh, Labeled } from 'react-admin';
import CustomerLinkField from '../user-management/CustomerLinkField';
const useStyles = makeStyles({
  icon_action_button_show: {
    color: 'green',
  },
  icon_action_button_hide: {
    color: 'red',
  },
});

const ReviewDateinview = (props) => {
  const { resource, record } = props;

  const refresh = useRefresh();
  const classes = useStyles();

  return (
    <Fragment>
      <Labeled label={`Review Added Date:${record?.creationDate}`}>
        <CustomerLinkField {...props} />
      </Labeled>
    </Fragment>
  );
};

export default ReviewDateinview;
