import { Tabs, Divider, Tab, makeStyles } from '@material-ui/core';
import { List } from '@rg-admin/ra-enterprise';
import {
  ChangeEvent,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Datagrid, TextField, CardActions, FunctionField } from 'react-admin';
import { CustomFunctionField } from '../../component/common';
import EmptyList from '../../component/EmptyList';
import CustomerLinkField from './CustomerLinkField';

const userTabs = [
  { id: 'profile', name: 'Profile' },
  { id: 'medication', name: 'Medication' },
];
const profileTabs = [{ id: 'medication', name: 'Medication' }];
const NoneActions = () => <CardActions />;

const TabbedDatagrid: FC<any> = (props) => {
  const { displayedFilters, records, userId } = props;
  const [filterValues, setFilters] = useState(props?.filterValues);
  const [recordsType, setRecords] = useState(records);
  // const params = new URLSearchParams(props?.location?.search);
  // const userId = params.get('userId');

  const rowClickFunction = (id, basePath, record) => {
    props.history.push(
      `/users/all/${record?.id}/show?userId=${record?.accountId}`
    );
  };

  const renderHamcode = (record: any) => {
    let value = '';
    if (record.hamcode != '') {
      value = record?.hamcode;
    } else {
      value = `--`;
    }

    return value;
  };

  useEffect(() => {
    setRecords(records);
  }, [records]);
  useEffect(() => {
    setFilters({ ...filterValues, status: props?.filterValues?.status });
  }, [props.filterValues]);

  const handleChange = useCallback(
    (event: ChangeEvent<unknown>, value: any) => {
      setFilters && setFilters({ ...filterValues, status: value });
    },
    [displayedFilters, filterValues, setFilters]
  );

  return (
    <Fragment>
      <Tabs
        variant="fullWidth"
        centered
        value={filterValues?.status}
        indicatorColor="primary"
        onChange={handleChange}
      >
        {recordsType?.userType == 'profile'
          ? profileTabs.map((choice) => (
              <Tab key={choice.id} label={choice.name} value={choice.id} />
            ))
          : userTabs.map((choice) => (
              <Tab key={choice.id} label={choice.name} value={choice.id} />
            ))}
      </Tabs>
      <Divider />
      {/* {isXSmall ? (
        <ListContextProvider value={{ ...listContext, ids: selectedIds }}>
          <MobileGrid {...props} ids={selectedIds} />
        </ListContextProvider>
      ) : ( */}
      <div>
        {filterValues.status === 'profile' && (
          <List
            perPage={25}
            actions={<NoneActions />}
            {...props}
            filter={{ accountId: props?.id }}
            resource="Profile"
            bulkActionButtons={false}
            title=" "
            empty={<EmptyList resource={'Profile'} />}
          >
            <Datagrid
              {...props}
              optimized
              rowClick={rowClickFunction}
              data-testid="order-profile-datagrid"
            >
              <CustomerLinkField />
              <TextField source="dob" label="DOB" sortable={false} />
              <FunctionField
                label="Gender"
                render={(record) =>
                  record?.gender == 'Other'
                    ? 'Prefer not to say'
                    : record?.gender
                }
              />
            </Datagrid>
          </List>
        )}
        {filterValues.status === 'medication' && (
          <List
            perPage={25}
            actions={<NoneActions />}
            {...props}
            title=" "
            filter={
              recordsType?.userType == 'user'
                ? { accountId: props?.id }
                : { accountId: userId, profileId: props?.id }
            }
            resource="Medications"
            bulkActionButtons={false}
            empty={<EmptyList resource={'Medication'} />}
          >
            <Datagrid {...props}>
              <FunctionField
                sortable={false}
                label="Hamcode"
                render={(record: any) => renderHamcode(record)}
              />
              <TextField
                source="medicationName"
                label="Medication Name"
                sortable={false}
              />
              <TextField
                source="medicationType"
                label="Medication Type"
                sortable={false}
              />
              {/* <TextField source="dosage" sortable={false} /> */}
            </Datagrid>
          </List>
        )}
      </div>
      {/* )} */}
    </Fragment>
  );
};

export default TabbedDatagrid;
