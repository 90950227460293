// react
import { useState } from 'react';
// apex-chart
import ReactApexChart from 'react-apexcharts';
// utils
import { dateFormat } from '../../../utils/helper';
// mui
import { IconButton, Button, Box } from '@mui/material';
// iconify
import Iconify from '../../../component/iconify/Iconify';
import { ApexOptions } from 'apexcharts';
// ----------------------------------------------------------------------------------------

/**
 * BarChart component for displaying bar chart visualizations.
 * @param {Object} props - Component props
 * @param {Array} props.data - Data for the bar chart
 * @param {string} props.name - Name of the chart
 * @param {boolean} props.isRegistered - Flag indicating if the chart is for registered users
 * @param {boolean} props.isAppAnalytics - Flag indicating if the chart is for app analytics
 * @returns {JSX.Element} - JSX element representing the BarChart component
 */
const WaveChart = (props) => {
  const { data, name, isRegistered, isAppAnalytics } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of bars to show per page
  const MAX_VISIBLE_PAGES = 4; // Adjust this to control the number of visible page numbers

  const categories: any = [];
  const dataSeriesTotalUser: any = [];
  const dataSeriesTotalSubscription: any = [];
  const dataSeriesTotalUnSubscription: any = [];
  const datanewRegistrations: any = [];
  const datanewSubscriptions: any = [];
  const datanewUnSubscriptions: any = [];

  if (isRegistered || isAppAnalytics) {
    data?.forEach((entry) => {
      categories.push(dateFormat(entry.label, 'MM-DD-YYYY'));
      dataSeriesTotalUser.push(entry.totalUsers);
      dataSeriesTotalSubscription.push(entry.totalSubscription);
      dataSeriesTotalUnSubscription.push(entry.totalUnSubscription);
      datanewRegistrations.push(entry.newRegistrations);
      datanewSubscriptions.push(entry.newSubscriptions);
      datanewUnSubscriptions.push(entry.newUnSubscriptions);
    });
  } else {
    data?.forEach((entry) => {
      categories.push(dateFormat(entry.label, 'MM-DD-YYYY'));
      dataSeriesTotalUser.push(entry.totalUsers);
      dataSeriesTotalSubscription.push(entry.totalSubscription);
      dataSeriesTotalUnSubscription.push(entry.totalUnSubscription);
      datanewRegistrations.push(entry.newRegistrations);
      datanewSubscriptions.push(entry.newSubscriptions);
      datanewUnSubscriptions.push(entry.newUnSubscriptions);
    });
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const visibleCategories = categories.slice(startIndex, endIndex);
  const visibleDataSeries = dataSeriesTotalUser.slice(startIndex, endIndex);
  const visibleDataSeriesSubscription = dataSeriesTotalSubscription.slice(
    startIndex,
    endIndex
  );
  const visibleDataSeriesUnSubscription = dataSeriesTotalUnSubscription.slice(
    startIndex,
    endIndex
  );

  const visibleDatanewRegistrations = datanewRegistrations.slice(
    startIndex,
    endIndex
  );
  const visibledatanewSubscriptions = datanewSubscriptions.slice(
    startIndex,
    endIndex
  );
  const visibledatanewUnSubscriptions = datanewUnSubscriptions.slice(
    startIndex,
    endIndex
  );

  console.log(categories, 'visibleCategories');

  const handlePageChange = (newPage) => {
    if (
      newPage >= 1 &&
      newPage <= Math.ceil(categories.length / itemsPerPage)
    ) {
      setCurrentPage(newPage);
    }
  };

  const series = [
    // {
    //   name: "Total Sighup Users",
    //   data: visibleDataSeries,
    // },
    // {
    //   name: "Total Subscribed Users",
    //   data: visibleDataSeriesSubscription,
    // },
    // {
    //   name: "Total UnSubscribed Users",
    //   data: visibleDataSeriesUnSubscription,
    // },
    {
      name: 'Sign Ups',
      data: visibleDatanewRegistrations,
    },
    {
      name: 'Subscribers',
      data: visibledatanewSubscriptions,
    },
    {
      name: 'Unsubscribers',
      data: visibledatanewUnSubscriptions,
    },
  ];

  const options: ApexOptions = {
    chart: {
      // eslint-disable-next-line @typescript-eslint/prefer-as-const
      type: 'line' as 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      show: true,
      curve: 'smooth',
    },
    fill: {
      colors: ['#F44336', '#E91E63', '#9C27B0'],
    },

    xaxis: {
      categories: visibleCategories,
      position: 'bottom',
      tooltip: {
        enabled: true,
      },
    },
    yaxis: [
      {
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
    ],
  };

  const getPageNumbers = () => {
    const totalPages = Math.ceil(categories.length / itemsPerPage);
    const currentPageIndex = currentPage - 1;

    let startPage = Math.max(
      currentPageIndex - Math.floor(MAX_VISIBLE_PAGES / 2),
      0
    );
    const endPage = Math.min(startPage + MAX_VISIBLE_PAGES - 1, totalPages - 1);

    if (endPage - startPage + 1 < MAX_VISIBLE_PAGES) {
      startPage = Math.max(endPage - MAX_VISIBLE_PAGES + 1, 0);
    }

    const pageNumbers: any = [];
    if (startPage > 0) {
      pageNumbers.push(1);
      if (startPage > 1) {
        pageNumbers.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i + 1);
    }

    if (endPage < totalPages - 1) {
      if (endPage < totalPages - 2) {
        pageNumbers.push('...');
      }
      pageNumbers.push(totalPages);
    }

    return pageNumbers;
  };

  return (
    <Box style={{ maxWidth: '800px', margin: '0 auto', position: 'relative' }}>
      <ReactApexChart options={options} series={series} type="line" />

      {categories.length > itemsPerPage && (
        <Box sx={{ position: 'absolute', top: '50%', right: 0 }}>
          <IconButton
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <Iconify icon="ic:round-less-than" width={25} height={25} />
          </IconButton>

          {getPageNumbers().map((pageNumber, index) => (
            <Button
              key={index}
              onClick={() =>
                typeof pageNumber === 'number' && handlePageChange(pageNumber)
              }
              sx={{
                padding: '10px',
                margin: '0 2px',
                color: currentPage === pageNumber ? '#9A9B9E' : '#2f3799',
                cursor: currentPage === pageNumber ? 'default' : 'pointer',
              }}
            >
              {pageNumber}
            </Button>
          ))}

          <IconButton
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={endIndex >= categories.length}
          >
            <Iconify icon="ic:round-greater-than" width={25} height={25} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default WaveChart;
