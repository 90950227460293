import React, { CSSProperties, useState, useEffect } from 'react';

//material ui
import { Box, Card, Grid } from '@material-ui/core';

//compnents
import PieChartSelection from './Chart';
import ChartTable from './chartTable';

//utils
import { IsAgeChartData } from '../../utils/Interface';
//----//

const styles = {
  pichartSection: { height: '100%' },
  pichartBoxSection: { padding: '16px' },
  tittle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
    fontWeight: '600',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    paddingBottom: '2%',
  },
};

const AgeChart = (props: any) => {
  const { ageData, totalAccount } = props;

  const [series, setSeries]: any = useState([]);
  const [totalCountageProvided, setTotalCountageProvided]: any = useState(0);
  const [ageChartData, setageChartData] = useState<IsAgeChartData[] | []>([]);

  const title = 'Age';
  const columns = [
    { field: 'range', headerName: 'Range' },
    { field: 'count', headerName: 'Number of users' },
  ];

  const [options, setOptions]: any = useState({
    chart: {
      width: 380,
      type: 'pie',
      id: props?.id,
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            // position: 'bottom'
          },
        },
      },
    ],
  });

  const setArrayofSeries = () => {
    const label: any = [];
    const result = ageChartData.map((res: any) => {
      label.push(res.range);
      return Number(res.count);
    });
    setSeries(result);
    setOptions({ ...options, labels: label });
  };

  const getTotalPercentage = async (data) => {
    if (data) {
      const total = await data?.reduce((sum, item) => {
        return sum + item.count;
      }, 0);
      const sum = (total / totalAccount) * 100;
      const finalResult = sum.toFixed(1);
      setTotalCountageProvided(finalResult);
    }
  };

  const handleUpdateAgeChartData = (ageDatas) => {
    const tempData = ageDatas?.map((ageData: any) => {
      return {
        count: ageData?.count,
        range: ageData?.ageRange,
      };
    });
    setageChartData(tempData);
  };

  useEffect(() => {
    // if (ageChartData?.length > 0) {
    setArrayofSeries();
    // }
  }, [ageChartData]);

  useEffect(() => {
    if (ageData) {
      handleUpdateAgeChartData(ageData);
      getTotalPercentage(ageData);
    }
  }, [ageData]);

  useEffect(() => {
    // console.log({ series, options });
  }, [series, options]);

  const data = ageChartData
    .map((item) => item.count)
    .every((count) => count === 0);

  return (
    <Card style={styles.pichartSection as CSSProperties}>
      <Box style={styles.pichartBoxSection as CSSProperties}>
        <div style={styles.tittle as CSSProperties}>{title}</div>

        <Grid container spacing={3}>
          {!data && (
            <Grid item xs={12} lg={7}>
              <PieChartSelection
                series={series}
                options={options}
                title={title}
                subTitile={`${totalCountageProvided}% of all the profiles provide age`}
                next="age"
              />
            </Grid>
          )}
          <Grid item xs={12} lg={5}>
            <Box
              sx={{ paddingTop: { xs: '0px', lg: `${!data}? '100px':'0px'` } }}
            >
              <ChartTable rows={ageChartData} columns={columns} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default AgeChart;
