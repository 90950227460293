import React, { CSSProperties, useEffect, useState } from 'react';

//material ui
import { Box, Card, Grid } from '@material-ui/core';

//components
import ChartTable from './chartTable';
import BarChart from './BarChart';

//utils
import { IsMostPopularDrugsChartData } from '../../utils/Interface';
//-----//

const styles = {
  pichartSection: { height: '100%' },
  pichartBoxSection: { padding: '16px' },
};

const MostPopularChart = (props: any) => {
  const { mostPopularDrugs, title } = props;

  const [series, setSeries]: any = useState([]);
  const [mostPopularDrugsChartData, setmostPopularDrugsChartData] = useState<
    IsMostPopularDrugsChartData[] | []
  >([]);

  const columns = [
    { field: 'medicine', headerName: 'Medicine' },
    { field: 'count', headerName: 'Number of users' },
  ];

  const [options, setOptions]: any = useState({
    chart: {
      id: props?.id,
      toolbar: { show: false },
      height: '900px',
      type: 'bar',
      sparkline: {
        // enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 760,
        options: {
          chart: {
            width: 400,
            toolbar: { show: false },
          },
          legend: {
            // position: 'bottom'
          },
        },
      },
    ],

    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
  });

  const setArrayofSeries = (mostPopularDrugsChartData) => {
    const label: any = [];
    const result = mostPopularDrugsChartData.map((res: any) => {
      label.push(res.medicine);
      return Number(res.count);
    });
    setSeries(result);

    setOptions((prevOptions) => ({
      ...prevOptions,
      xaxis: {
        medication: label,
      },
      labels: label,
    }));

    // setOptions((prevOptions) => ({
    //   ...prevOptions,
    //   xaxis: {
    //     tickAmount: 2,
    //     labels: {

    //       formatter: function (val) {
    //         // format the labels using a conditional statement
    //         if (val > 0 && val < 1) {
    //           return ''; // return an empty string to hide the label
    //         } else {
    //           return val.toFixed(2);
    //         }
    //       },
    //     },
    //   },
    //   labels: label,
    // }));
  };

  const handleUpdateMostPopularDrugsChartData = async (mostPopularDrugs) => {
    const tempData = await mostPopularDrugs?.map((mostPopularDrug) => {
      return {
        medicine: mostPopularDrug?.medicine_name,
        count: mostPopularDrug?.users_count,
      };
    });
    setmostPopularDrugsChartData(tempData);
  };

  useEffect(() => {
    // if (mostPopularDrugsChartData?.length > 0) setArrayofSeries();
    setArrayofSeries(mostPopularDrugsChartData);
  }, [mostPopularDrugsChartData]);

  useEffect(() => {
    if (mostPopularDrugs) {
      handleUpdateMostPopularDrugsChartData(mostPopularDrugs);
    }
  }, [mostPopularDrugs]);

  // useEffect(() => {
  //   console.log({ series, options });
  // }, [series, options]);

  return (
    <Card style={styles.pichartSection as CSSProperties}>
      <Box style={styles.pichartBoxSection as CSSProperties}>
        <Grid container>
          <Grid item xs={12} lg={6}>
            {series?.length > 0 && (
              <BarChart
                series={[
                  {
                    name: 'usage',
                    data: series,
                  },
                ]}
                options={options}
                title={title}
                // height={2500}
                height={
                  series?.length <= 3 ? 300 : series?.length <= 10 ? 1000 : 2500
                }
                // height={'100%'}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {/* {console.log(
              mostPopularDrugsChartData,
              'mostPopularDrugsChartData'
            )} */}
            {mostPopularDrugsChartData?.length > 0 && (
              <Box sx={{ paddingTop: { xs: '0px', lg: '50px' } }}>
                <ChartTable
                  alignLeft={true}
                  rows={mostPopularDrugsChartData}
                  columns={columns}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export default MostPopularChart;
