import React from 'react';
import './chart.css';
import Chart from 'react-apexcharts';
import { Typography } from '@mui/material';

export default class BarChart extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      options: props.options,
      series: props.series,
      title: props.title,
      width: props.width,
      height: props.height,
      subTitile: props.subTitile,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.series !== prevProps.series) {
      this.setState({
        options: this.props.options,
        series: this.props.series,
        title: this.props.title,
        width: this.props.width,
        subTitile: this.props.subTitile,
      });
    }
  }

  public render(): JSX.Element {
    return (
      <div className="container APEchart">
        <div className="title">{this.state.title}</div>
        <Typography
          sx={{
            fontSize: '15px',
            color: '#00000078',
            fontFamily: 'inherit',
            fontWeight: 500,
          }}
        >
          {this.state.subTitile}
        </Typography>
        <div className="container chart-section">
          {console.log(
            this.state.series,
            `this.state.series ${this.props.next}`
          )}
          <Chart
            options={this.state.options}
            series={this.state.series}
            type={'bar'}
            width={this.state.width}
            height={this.state.height}
          />{' '}
        </div>
      </div>
    );
  }
}
