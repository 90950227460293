import { Stack } from '@mui/material';
import { makeStyles } from '@material-ui/core';

import React, { Fragment, ReactElement, useState } from 'react';
import {
  Button,
  useRefresh,
  Confirm,
  useMutation,
  useRecordContext,
} from 'react-admin';
import { useHistory } from 'react-router';
import CopyButton from '../../component/CopyButton';
import { handleReviewVisiblity } from '../../provider/options';
import { UPDATE_MANY } from 'ra-core';

const useStyles = makeStyles({
  icon_action_button_show: {
    color: 'green',
  },
  icon_action_button_hide: {
    color: 'red',
  },
});

const FAQListAction = (props) => {
  const { resource, record } = props;
  const refresh = useRefresh();
  const classes = useStyles();
  const [open, setOpen] = useState(props.open);
  const [loading, setloading] = useState(false);
  const resourceUrl = `reviews?commentId:${record.id}&flag:${
    record?.isVisible ? 0 : 1
  }`;
  const confirmContent = `${
    record?.isVisible
      ? 'Are you sure you want to flag this comment from the drug reviews screen in the app?'
      : 'Are you sure you want to unflag this comment from the drug reviews screen in the app?'
  }`;
  // const [update, { loading }] = useMutation(
  //   {
  //     type: 'update',
  //     resource: resourceUrl,
  //     payload: {},
  //   },
  //   {
  //     onSuccess: ({ data }) => {
  //       refresh();
  //     },
  //   }
  // );

  const updateFlag = (event: any) => {
    event.stopPropagation();
    setloading(true);
    handleReviewVisiblity(props?.record?.id, props?.record?.isVisible)
      .then((res: any) => {
        // console.log(res, ': res in handle review visiblity response');
        refresh();
      })
      .finally(() => setloading(false));
  };

  const handleClick = (e) => {
    e.stopPropagation(), setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = (e) => {
    // update();
    updateFlag(e);
    setOpen(false);
  };
  return (
    <Fragment>
      <Button
        label={record?.isVisible ? 'Active' : 'Flagged'}
        color="primary"
        record={record}
        onClick={handleClick}
        className={
          record?.isVisible
            ? classes.icon_action_button_show
            : classes.icon_action_button_hide
        }
      />
      <Confirm
        isOpen={open}
        loading={loading}
        title={'Are you sure?'}
        content={confirmContent}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export default FAQListAction;
