// react
import { useEffect, useState } from 'react';
//react-admin
import { List } from 'react-admin';
// api
import {
  appAnalytics,
  userAnalytics,
  waveChartAnalytics,
} from '../../provider/options';
// mui
import { Box, Card, Grid, Typography } from '@mui/material';
// components
import Loading from '../../component/Loading';
import NoDataFound from '../../component/NoDataFound';
import DateFilter from './DateFilter';
import CustomPagination from './components/NoPagination';
import WaveChart from './components/WaveChart';
import WaveChartNew from './components/WaveChartNew';
// dayjs
import dayjs from 'dayjs';
// utils
import { ListActions } from '../../utils/common';
import PieChart from './components/PieChart';
import Sort from './components/Sort';
// ------------------------------------------------------------------------------------------------------

/**
 * Analytics component for displaying various analytics related to user engagement, registered users, app features, and active screens.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the Analytics component
 */
const Analytics = (props) => {
  const today = dayjs();
  const startOfPreviousWeek = today.subtract(7, 'day');

  const [startDate, setStartDate] = useState<any>(startOfPreviousWeek);
  const [endDate, setEndDate] = useState<any>(today);
  const [sortOrder, setSortOrder] = useState<string>('DESC');
  const [usersEngagement, setUsersEngagement] = useState<any>(null);
  // const [registeredUsers, setRegisteredUsers] = useState<any>(null);
  const [features, setFeatures] = useState<any>(null);
  const [userGrowth, setUserGrowth] = useState<any>(null);

  const [pageHit, setPageHit] = useState<any>(null);
  const [isLoadingFeatures, setIsLoadingFeatures] = useState<boolean>(false);
  const [isLoadingUserAnalytics, setIsLoadingUserAnalytics] =
    useState<boolean>(false);
  const [isLoadingPageHit, setIsLoadingPageHit] = useState<boolean>(false);

  // api to fetch User engagement and registered users analytics
  useEffect(() => {
    const fetchData = async () => {
      if (startDate && endDate) {
        setIsLoadingUserAnalytics(true);
        try {
          const [
            engagementResponse, //registeredUsersResponse
          ] = await Promise.all([
            userAnalytics('DAILY', startDate, endDate),
            // userAnalytics('ACTIVE', startDate, endDate),
          ]);
          setUsersEngagement(engagementResponse?.data);
          // setRegisteredUsers(registeredUsersResponse?.data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingUserAnalytics(false);
        }
      }
    };
    fetchData();
  }, [endDate, startDate]);

  // api to app features analytics
  useEffect(() => {
    const fetchData = async () => {
      if (startDate && endDate) {
        setIsLoadingFeatures(true);
        try {
          const featureResponse = await appAnalytics(
            'feature',
            startDate,
            endDate,
            sortOrder
          );
          setFeatures(featureResponse?.data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingFeatures(false);
        }
      }
    };
    fetchData();
  }, [endDate, startDate, sortOrder]);

  // api to most active screens(page-hit) analytics
  useEffect(() => {
    const fetchData = async () => {
      if (startDate && endDate) {
        setIsLoadingPageHit(true);
        try {
          const pageHitResponse = await appAnalytics(
            'page-hit',
            startDate,
            endDate,
            sortOrder
          );
          setPageHit(pageHitResponse?.data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingPageHit(false);
        }
      }
    };
    fetchData();
  }, [endDate, startDate, sortOrder]);

  // api to wavechart data for subsciber,unsubscribed and cancel features analytics
  useEffect(() => {
    const fetchData = async () => {
      if (startDate && endDate) {
        setIsLoadingFeatures(true);
        try {
          const waveChartingResponse = await waveChartAnalytics(
            'DAILY',
            startDate,
            endDate
          );
          setUserGrowth(waveChartingResponse?.data);
        } catch (error) {
          console.error(error);
        } finally {
          setIsLoadingFeatures(false);
        }
      }
    };
    fetchData();
  }, [endDate, startDate, sortOrder]);

  return (
    <List
      title="Analytics"
      pagination={<CustomPagination />}
      actions={<ListActions />}
      style={{
        height: 'auto',
      }}
      {...props}
    >
      <>
        <Box
          display="flex"
          gap={1}
          justifyContent="flex-end"
          alignItems="center"
          mt={5}
          mb={2}
          mr={2}
        >
          <DateFilter
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
          />
          <Sort sortOrder={sortOrder} setSortOrder={setSortOrder} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap={1.5}
          mb={2}
        >
          <ChartContainer text="User Growth">
            {isLoadingFeatures ? (
              <LoadingBar />
            ) : userGrowth?.length > 0 ? (
              <WaveChart data={userGrowth} name={'userGrowth'} isAppAnalytics />
            ) : (
              <NoData />
            )}
          </ChartContainer>

          <ChartContainer text="User Engagement">
            {isLoadingUserAnalytics ? (
              <LoadingBar />
            ) : usersEngagement?.length > 0 ? (
              <WaveChartNew data={usersEngagement} name={'User Engagement'} />
            ) : (
              <NoData />
            )}
          </ChartContainer>

          <Grid
            container
            spacing={2}
            height="auto"
            mb={4}
            padding="0 16px 0 12px"
          >
            <Grid item xs={12} lg={6}>
              <ChartContainer text="Most Used Functionality" isPie>
                {isLoadingFeatures ? (
                  <LoadingBar />
                ) : features?.length > 0 ? (
                  <PieChart data={features} id={props?.id} />
                ) : (
                  <NoData isPie />
                )}
              </ChartContainer>
            </Grid>

            <Grid item xs={12} lg={6}>
              <ChartContainer text="Most Active Screens" isPie>
                {isLoadingPageHit ? (
                  <LoadingBar />
                ) : pageHit?.length > 0 ? (
                  <PieChart data={pageHit} id={props?.id} />
                ) : (
                  <NoData isPie />
                )}
              </ChartContainer>
            </Grid>
          </Grid>
        </Box>
      </>
    </List>
  );
};
export default Analytics;
const ChartContainer = (props) => {
  const { text, children, isPie } = props;
  return (
    <Card
      sx={{
        padding: 2,
        width: '95%',
        height: isPie ? '100%' : 345,
        marginBottom: isPie ? 3 : 0,
      }}
    >
      <Typography
        fontSize="1.2rem"
        fontWeight="600"
        fontFamily="Roboto, Helvetica, Arial, sans-serif"
        margin={2}
      >
        {text}
      </Typography>
      {children}
    </Card>
  );
};
const NoData = (props) => {
  return (
    <NoDataFound sx={{ mt: 10, mb: props.isPie ? 10 : 0 }}>
      Insufficient data for analytics display.
    </NoDataFound>
  );
};
const LoadingBar = () => <Loading width={100} height={100} sx={{ mt: 10 }} />;
