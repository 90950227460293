import { FC } from 'react';
import { Link } from 'react-admin';

// import FullNameField from '../../component/FullNameField';
import { FieldProps } from '../../interface/types';
import FullNameField from './FullNameField';

const renderUrl = (record: any) => {
  if (record?.user?.userType === 'profile') {
    return `/users/all/${record?.user?.id}/show?userId=${record?.user?.accountId}`;
  } else
    return `/users/all/${record?.user?.id ? record?.user?.id : record.id}/show`;
};
const CustomerLinkField: FC<FieldProps<any>> = (props) =>
  props.record ? (
    <Link to={renderUrl(props.record)}>
      <FullNameField {...props} />
    </Link>
  ) : null;

CustomerLinkField.defaultProps = {
  source: 'firstName',
  addLabel: true,
  label: 'resources.users.fields.firstName',
};

export default CustomerLinkField;
