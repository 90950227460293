/* eslint-disable react/no-unescaped-entities */
import { makeStyles } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import './csvImport.css';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '600px',
  },
  paper: {
    border: 'none',
  },
  importDialogContent: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const ZipFileUpload = (props: any) => {
  const { onDrop } = props;

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.zip,.rar,.7zip',
  });

  return (
    <>
      <div className="dropeZone" {...getRootProps()}>
        <input {...getInputProps()} />
        <p>
          Drag 'n' Drop the file here or
          <u style={{ color: 'blue', cursor: 'pointer' }}>click</u> to upload
        </p>
      </div>
    </>
  );
};

export default ZipFileUpload;
