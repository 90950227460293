import { FC, Fragment, ReactElement, useCallback } from 'react';
import classnames from 'classnames';
import { BulkDeleteButton, ListProps } from 'react-admin';
import { List, ListActions } from '@rg-admin/ra-enterprise';
import { Route, useHistory } from 'react-router-dom';
import { Drawer, useMediaQuery, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BulkAcceptButton from './BulkAcceptButton';
import BulkRejectButton from './BulkRejectButton';
import ReviewListMobile from './ReviewListMobile';
import ReviewListDesktop from './ReviewListDesktop';
import ReviewFilter from './ReviewFilter';
import ReviewEdit from './ReviewEdit';
import CustomBreadcrumb from '../../../component/layout/Breadcrumb';

const ReviewsBulkActionButtons: FC = ({ children, ...props }) => (
  <Fragment>
    <BulkAcceptButton {...props} />
    <BulkRejectButton {...props} />
    <BulkDeleteButton {...props} />
  </Fragment>
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 400,
  },
  drawerPaper: {
    zIndex: 100,
  },
}));

const ReviewList: FC<ListProps> = (props) => {
  const classes = useStyles();
  const isXSmall = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('xs')
  );
  const history = useHistory();

  const handleClose = useCallback(() => {
    history.push('/reviews');
  }, [history]);

  return (
    <div className={classes.root}>
      <Route path="/reviews/:id">
        {({ match }): ReactElement => {
          const isMatch = !!(
            match &&
            match.params &&
            match.params.id !== 'create'
          );

          return (
            <Fragment>
              <List
                {...props}
                className={classnames(classes.list, {
                  [classes.listWithDrawer]: isMatch,
                })}
                bulkActionButtons={<ReviewsBulkActionButtons />}
                filters={<ReviewFilter />}
                actions={
                  <ListActions
                    breadcrumb={<CustomBreadcrumb variant="actions" />}
                  />
                }
                perPage={25}
                sort={{ field: 'date', order: 'DESC' }}
              >
                {isXSmall ? (
                  <ReviewListMobile />
                ) : (
                  <ReviewListDesktop
                    selectedRow={
                      isMatch && !!match && parseInt(match.params.id, 10)
                    }
                  />
                )}
              </List>
              <Drawer
                variant="persistent"
                open={isMatch}
                anchor="right"
                onClose={handleClose}
                classes={{
                  paper: classes.drawerPaper,
                }}
              >
                {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
                {isMatch && !!match ? (
                  <ReviewEdit
                    id={match.params.id}
                    onCancel={handleClose}
                    {...props}
                  />
                ) : null}
              </Drawer>
            </Fragment>
          );
        }}
      </Route>
    </div>
  );
};

export default ReviewList;
