// react
import React, { CSSProperties, ReactElement } from 'react';

//material ui
import { Grid, makeStyles, Theme, useMediaQuery } from '@material-ui/core';

//react-admin
import { GET_LIST, useRefresh } from 'ra-core';
import { useVersion } from 'react-admin';
import { DataProvider } from '../../provider/dataProvider';

//components
import AgeChart from './AgeChart';
import GenderChart from './GenderChart';
import IOSvsAndroidChart from './IOSvsAndroidChart';
import MostPopularChart from './MostPopularChart';
import RxOTChart from './RxOTChart';
import Welcome from './Welcome';
// import { DateRangePicker } from "materialui-daterange-picker";
import moment from 'moment';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
//utils
import './dashboard.css';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

//------//

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 1, marginRight: '0.5em' },
  rightCol: { marginLeft: '0.5em', maxWidth: '30em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
  // pichartSection: { height: '350px' },
};

const useStyles = makeStyles((theme) => ({
  welcomeSection: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
    },
  },
  paddingBottom: {
    paddingBottom: 24,
  },
  datePicker: {
    display: 'none',
  },

  datepicker_box: {
    width: '13%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  datepicker_grid: {
    paddingRight: '2%',
    paddingTop: '2%',
    paddingBottom: '2%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '14%',
    },
  },
  custom: {
    paddingRight: '3%',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '16%',
    },
  },
}));

const Dashboard = (): ReactElement => {
  const [dashboardData, setDashboardData] = React.useState<any>({});
  const classes = useStyles();
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  const refresh = useRefresh();
  const version = useVersion();
  const today = dayjs();

  const [startDate, setStartdate] = React.useState<Dayjs | null | any>(null);
  const [endDate, setEnddate] = React.useState<Dayjs | null | any>(null);
  const [show, setShow]: any = React.useState(false);
  const [name, setName] = React.useState('');

  React.useEffect(() => {
    setDashboardData({});
    DataProvider(GET_LIST, 'dashboard', {
      pagination: {
        page: 1,
        perPage: 100,
      },
      sort: null,
      startDate: startDate?.$d
        ? moment(startDate?.$d).format('YYYY-MM-DD 00:00:00')
        : '2015-01-01 00:00:00',
      // 2016-12-16 00:00:00
      endDate: endDate?.$d
        ? moment(endDate?.$d).format('YYYY-MM-DD 23:59:59')
        : moment(new Date()).format('YYYY-MM-DD 23:59:59'),
    }).then((res) => {
      setDashboardData(res.data);
    });
  }, [version, endDate, startDate]); //removed version from useeffect array

  const handleDates = (data: any) => {
    if (data == 'month') {
      setShow(false);
      const startOfPreviousMonth = today.subtract(1, 'month').startOf('month');
      setStartdate(startOfPreviousMonth);
      setEnddate(startOfPreviousMonth.endOf('month'));
    } else if (data == 'week') {
      setShow(false);
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      setStartdate(prevWeek.startOf('week'));
      setEnddate(prevWeek.endOf('week'));
    } else if (data == 'last_7_days') {
      setShow(false);
      setStartdate(today.subtract(6, 'day'));
      setEnddate(today);
    } else if (data == 'custom') {
      setStartdate('');
      setEnddate('');
      setShow(true);
    } else if (data == 'reset') {
      setStartdate('');
      setEnddate('');
      setShow(false);
    }
  };

  // console.log(startDate, 'startDate', endDate, 'endDate');

  const handleChange = (event: any) => {
    setName(event.target.value);
  };

  return (
    <div>
      <div
        className={classes.welcomeSection}
        style={styles.flexColumn as CSSProperties}
      >
        <Grid container spacing={3} className={classes.datepicker_grid}>
          <Grid
            container
            item
            xs={6}
            sm={12}
            md={12}
            xl={12}
            lg={12}
            spacing={3}
            justifyContent="flex-end"
          >
            <div
              className={` ${show ? classes.custom : classes.datePicker}`}
              style={{ textAlign: 'right', paddingRight: '3%' }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start date"
                  onChange={(e: any) => setStartdate(e)}
                  value={startDate}
                />{' '}
                &nbsp;
                <DatePicker
                  label="End date"
                  onChange={(e: any) => setEnddate(e)}
                  maxDate={today}
                  value={endDate}
                />
              </LocalizationProvider>
            </div>

            <Box className={classes.datepicker_box}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Filter By Date{' '}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={name}
                  label="Filter By Date"
                  onChange={handleChange}
                  style={{ width: '120%' }}
                >
                  <MenuItem
                    onClick={() => handleDates('last_7_days')}
                    value="Last 7 days"
                  >
                    Last 7 days
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDates('month')}
                    value="Last Month"
                  >
                    Last Month
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDates('week')}
                    value="Last Week"
                  >
                    Last Week
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleDates('custom')}
                    value="Custom"
                  >
                    Custom
                  </MenuItem>
                  <MenuItem onClick={() => handleDates('reset')}>
                    Reset
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Welcome dashboardData={dashboardData} />
      </div>
      <div>
        <Grid container spacing={3}>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} lg={4}>
              <GenderChart
                genderData={dashboardData?.accountAnalytics}
                totalAccount={dashboardData?.totalAccounts}
                // id="gender"
              />
            </Grid>
            <Grid item xs={12} lg={8}>
              <AgeChart
                ageData={dashboardData?.accountAnalytics?.basedOnAge}
                totalAccount={dashboardData?.totalAccounts}
                id="age"
              />
            </Grid>
          </Grid>
          {/* //back up starts here */}
          {/* <Grid container item xs={12} spacing={3}>
            <Grid container item xs={12} lg={4}>
              <Grid item xs={12} className={classes.paddingBottom}>
                <RxOTChart drugTypeData={dashboardData?.drugAnalytics?.type} />
              </Grid>
              <Grid item xs={12}>
                <IOSvsAndroidChart
                  deviceTypeData={dashboardData?.deviceAnalytics}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} lg={8}>
              <MostPopularChart
                mostPopularDrugs={dashboardData?.drugAnalytics?.top_ten_rx}
                title={'Most Popular RX Medications'}
              />
            </Grid>
          </Grid> */}
          {/* //backup ends here */}
          {/* //remove */}

          {dashboardData?.drugAnalytics?.top_ten_rx?.length > 0 && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} lg={12}>
                <MostPopularChart
                  mostPopularDrugs={dashboardData?.drugAnalytics?.top_ten_rx}
                  title={'Most Popular RX Medications'}
                  id="rx"
                  next="rx"
                />
              </Grid>
            </Grid>
          )}

          {dashboardData?.drugAnalytics?.top_ten_otc?.length > 0 && (
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} lg={12}>
                <MostPopularChart
                  mostPopularDrugs={dashboardData?.drugAnalytics?.top_ten_otc}
                  title={'Most Popular OTC Medications'}
                  id="otc"
                />
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={12} lg={4} className={classes.paddingBottom}>
              <RxOTChart drugTypeData={dashboardData?.drugAnalytics?.type} />
            </Grid>
            <Grid item xs={12} lg={4}>
              <IOSvsAndroidChart
                deviceTypeData={dashboardData?.deviceAnalytics}
                id="iosvsandroid"
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
