// react-admin
import { DateTimeInput, SelectInput, TextInput } from 'react-admin';

// @mui
import { Box, Grid, Typography } from '@mui/material';
// -----------------------------------------------------------------------------------------------
/**
 * BlastNotificationForm component for displaying a form to create/edit blast notifications.
 * @returns {JSX.Element} - JSX element representing the BlastNotificationForm component
 */
const BlastNotificationForm = () => (
  <Box
    display="flex"
    flexDirection={{ xs: 'column', lg: 'row' }}
    justifyContent={{ xs: 'unset', lg: 'space-between' }}
  >
    <Grid container width={{ xs: '100%', lg: '50%' }} spacing={2}>
      <Grid item xs={12}>
        <Label text="Notification Content" />
        <TextInput source="notificationTitle" fullWidth label="Title*" />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          source="notificationContent"
          label="Content*"
          minRows={4}
          multiline
          fullWidth
        />
      </Grid>
    </Grid>
    <Grid
      container
      width={{ xs: '100%', lg: '50%' }}
      rowGap={{ xs: 0, lg: 4.5 }}
      spacing={2}
    >
      <Grid item xs={12}>
        <Label text="Type" />
        <SelectInput
          source="userType"
          choices={[
            { id: 'F', name: 'Free' },
            { id: 'P', name: 'Paid' },
            { id: 'B', name: 'Both' },
          ]}
          fullWidth
          label="User Type*"
        />
      </Grid>

      <Grid item xs={12}>
        <Label text="Schedule Notification (Optional)" />
        <DateTimeInput source="schedule" label="Date & Time" fullWidth />
      </Grid>
    </Grid>
  </Box>
);

export default BlastNotificationForm;

const Label = ({ text, color, fontSize, marginTop = 0 }: any) => (
  <Typography
    marginTop={marginTop}
    fontWeight={500}
    color={color}
    fontSize={fontSize || '14px'}
  >
    {text}
  </Typography>
);
