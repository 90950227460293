import { Grid, makeStyles, Theme } from '@material-ui/core';
import {
  ImageField,
  ListProps,
  useShowController,
  Show,
  SimpleShowLayout,
  TextField,
  Labeled,
  SelectField,
  TopToolbar,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
  useDataProvider,
} from 'react-admin';
import './UserView.css';
import queryString from 'query-string';
import React, { FC, Fragment, ReactElement, useEffect, useState } from 'react';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import TabbedDatagrid from './UserDetailsTab';

import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: 0,
    width: '100%',
  },
  disableTopMargin: {
    marginTop: '0px',
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0px 6px',
  },
  image: {
    borderRadius: '50%',
    width: '90%',
    maxWidth: '15em',
    maxHeight: '15em',
    [theme.breakpoints.only('xs')]: {
      width: '90%',
    },
    [theme.breakpoints.up('xl')]: {
      width: '20em',
    },
  },
}));

const UserDetailsView = (props: any): ReactElement => {
  const { location } = props;
  const { record } = useShowController(props);
  const parsed = queryString.parse(location.search);
  const WithProps = ({ children, ...props }) => children(props);
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const classes = useStyles();

  // state
  const [locationKeys, setLocationKeys] = useState([]);
  const [userSubscriptionDetail, setUserSubscriptionDetail] =
    useState<any>(null);

  const history = useHistory();

  // data provider get
  const dataProvider: any = useDataProvider();

  useEffect(() => {
    const fetchData = async () => {
      // get current action subscription of the user
      await dataProvider
        .getData(`/subscription-plan/activeSubscription?userId=${props?.id}`)
        .then((response: any) => {
          setUserSubscriptionDetail(response?.data?.data[0]);
        })
        .catch((error: any) =>
          notify(`${error?.response?.data?.message}`, { type: 'error' })
        );
    };
    fetchData();
  }, []);

  const onSuccess = () => {
    notify(`Saved successfully`, 'success', {}, false);
    redirect('/users/all');
    refresh();
  };
  const onImageError = (ev) => {
    // console.log('image error triggered');
    ev.target.src = './profile-placeholder.jpeg';
  };
  useEffect(() => {
    return history.listen((location: any) => {
      if (history.action === 'PUSH') {
        //@ts-ignore
        setLocationKeys([location.key]);
      }

      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);

          // Handle forward event
          // console.log('browser forward triggered');
        } else {
          //@ts-ignore
          setLocationKeys((keys) => [location.key, ...keys]);
          // Handle back event
          if (parsed?.userId)
            props.history.push(`/users/all/${parsed?.userId}/show`);
          else props.history.push(`/users/all`);
        }
      }
    });
  }, [locationKeys]);
  const UserShowActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <CustomBreadcrumb variant="actions" />
    </TopToolbar>
  );
  return (
    <Fragment>
      <Show
        {...props}
        title="User Details"
        actions={<UserShowActions {...props} />}
      >
        <SimpleShowLayout>
          <Grid container spacing={2}>
            <Grid container item xs={12} lg={3} md={3} justifyContent="center">
              <img
                src={
                  record?.pictureUrl
                    ? record?.pictureUrl
                    : './profile-placeholder.jpeg'
                }
                alt="User"
                className={classes.image}
                onError={onImageError}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              lg={8}
              md={8}
              spacing={1}
              className="main_content"
            >
              <Grid item xs={4} className="sub_content">
                <Labeled label="Name">
                  <TextField source="name" />
                </Labeled>
              </Grid>
              <Grid item xs={6} className="sub_content">
                <Labeled label="Email">
                  <TextField source="email" />
                </Labeled>
              </Grid>
              <Grid item xs={4} className="sub_content">
                <Labeled label="Date Of Birth">
                  <TextField source="dob" label="DOB" sortable={false} />
                </Labeled>
              </Grid>
              <Grid item xs={4} className="sub_content">
                <Labeled label="Gender">
                  <SelectField
                    source="gender"
                    choices={[
                      { id: 'M', name: 'Male' },
                      { id: 'F', name: 'Female' },
                      { id: 'O', name: 'Prefer not to say' },
                    ]}
                  />
                </Labeled>
              </Grid>
              {/* used to show the current active plan of the user */}
              <Grid item xs={4} className="sub_content">
                <Labeled label="Active Subscription">
                  <span style={{ fontSize: '0.875rem' }}>
                    {userSubscriptionDetail?.plan || '-'}
                  </span>
                </Labeled>
              </Grid>
              <Grid item xs={4} className="sub_content">
                <Labeled label="Activation Status">
                  <SelectField
                    source="isActive"
                    choices={[
                      { id: true, name: 'Active' },
                      { id: false, name: 'Inactive' },
                    ]}
                  />
                </Labeled>
              </Grid>
            </Grid>
          </Grid>
        </SimpleShowLayout>
      </Show>
      <DetailsList title=" " record={record} {...props} />
    </Fragment>
  );
};

const DetailsList: FC<ListProps> = (props: any) => (
  <TabbedDatagrid
    title=" "
    records={props?.record}
    userId={props?.record?.accountId}
    sort={{ field: 'date', order: 'DESC' }}
    {...props}
    filterValues={{
      status: props?.record?.userType == 'user' ? 'profile' : 'medication',
      id: props.id,
    }}
  />
);

export default UserDetailsView;
