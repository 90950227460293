import { TopToolbar } from 'react-admin';
import CustomBreadcrumb from '../component/layout/Breadcrumb';

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ListActions = (props) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
    </TopToolbar>
  );
};
