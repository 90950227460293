import * as React from 'react';
import Rating from '@mui/material/Rating';

import { FieldProps, useRecordContext } from 'react-admin';

interface OwnProps {
  size?: 'large' | 'small';
}

const StarRatingField = ({ size = 'large' }: FieldProps & OwnProps) => {
  const record = useRecordContext();
  if (!record) return null;

  return <Rating name="read-only" value={record?.rating} readOnly />;
};

StarRatingField.defaultProps = {
  label: 'resources.reviews.fields.rating',
  source: 'rating',
};

export default StarRatingField;
