export const BUGSNAG_API_KEY = process.env.REACT_APP_BUGSNAG_API_KEY;
export const RELEASE_STAGE = process.env.REACT_APP_RELEASE_STAGE;

export const TRIGGERS = [
  {
    id: 'The user adds a drug to their profile and a severe interaction is detected',
    name: 'The user adds a drug to their profile and a severe interaction is detected',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'B', // All
  },
  {
    id: 'The user adds a drug X to their profile which is in our trigger list',
    name: 'The user adds a drug X to their profile which is in our trigger list',
    isMedicine: true,
    isMultipleContents: false,
    userType: 'P', // Paid
  },
  {
    id: 'The user adds a reminder for a drug',
    name: 'The user adds a reminder for a drug',
    isMedicine: true,
    isMultipleContents: false,
    userType: 'P', // Paid
  },
  {
    id: 'The users marks a drug as taken',
    name: 'The users marks a drug as taken',
    isMedicine: true,
    isMultipleContents: false,
    userType: 'P', // Paid
  },
  {
    id: 'A reminder for a drug is added for more than 3 times a day',
    name: 'A reminder for a drug is added for more than 3 times a day',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'P', // Paid
  },
  {
    id: 'A user does not mark a drug as taken for more than 3 days in a row',
    name: 'A user does not mark a drug as taken for more than 3 days in a row',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'P', // Paid
  },
  {
    id: 'A user marks a drug as taken more than an hour late for 3 days in a row',
    name: 'A user marks a drug as taken more than an hour late for 3 days in a row',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'P', // Paid
  },
  {
    id: 'When a like ingredient is detected',
    name: 'When a like ingredient is detected',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'P', // Paid
  },
  {
    id: 'The user scans a drug and gets no result',
    name: 'The user scans a drug and gets no result',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'B', // All
  },
  {
    id: 'Did you know notification after 5 days of inactivity',
    name: 'Did you know notification after 5 days of inactivity',
    isMedicine: false,
    isMultipleContents: true,
    userType: 'B', // All
  },
  {
    id: 'When a user has had a MyRxProfile account for a month',
    name: 'When a user has had a MyRxProfile account for a month',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'B', // All
  },
  {
    id: 'When a user has had a MyRxProfile account for a week',
    name: 'When a user has had a MyRxProfile account for a week',
    isMedicine: false,
    isMultipleContents: false,
    userType: 'B', // All
  },
];

// Symptoms
export const Symptoms = [
  { id: 1, label: 'Cough' },
  { id: 2, label: 'Sore throat' },
  { id: 3, label: 'Headache' },
  { id: 4, label: 'Fatigue' },
  { id: 5, label: 'Difficulty breathing' },
  { id: 6, label: 'Chest pain' },
  { id: 7, label: 'Nausea' },
  { id: 8, label: 'Vomiting' },
  { id: 9, label: 'Diarrhea' },
  { id: 10, label: 'Abdominal pain' },
  { id: 11, label: 'Muscle aches' },
  { id: 12, label: 'Joint pain' },
  { id: 13, label: 'Rash & Itching' },
  { id: 14, label: 'Dizziness' },
  { id: 15, label: 'Swollen lymph nodes' },
  { id: 16, label: 'Runny or stuffy nose' },
  { id: 17, label: 'Sneezing' },
  { id: 18, label: 'Watery eyes' },
  { id: 19, label: 'Chills' },
  { id: 20, label: 'Blood Pressure' },
  { id: 21, label: 'Ringing in the Ears' },
  { id: 22, label: 'Urine Discoloration' },
  { id: 23, label: 'Weight Loss or Gain' },
  { id: 24, label: 'Loss of Appetite' },
];
