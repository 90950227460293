import OrderIcon from '@material-ui/icons/AttachMoney';

import OrderList from './OrderList';
import OrderEdit from './OrderEdit';

export default {
  list: OrderList,
  edit: OrderEdit,
  icon: OrderIcon,
};
