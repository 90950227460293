import { FC } from 'react';
import { FilterList, FilterListItem } from 'react-admin';
import WcIcon from '@material-ui/icons/Wc';

const GenderFilter: FC = () => (
  <FilterList label="resources.users.filters.gender" icon={<WcIcon />}>
    <FilterListItem
      label="resources.users.filters.male"
      value={{
        gender: 'Male',
      }}
    />
    <FilterListItem
      label="resources.users.filters.female"
      value={{
        gender: 'Female',
      }}
    />
    <FilterListItem
      label="resources.users.filters.other"
      value={{
        gender: 'Other',
      }}
    />
  </FilterList>
);

export default GenderFilter;
