import React, { CSSProperties, useEffect, useState } from 'react';

//material ui
import { Box, Card, Typography } from '@material-ui/core';

//components
import ChartTable from './chartTable';
import PieChartSelection from './Chart';

//utils
import { IsDeviceTypeChartData } from '../../utils/Interface';

const styles = {
  pichartSection: { height: '100%' },
  pichartBoxSection: { padding: '16px' },
  helperText: { marginTop: '2rem' },
  tittle: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1.5rem',
    fontWeight: '600',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    paddingBottom: '2%',
  },
};

const IOSvsAndroidChart = (props: any) => {
  const { deviceTypeData } = props;

  const [series, setSeries]: any = useState([]);
  const [deviceTypeChartData, setdeviceTypeChartData] = useState<
    IsDeviceTypeChartData[] | []
  >([]);

  const title = 'IOS vs Android';
  const columns = [
    { field: 'type', headerName: 'Device' },
    { field: 'count', headerName: 'Number of users' },
  ];

  const [options, setOptions]: any = useState({
    chart: {
      width: 380,
      type: 'pie',
      id: props?.id,
    },
    responsive: [
      {
        breakpoint: undefined,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            // position: 'bottom'
          },
        },
      },
    ],
  });

  const handleUpdateDeviceTypeChartData = async (deviceTypeDatas) => {
    const tempData = await deviceTypeDatas?.map((deviceTypeData) => {
      return {
        type: deviceTypeData?.device,
        count: deviceTypeData?.count,
      };
    });
    setdeviceTypeChartData(tempData);
  };

  const setArrayofSeries = () => {
    const label: any = [];
    const result = deviceTypeChartData.map((res: any) => {
      label.push(res.type);
      return res.count;
    });
    setSeries(result);
    setOptions({ ...options, labels: label });
  };

  useEffect(() => {
    if (deviceTypeChartData?.length > 0) setArrayofSeries();
  }, [deviceTypeChartData]);

  useEffect(() => {
    handleUpdateDeviceTypeChartData(deviceTypeData);
  }, [deviceTypeData]);

  const data = deviceTypeChartData
    ?.map((item) => item.count)
    .every((count) => count === 0);

  return (
    <Card style={styles.pichartSection as CSSProperties}>
      <Box style={styles.pichartBoxSection as CSSProperties}>
        <div style={styles.tittle as CSSProperties}>{title}</div>
        {!data && (
          <PieChartSelection series={series} options={options} title={title} />
        )}

        <ChartTable rows={deviceTypeChartData} columns={columns} />
        <Typography variant="body2" style={styles.helperText as CSSProperties}>
          The count gets updated every time the app installed on a new device
        </Typography>
      </Box>
    </Card>
  );
};

export default IOSvsAndroidChart;
