// react
import { useEffect, useState } from 'react';

// react-admin
import {
  ArrayInput,
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';

// @mui
import { Button, Grid, IconButton } from '@mui/material';
import CloseIcon from '@material-ui/icons/Close';

// constant
import { TRIGGERS } from '../../../utils/constants';
import { useForm } from 'react-final-form';
// -----------------------------------------------------------------------------------------------
/**
 * PersonalizedNotificationForm component for rendering personalized notification form.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the PersonalizedNotificationForm component
 */
const PersonalizedNotificationForm = (props) => {
  const { isEdit } = props;
  const record = useRecordContext();
  const form = useForm();
  const [isMedicineDisabled, setIsMedicineDisabled] = useState<boolean>(true);
  const [index, setIndex] = useState<number>(0);
  const [isAddMoreContent, setIsAddMoreContent] = useState<boolean>(false);

  // Handler for adding a content item
  const handleAddClick = () => {
    setIndex(index + 1);
  };
  // Handler for removing a content item
  const handleRemoveClick = () => {
    setIndex((prevIndex) => prevIndex - 1);
  };
  // to update form values based on record and edit status
  useEffect(() => {
    if (record && isEdit) {
      // Update form values with existing record data
      form.change('title', record.notificationData[0].title);
      form.change('content', record.notificationData[0].content);

      // Find the selected trigger based on the triggerValue in the record
      const selectedTrigger = TRIGGERS.find(
        (trigger) => trigger?.id === record?.triggerValue
      );

      // Update state values based on the selected trigger
      if (selectedTrigger) {
        setIsMedicineDisabled(!selectedTrigger?.isMedicine);
        setIsAddMoreContent(selectedTrigger?.isMultipleContents);
        setIndex(record.notificationData.length);
      }
    }
  }, [record]);

  // Handler for trigger value change
  const handleTriggerChange = (event) => {
    const selectedTrigger = TRIGGERS.find(
      (trigger) => trigger?.id === event?.target?.value
    );

    // Update state values based on the selected trigger
    if (selectedTrigger) {
      setIsMedicineDisabled(!selectedTrigger?.isMedicine);
      setIsAddMoreContent(selectedTrigger?.isMultipleContents);
    }
  };

  if (!record) return null;
  return (
    <Grid container width={{ xs: '100%', lg: '50%' }} spacing={2}>
      <Grid item xs={12}>
        <SelectInput
          source="triggerValue"
          choices={TRIGGERS}
          fullWidth
          onChange={handleTriggerChange}
          validate={required()}
          label="Trigger Value"
        />
      </Grid>

      {!isMedicineDisabled && (
        <Grid item xs={12}>
          <ReferenceInput
            label="Medicine name*"
            source="drugs"
            reference="drugList"
          >
            <AutocompleteInput
              optionText="drugListName"
              optionValue="drugs"
              resettable
              suggestionLimit={8}
              fullWidth
              validate={required()}
            />
          </ReferenceInput>
        </Grid>
      )}
      <Grid item xs={12}>
        {isAddMoreContent ? (
          <ArrayInput source="notificationData" label="Notification Content">
            <SimpleFormIterator
              getItemLabel={() => ''}
              disableReordering
              disableAdd={!isAddMoreContent && index >= 1}
              disableRemove={!isAddMoreContent}
              removeButton={
                <RemoveButton index={index} onClick={handleRemoveClick} />
              }
              addButton={<AddButton index={index} onClick={handleAddClick} />}
            >
              <TextInput
                source="title"
                fullWidth
                label="Notification Title"
                validate={required()}
              />
              <TextInput
                source="content"
                label="Notification Content"
                minRows={3}
                multiline
                fullWidth
                validate={required()}
              />
            </SimpleFormIterator>
          </ArrayInput>
        ) : (
          <>
            <Grid item xs={12}>
              <TextInput
                source="title"
                fullWidth
                label="Notification Title"
                validate={required()}
              />
            </Grid>
            <Grid item xs={12}>
              <TextInput
                source="content"
                label="Notification Content"
                minRows={3}
                multiline
                fullWidth
                validate={required()}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default PersonalizedNotificationForm;

const RemoveButton = ({ onClick, index }: any) => (
  <IconButton onClick={onClick} disabled={index <= 1}>
    <CloseIcon />
  </IconButton>
);

const AddButton = ({ onClick, index }: any) => (
  <div>
    <Button variant="text" onClick={onClick} disabled={index === 10}>
      {index === 0 ? 'Click here to add contents' : '+ Add More'}
    </Button>
  </div>
);
