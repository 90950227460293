import { FC, cloneElement } from 'react';
import {
  TextField,
  ListProps,
  Datagrid,
  TopToolbar,
  Filter,
  SearchInput,
  SelectInput,
  useListContext,
} from 'react-admin';
import { List } from '@rg-admin/ra-enterprise';
import { Button, makeStyles } from '@material-ui/core';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import onClickBasic, { onClickBasic1 } from './encryption';
import { Form } from 'react-final-form';
import EmptyList from '../../component/EmptyList';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';

const useStyles = makeStyles((theme) => ({
  importDialogContent: {
    height: 200,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBar: {
    width: '400px',
    paddingTop: '20px',
    '& .MuiFilledInput-root': {
      height: '48px',
    },
  },
}));

const handleOpenImport = () => {
  window.open('/medicationsBulkFileUpload', '_blank');
};
const handleOpenImportZip = () => {
  window.open('/MedicineZipFileUpload', '_blank');
};

const medTypeChoices = [
  { id: 'RX', name: 'RX' },
  { id: 'OTC', name: 'OTC' },
];
const MedicationFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props}>
      <SearchInput
        source="searchKey"
        placeholder="Search By Medicine Name"
        resettable
        alwaysOn
        className={classes.searchBar}
      />

      <SelectInput
        label={'Filter Drugs By RX/OTC'}
        source={'med_type'}
        choices={medTypeChoices}
        resettable
        alwaysOn
        style={{ width: '248px' }}
        allowEmpty={false}
      />
    </Filter>
  );
};

const ListActions = (props: any) => {
  const { className, basePath, filters } = props;
  const { resource, displayedFilters, filterValues, showFilter } =
    useListContext();
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
      {/* <Button color="primary" onClick={handleOpenImportZip}>
        Multum Import
      </Button> */}
      {/* <Button color="primary" onClick={handleOpenImport}>
        Hamacher Import
      </Button> */}
    </TopToolbar>
  );
};
const MedicineList: FC<ListProps> = (props: any) => {
  const classes = useStyles();

  return (
    <List
      {...props}
      resource="medication"
      actions={
        <ListActions
          handleOpen={handleOpenImport}
          className={classes.toolbar}
        />
      }
      filters={<MedicationFilter />}
      perPage={25}
      bulkActionButtons={false}
      exporter={false}
      title="Medicine"
      hasCreate={false}
      hasList
      pagination={<PaginationWithCustomEmpty />}
      sort={{ field: 'users', order: 'DESC' }} //internet_desc
    >
      <Datagrid empty={<EmptyList resource={'Medicines'} />}>
        <TextField
          sortable={false}
          label="Medicine Name"
          source="medicineName"
        />
        <TextField sortable={false} label="OTC/RX" source="type" />

        <TextField label="Users" source="users" />
      </Datagrid>
    </List>
  );
};

export default MedicineList;
