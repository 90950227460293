import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { FC, cloneElement } from 'react';

import { useListContext, useTranslate } from 'react-admin';

import { CircularProgress } from '@material-ui/core';
import AvatarField from '../../../component/AvatarField';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1em',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    // justifyContent: 'space-between',
    gap: '16px',
  },
  card: {
    width: 265,
    display: 'flex',
    flexDirection: 'column',
    margin: '0.5rem 0',
  },
  cardTitleContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  cardContent: {
    ...theme.typography.body1,
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    textTransform: 'capitalize',
    minHeight: '36px',
  },
  loader: {
    textAlign: 'center',
    padding: '16px 0px',
  },
}));

interface Props {
  fields: any;
}

const DesktopGrid: FC<Props> = ({ fields, ...rest }) => {
  const translate = useTranslate();
  const classes = useStyles();
  const { ids, data } = useListContext(rest);

  if (!ids || !data) {
    return null;
  }

  return (
    <div className={classes.root}>
      {ids.map((id) => (
        <Card key={id} className={classes.card}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardTitleContent}>
              <AvatarField record={data[id]} size="120" />
              <h2 className={classes.name}>{`${
                data[id]?.firstName ? data[id]?.firstName : ''
              }`}</h2>

              {/* <IconButton
                color="secondary"
                aria-label="Edit"
                component={RouterLink}
                className={classes.editButton}
                to={linkToRecord(basePath, id, 'show')}
              >
                <VisibilityIcon />
              </IconButton> */}
            </div>
            {!data[id]?.email && (
              <div className={classes.loader}>
                <CircularProgress />
              </div>
            )}
            {fields
              .filter((field) => field.props.source !== 'id') // we already display customer up there
              .filter(
                (field) =>
                  data[id]?.email && data[id][field.props.source] !== null
              )
              .map((field) => {
                const fieldName = field.props.source;
                if (field.props.source == 'firstName') {
                  return null;
                }

                return (
                  data[id]?.email && (
                    <div
                      key={fieldName}
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div
                        style={{
                          marginRight: '0.5em',
                          marginTop: '0.5em',
                          minWidth: '26%',
                        }}
                      >
                        {fieldName == undefined
                          ? 'Age'
                          : '' ||
                            translate(`resources.users.fields.${fieldName}`)}
                      </div>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          marginTop: '0.5em',
                          minWidth: '70%',
                          maxWidth: '70%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {cloneElement(field, {
                          record: data[id],
                        })}
                      </div>
                    </div>
                  )
                );
              })}
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default DesktopGrid;
