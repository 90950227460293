import {
  Create,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
} from 'react-admin';
import { Button, Grid, makeStyles } from '@material-ui/core';
import './FAQForm.css';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';

const useStyles = makeStyles({
  card: {
    padding: 0,
    width: '100%',
  },
  disableTopMargin: {
    marginTop: '0px',
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0px 6px',
  },
});

export const FAQCreate = (props) => {
  const classes = useStyles();

  const clickToCancel = () => {
    props.history.push(`/FAQ`);
  };

  const CreateActions = (props: any) => {
    const { className, basePath } = props;
    return (
      <TopToolbar className={className}>
        <CustomBreadcrumb variant="actions" />
      </TopToolbar>
    );
  };

  const CustomToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton label="Save" />
      <Button
        className={classes.cancelButton}
        onClick={clickToCancel}
        variant="contained"
      >
        CANCEL
      </Button>
    </Toolbar>
  );

  return (
    <Create actions={<CreateActions />} {...props} title="FAQ Create">
      <SimpleForm toolbar={<CustomToolbar />} redirect="list">
        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={6}>
            <TextInput validate={required()} fullWidth source="question" />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              multiline
              validate={required()}
              fullWidth
              source="answer"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
