import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import ActionDelete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import inflection from 'inflection';
import { Confirm, getMutationMode, useDeleteWithConfirmController, useRecordContext, useResourceContext, useTranslate, } from 'react-admin';
export var DeleteWithConfirmIconButton = function (props) {
    var basePath = props.basePath, className = props.className, _a = props.confirmTitle, confirmTitle = _a === void 0 ? 'ra.message.delete_title' : _a, _b = props.confirmContent, confirmContent = _b === void 0 ? 'ra.message.delete_content' : _b, _c = props.label, label = _c === void 0 ? 'ra.action.delete' : _c, mutationMode = props.mutationMode, _d = props.redirect, redirectTo = _d === void 0 ? 'list' : _d, undoable = props.undoable;
    var mode = getMutationMode(mutationMode, undoable);
    var record = useRecordContext(props);
    var resource = useResourceContext(props);
    var _e = useDeleteWithConfirmController({
        mutationMode: mutationMode || mode,
        resource: resource,
        record: record,
        redirect: redirectTo,
        basePath: basePath,
    }), open = _e.open, loading = _e.loading, handleDialogOpen = _e.handleDialogOpen, handleDialogClose = _e.handleDialogClose, handleDelete = _e.handleDelete;
    var translate = useTranslate();
    var translatedLabel = translate(label, { _: label });
    return (React.createElement(Fragment, null,
        React.createElement(Tooltip, { title: translatedLabel },
            React.createElement(IconButton, { "aria-label": translatedLabel, onClick: handleDialogOpen, className: classnames('ra-delete-button', className), key: "button", size: "small" },
                React.createElement(ActionDelete, { color: "error" }))),
        React.createElement(Confirm, { isOpen: open, loading: loading, title: confirmTitle, content: confirmContent, translateOptions: {
                name: inflection.humanize(translate("resources." + resource + ".name", {
                    smart_count: 1,
                    _: inflection.singularize(resource),
                }), true),
                id: record ? record.id : undefined,
            }, onConfirm: handleDelete, onClose: handleDialogClose })));
};
DeleteWithConfirmIconButton.propTypes = {
    basePath: PropTypes.string,
    className: PropTypes.string,
    confirmTitle: PropTypes.string,
    confirmContent: PropTypes.string,
    label: PropTypes.string,
    record: PropTypes.any,
    redirect: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.func,
    ]),
    resource: PropTypes.string,
    icon: PropTypes.element,
};
