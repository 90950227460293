// react-admin
import {
  ShowButton,
  EditButton,
  useRecordContext,
  useRedirect,
} from 'react-admin';
// ------------------------------------------------------------------------------------------------------
/**
 * EditOrShowButton component for rendering either a ShowButton or an EditButton based on the record's status.
 * @returns {JSX.Element} - JSX element representing the EditOrShowButton component
 */
const EditOrShowButton = () => {
  const record = useRecordContext();
  const redirect = useRedirect();
  if (!record) return null;
  const handleShow = () => redirect(`/blast-notification/${record.id}/show`);
  const handleEdit = () => redirect(`/blast-notification/${record.id}`);

  return (
    <>
      {record?.status === 1 ? (
        <ShowButton onClick={handleShow} />
      ) : (
        <EditButton onClick={handleEdit} />
      )}
    </>
  );
};
export default EditOrShowButton;
