// react admin
import { FunctionField, Show, TextField } from 'react-admin';
// mui
import { Card, Grid, Box, Typography } from '@mui/material';
// components
import UserAvatar from './components/UserAvatar';
import DrugDetails from './components/DrugDetails';
// helper functions
import { renderArrayContent, renderContent } from './components/helperFunction';
// utils
import { ListActions } from '../../utils/common';
import { dateFormat } from '../../utils/helper';

// -------------------------------------------------------------------------------------------------------

/**
 * Component to show symptoms details.
 * @param {object} props - The component props.
 * @returns {JSX.Element} - The JSX element representing the show symptoms view.
 */
const ShowSymptoms = (props) => {
  return (
    <Show {...props} actions={<ListActions />} title={`Symptom Details`}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
          alignItems: 'center',
          gap: 10,
          padding: 2,
          width: '100%',
        }}
      >
        <Box marginBottom="auto">
          <UserAvatar />
        </Box>
        <Grid container spacing={4} width={{ xs: '100%', lg: '60%' }}>
          <Grid item xs={12} md={6}>
            <Label text="Name" />
            <TextField source="name" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Profile Name" />
            <FunctionField
              key="profileName"
              render={(record) => renderContent(record?.profileName)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Symptoms" />
            <FunctionField
              key="symptoms"
              render={(record: any) => renderArrayContent(record?.symptoms)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Label text="Date" />
            <FunctionField
              render={(record) => dateFormat(record?.schedule, 'MM-DD-YYYY')}
            />
          </Grid>
          <Grid item xs={12}>
            <Label text="Comments" />
            <FunctionField
              key="comments"
              render={(record) => renderContent(record?.notes)}
            />
          </Grid>
          <Grid item xs={12}>
            <Label text="Drugs" />
            <DrugDetails />
          </Grid>
        </Grid>
      </Card>
    </Show>
  );
};
const Label = ({ text }: { text: string }) => (
  <Typography color="rgba(0, 0, 0, 0.54)" fontSize="12px">
    {text}
  </Typography>
);
export default ShowSymptoms;
