// react
import { FC } from 'react';

// react admin
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  SearchInput,
  ShowButton,
  TextField,
  TopToolbar,
  CreateButton,
} from 'react-admin';

// mui
import { Theme, useMediaQuery } from '@material-ui/core';

// rg admin
import { FilterWithSave } from '@rg-admin/ra-preferences';

// Components
import EmptyList from '../../component/EmptyList';
import AsideFilter from './components/AsideFilter';
import IsActiveSwitch from './components/IsActiveSwitch';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';

// helper function
import {
  renderContentSliced,
  renderNotificationStatus,
  renderUserType,
} from '../symptom/components/helperFunction';
import EditOrShowButton from './components/EditOrShowButoon';

// ----------------------------------------------------------------------------------------------------

// UserFilter component for filtering the list of users
const UserFilter: FC = (props: any) => (
  <FilterWithSave {...props}>
    <SearchInput source="searchKey" alwaysOn />
  </FilterWithSave>
);

/**
 * Custom top toolbar for list actions.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the ListActions component
 */
const ListActions = (props) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
      <CreateButton />
    </TopToolbar>
  );
};

/**
 * ListBlastNotifications component for displaying a list of blast notifications.
 * @param {ListProps} props - Component props
 * @returns {JSX.Element} - JSX element representing the ListBlastNotifications component
 */
const ListBlastNotifications: FC<ListProps> = (props) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={isSmall ? <UserFilter /> : undefined}
      aside={<AsideFilter />}
      actions={<ListActions />}
      pagination={<PaginationWithCustomEmpty />}
      title="Blast Notifications"
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid optimized empty={<EmptyList resource={'Blast Notification'} />}>
        <TextField source="id" label="ID" key="id" />,
        <TextField source="notificationTitle" label="Title" key="title" />,
        <FunctionField
          label="Content"
          key="content"
          render={(record) =>
            renderContentSliced(record?.notificationContent, 20)
          }
        />
        ,
        <FunctionField
          label="User Type"
          key="userType"
          render={(record) => renderUserType(record?.userType)}
        />
        ,
        <FunctionField
          label="Status"
          key="status"
          render={(record) => renderNotificationStatus(record?.status)}
        />
        ,
        <IsActiveSwitch key="isActive" label="Is Active?" />,
        <EditOrShowButton key="show" />,
      </Datagrid>
    </List>
  );
};

export default ListBlastNotifications;
