import {
  Edit,
  required,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  TopToolbar,
  useNotify,
  useRedirect,
  useRefresh,
} from 'react-admin';
import { Button, Grid, makeStyles } from '@material-ui/core';
import './FAQForm.css';
import { EditActions } from '@rg-admin/ra-enterprise';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';

const useStyles = makeStyles({
  card: {
    padding: 0,
    width: '100%',
  },
  disableTopMargin: {
    marginTop: '0px',
  },
  cancelButton: {
    textTransform: 'capitalize',
    margin: '0px 6px',
  },
});

const ListActions = (props: any) => {
  const { className, basePath } = props;
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
    </TopToolbar>
  );
};

export const FAQEdit = (props) => {
  const classes = useStyles();

  const clickToCancel = () => {
    props.history.push(`/FAQ`);
  };

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const successToRedirect = () => {
    notify(`Saved successfully`, 'success', {}, false);
    redirect('/FAQ');
    refresh();
  };
  const CustomToolbar = (props: any) => (
    <Toolbar {...props}>
      <SaveButton label="Save" />
      <Button
        className={classes.cancelButton}
        onClick={clickToCancel}
        variant="contained"
      >
        CANCEL
      </Button>
    </Toolbar>
  );

  return (
    <Edit
      {...props}
      title="FAQ Edit"
      onSuccess={successToRedirect}
      actions={<ListActions />}
      undoable={false}
      component="div"
    >
      <SimpleForm toolbar={<CustomToolbar />}>
        <Grid container spacing={4} className="fullWidth">
          <Grid item xs={6}>
            <TextInput validate={required()} fullWidth source="question" />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              multiline
              validate={required()}
              fullWidth
              source="answer"
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  );
};
