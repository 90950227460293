/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Badge,
  CardContent,
  Theme,
  Typography,
  useMediaQuery,
  withStyles,
} from '@material-ui/core';
import { Box } from '@mui/material';
import AVTimerIcon from '@material-ui/icons/AvTimer';
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EventsIcon from '@material-ui/icons/FormatListNumbered';
import NotificationsIcon from '@material-ui/icons/Notifications';

import {
  MenuItem,
  MenuItemCategory,
  MultiLevelMenu,
  Menu as NavigationMenu,
  useAppLocationMatcher,
  useResourceAppLocation,
} from '@rg-admin/ra-navigation';
import { useSavedQueries } from '@rg-admin/ra-preferences';
import { useSubscribeToRecordList } from '@rg-admin/ra-realtime';
import { endOfYesterday } from 'date-fns';
import querystring from 'query-string';
import { FC, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useSelector } from 'react-redux';
import { AppState } from '../../interface/types';
import categories from '../../module/demo/categories';
import invoices from '../../module/demo/invoices';
import orders from '../../module/demo/orders';
import products from '../../module/demo/products';
import stores from '../../module/demo/stores';
import { segments } from '../../module/demo/visitors/segments';
import faq from '../../module/faq';
import medicine from '../../module/medicine';
import users from '../../module/user-management';
import Iconify from '../iconify/Iconify';
import CustomMobileMenu from './CustomMobileMenu';

interface Props {
  logout?: ReactNode;
  onMenuClick?: () => void;
}

export const newCustomerFilter = querystring.stringify({
  filter: JSON.stringify({
    last_seen_gte: endOfYesterday().toISOString(),
  }),
});

export const visitorsFilter = querystring.stringify({
  filter: JSON.stringify({ nb_commands_lte: 0 }),
});

export const pendingReviewFilter = querystring.stringify({
  filter: JSON.stringify({ status: 'pending' }),
});

const useResourceChangeCounter = (resource: string): number => {
  const match = useAppLocationMatcher();
  const location = useResourceAppLocation();
  const [countEvent, setCountEvent] = useState(0);

  useSubscribeToRecordList(resource, ({ payload }) => {
    if (!payload || !payload.ids) {
      return;
    }

    let count = payload.ids.length;

    if (location && match(resource)) {
      const { record } = location && (location.values || {});
      if (!record || record.id == null) {
        return;
      }

      count = payload.ids.filter((id) => id !== record.id).length;
    }

    if (count) {
      setCountEvent((previous) => previous + count);
    }
  });

  useEffect(() => {
    if (match(resource)) {
      setCountEvent(0);
    }
  }, [match, resource]);

  return countEvent;
};

const StyledBadgeForText = withStyles((theme) => ({
  badge: {
    top: 13,
    right: 13,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);

const RenderIcon = ({ icon, size }: any) => {
  return <Iconify icon={icon} width={size} height={size} />;
};

const CustomMenu: FC<Props> = ({ logout, onMenuClick }) => {
  useSelector((state: AppState) => state.theme); // force rerender on theme change
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );

  return isXSmall ? (
    <CustomMobileMenu logout={logout} onMenuClick={onMenuClick} />
  ) : (
    <DesktopMenu onMenuClick={onMenuClick} />
  );
};

export default CustomMenu;

const DesktopMenu: FC<Props> = ({ onMenuClick }) => {
  const translate = useTranslate();

  return (
    <MultiLevelMenu variant="categories">
      <MenuItemCategory
        name="dashboard"
        to="/"
        exact
        icon={<DashboardIcon />}
        onClick={onMenuClick}
        label="ra.page.dashboard"
      />
      <MenuItemCategory
        name="users"
        icon={<users.icon />}
        onClick={onMenuClick}
        label="Users"
      >
        <UserMenu />
      </MenuItemCategory>
      <MenuItemCategory
        name="subscription"
        to="/subscription"
        icon={<RenderIcon icon="eos-icons:subscription-management" />}
        onClick={onMenuClick}
        label={'Subscription'}
      />

      <MenuItemCategory
        name="medication"
        to="/medication"
        icon={<medicine.icon />}
        onClick={onMenuClick}
        label={translate(`Medicine`, { smart_count: 2 })}
      />
      <MenuItemCategory
        name="review"
        to="/review"
        icon={<faq.icon />}
        onClick={onMenuClick}
        label={translate(`Reviews`, { smart_count: 2 })}
      />
      <MenuItemCategory
        name="symptoms"
        to="/symptoms"
        icon={
          <RenderIcon icon="streamline:interface-edit-copy-clipboard-copy-cut-paste" />
        }
        onClick={onMenuClick}
        label="Symptoms"
      />
      <MenuItemCategory
        name="notifications"
        icon={<NotificationsIcon />}
        onClick={onMenuClick}
        label="Notifications"
      >
        <NotificationMenu />
      </MenuItemCategory>
      <MenuItemCategory
        name="analytics"
        to="/analytics"
        icon={<RenderIcon icon="codicon:graph" />}
        onClick={onMenuClick}
        label={'Analytics'}
      />
      {/* <DemoMenuItem onMenuClick={onMenuClick} /> */}
    </MultiLevelMenu>
  );
};

const UserMenu = ({
  onMenuClick,
}: {
  onMenuClick?: () => void;
}): ReactElement => {
  return (
    <CardContent>
      <Typography variant="subtitle1" gutterBottom>
        Users
      </Typography>
      <NavigationMenu>
        <MenuItem
          name="users/all"
          to="/users/all"
          icon={<RenderIcon icon="fa-solid:users" />}
          onClick={onMenuClick}
          label="All Users"
        />
        {/* <MenuItem
          name="signed-up-users"
          to="/users/signed-up-users"
          icon={<RenderIcon icon="mdi:users-add" size={30} />}
          label="Signed-Up Users"
          onClick={onMenuClick}
        /> */}
        <MenuItem
          name="subscribed-users"
          to="/users/subscribed-users"
          icon={<RenderIcon icon="mdi:account-multiple-tick" size={30} />}
          label="Subscribed Users"
          onClick={onMenuClick}
        />
      </NavigationMenu>
    </CardContent>
  );
};

const NotificationMenu = ({
  onMenuClick,
}: {
  onMenuClick?: () => void;
}): ReactElement => {
  return (
    <CardContent>
      <Typography variant="subtitle1" gutterBottom>
        Notifications
      </Typography>
      <NavigationMenu>
        <MenuItem
          name="blast-notification"
          to="/blast-notification"
          icon={<RenderIcon icon="pajamas:messages" />}
          label="Blast Notifications"
          onClick={onMenuClick}
        />
        <MenuItem
          name="personalized-notification"
          to="/personalized-notification"
          icon={<RenderIcon icon="fluent:person-alert-16-regular" />}
          label="Personalized Notifications"
          onClick={onMenuClick}
        />
      </NavigationMenu>
    </CardContent>
  );
};

const DemoMenuItem = ({
  onMenuClick,
}: {
  onMenuClick?: () => void;
}): ReactElement => {
  // const { onMenuClick } = props;
  // const savedQueriesMenuItems = usePersistedQueriesMenu('products');
  const commandsChangeCount = useResourceChangeCounter('commands');
  const translate = useTranslate();

  return (
    <StyledBadgeForText badgeContent={commandsChangeCount} color="primary">
      <MenuItemCategory
        name="demo"
        icon={<products.icon />}
        onClick={onMenuClick}
        label={translate(`pos.menu.demo`, { smart_count: 1 })}
        data-testid="commands-menu"
      >
        <CardContent>
          <Typography variant="h6" gutterBottom>
            {translate(`ra.page.dashboard`, {
              smart_count: 2,
            })}
          </Typography>
          <NavigationMenu>
            <MenuItem
              name="dashboard"
              to={'/dash'}
              icon={<DashboardIcon />}
              onClick={onMenuClick}
              label="ra.page.dashboard"
            />
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            {translate(`pos.menu.sales`, { smart_count: 1 })}
          </Typography>
          <NavigationMenu>
            <StyledBadgeForText
              badgeContent={commandsChangeCount}
              color="primary"
            >
              <MenuItem
                name="commands"
                to="/commands"
                icon={<orders.icon />}
                onClick={onMenuClick}
                label={translate(`resources.commands.name`, {
                  smart_count: 2,
                })}
              />
            </StyledBadgeForText>
            <MenuItem
              name="invoices"
              to="/invoices?filter={}"
              icon={<invoices.icon />}
              onClick={onMenuClick}
              label={translate(`resources.invoices.name`, {
                smart_count: 2,
              })}
            />
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            {translate(`pos.menu.catalog`, {
              smart_count: 1,
            })}
          </Typography>
          <NavigationMenu>
            <MenuItem
              name="products"
              to="/products"
              icon={<products.icon />}
              onClick={onMenuClick}
              label={translate(`resources.products.name`, {
                smart_count: 2,
              })}
            />
            <MenuItem
              name="categories"
              to="/categories"
              icon={<categories.icon />}
              onClick={onMenuClick}
              label={translate(`resources.categories.name`, {
                smart_count: 2,
              })}
            />
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            {translate(`resources.customers.name`, {
              smart_count: 2,
            })}
          </Typography>
          <NavigationMenu>
            <MenuItem
              name="customers.all_customers"
              to={`/customers?filter={}`}
              onClick={onMenuClick}
              label={translate(`pos.menu.all_customers`, {
                smart_count: 2,
              })}
            />
            <MenuItem
              name="customers.newcomers"
              to={`/customers?${newCustomerFilter}`}
              onClick={onMenuClick}
              label={translate(`pos.menu.new_customers`, {
                smart_count: 2,
              })}
            />
            <MenuItem
              name="customers.visitors"
              to={`/customers?${visitorsFilter}`}
              onClick={onMenuClick}
              label={translate(`pos.menu.visitors`, {
                smart_count: 2,
              })}
            />
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            {translate(`resources.segments.name`, {
              smart_count: 2,
            })}
          </Typography>
          <NavigationMenu>
            {segments.map((segment) => (
              <MenuItem
                key={segment}
                name={`segments.${segment}`}
                to={`/customers?${querystring.stringify({
                  filter: JSON.stringify({ groups: segment }),
                })}`}
                onClick={onMenuClick}
                label={translate(`resources.segments.data.${segment}`, {
                  smart_count: 2,
                })}
              />
            ))}
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            {translate(`resources.reviews.name`, {
              smart_count: 2,
            })}
          </Typography>
          <NavigationMenu>
            <MenuItem
              name="reviews.all"
              to={'/reviews?filter={}'}
              icon={<CheckCircleOutlineIcon />}
              onClick={onMenuClick}
              label="pos.menu.all_reviews"
            />
            <MenuItem
              name="reviews.pending"
              to={`/reviews?${pendingReviewFilter}`}
              icon={<AVTimerIcon />}
              onClick={onMenuClick}
              label="pos.menu.pending_reviews"
            />
            <MenuItem
              name="reviews.bad"
              to={'/reviews?filter={"rating_lte": "2"}'}
              icon={<BlockIcon />}
              onClick={onMenuClick}
              label="pos.menu.bad_reviews"
            />
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            {translate(`resources.stores.name`, {
              smart_count: 2,
            })}
          </Typography>
          <NavigationMenu>
            <MenuItem
              name="stores"
              to={'/stores'}
              icon={<stores.icon />}
              onClick={onMenuClick}
              label="resources.stores.name"
            />
          </NavigationMenu>
          <Typography variant="h6" gutterBottom>
            {translate(`resources.stores.name`, {
              smart_count: 2,
            })}
          </Typography>
          <NavigationMenu>
            <MenuItem
              name="events"
              to={'/events'}
              icon={<EventsIcon />}
              onClick={onMenuClick}
              label="resources.events.name"
            />
          </NavigationMenu>
        </CardContent>
      </MenuItemCategory>
    </StyledBadgeForText>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const usePersistedQueriesMenu = (
  resource: string
): { label: string; to: string }[] => {
  const [savedQueries] = useSavedQueries(resource);
  const savedQueriesMenuItems = savedQueries.map(({ label, value }) => ({
    label,
    to: `/${resource}?${querystring.stringify({
      filter: JSON.stringify(value.filter),
      sort: value?.sort?.field,
      order: value?.sort?.order,
      page: 1,
      perPage: value.perPage,
      displayedFilters: value.displayedFilters,
    })}`,
  }));

  return savedQueriesMenuItems;
};
