import { makeStyles } from '@material-ui/core/styles';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbProps,
  ResourceBreadcrumbItems,
} from '@rg-admin/ra-navigation';
import { FC } from 'react';
import { Record, linkToRecord, useTranslate } from 'react-admin';

const CustomBreadcrumb: FC<BreadcrumbProps> = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  const editLabel = translate('ra.action.edit');
  const showLabel = translate('ra.action.show');
  const createLabel = translate('ra.action.create');
  return (
    <Breadcrumb className={classes.root} {...props}>
      <ResourceBreadcrumbItems resources={['stores', 'tours']} />
      <BreadcrumbItem name="catalog" label={translate('pos.menu.catalog', 1)}>
        <BreadcrumbItem
          name="products"
          label={translate('resources.products.name', 2)}
          to="/products"
        >
          <BreadcrumbItem
            name="edit"
            label={({ record }: { record?: Record }): string =>
              `${editLabel} ${record ? `"${record.reference}"` : '...'}`
            }
            to={({ record }: { record?: Record }): string =>
              record ? `${linkToRecord('/products', record.id)}/edit` : ''
            }
          />
          <BreadcrumbItem
            name="create"
            label={createLabel}
            to="/products/create"
          />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="categories"
          label={translate('resources.categories.name', 2)}
        >
          <BreadcrumbItem
            name="edit"
            label={({ record }: { record?: Record }): string =>
              `${editLabel} ${record ? `"${record.name}"` : '...'}`
            }
          />
        </BreadcrumbItem>
      </BreadcrumbItem>

      <BreadcrumbItem name="sales" label={translate('pos.menu.sales', 1)}>
        <BreadcrumbItem
          name="commands"
          label={translate('resources.commands.name', 2)}
          to="/commands"
        >
          <BreadcrumbItem
            name="edit"
            label={({ record }: { record?: Record }): string =>
              `${editLabel} ${record ? `"${record.reference}"` : '...'}`
            }
            to={({ record }: { record?: Record }): string =>
              record ? `${linkToRecord('/products', record.id)}/edit` : ''
            }
          />
        </BreadcrumbItem>
        <BreadcrumbItem
          name="invoices"
          label={translate('resources.invoices.name', 2)}
          to="/invoices"
        />
      </BreadcrumbItem>

      <BreadcrumbItem
        name="medication"
        label={translate('Medication', 1)}
        to="/medication"
      >
        {/* <BreadcrumbItem
          name="medication"
          label={translate('Medication', 2)}
          to="/medication"
        > */}
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Record }): string =>
            `${editLabel} ${record ? `"${record.reference}"` : '...'}`
          }
          to={({ record }: { record?: Record }): string =>
            record ? `${linkToRecord('/medication', record.id)}/edit` : ''
          }
        />
        {/* </BreadcrumbItem> */}
        <BreadcrumbItem
          name="show"
          label={showLabel}
          to={({ record }: any) =>
            record && `${linkToRecord('/songs', record?.id)}/show`
          }
        />
      </BreadcrumbItem>

      <BreadcrumbItem name="FAQ" label={'Reviews'} to="/FAQ">
        <BreadcrumbItem
          name="edit"
          label={editLabel}
          to={({ record }: { record?: Record }): string =>
            record ? `${linkToRecord('FAQ', record.id)}/edit` : ''
          }
        />
        <BreadcrumbItem
          name="show"
          label={({ record }: { record?: Record }): string =>
            `${showLabel} ${record ? `"${record.title}"` : '...'}`
          }
        />
        <BreadcrumbItem name="create" label={createLabel} to="/FAQ/create" />
      </BreadcrumbItem>
      <BreadcrumbItem name="review" label={'Reviews'} to="/review">
        <BreadcrumbItem
          name="show"
          label={({ record }: { record?: Record }): string =>
            ` ${record ? `${record.title}` : '...'}`
          }
        />
      </BreadcrumbItem>

      <BreadcrumbItem
        name="users/all"
        label={translate('resources.users.name', 2)}
        to="/users/all"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Record }): string =>
            `${editLabel} ${record ? `"${record?.name}"` : '...'}`
          }
          to={({ record }: { record?: Record }): string =>
            record ? `${linkToRecord('/users/all', record.id)}/edit` : ''
          }
        />
        <BreadcrumbItem
          name="show"
          label={({ record }: { record?: Record }): string =>
            `${record ? `${record?.name}` : '...'}`
          }
          to={({ record }: any) =>
            record && `${linkToRecord('/users/all', record?.id)}/show`
          }
        />

        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/users/all/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="users/signed-up-users"
        label="Signed-up Users"
        to="/users/signed-up-users"
      />
      <BreadcrumbItem
        name="users/subscribed-users"
        label="Subscribed Users"
        to="/users/subscribed-users"
      />

      <BreadcrumbItem
        name="customers"
        label={translate('resources.customers.name', 2)}
        to="/customers"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Record }): string =>
            `${editLabel} ${
              record ? `"${record.first_name} ${record.last_name}"` : '...'
            }`
          }
          to={({ record }: { record?: Record }): string =>
            record ? `${linkToRecord('/customers', record.id)}/edit` : ''
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/customers/create"
        />
      </BreadcrumbItem>

      <BreadcrumbItem
        name="users/subscribed-users"
        label="Subscribed Users"
        to="/users/subscribed-users"
      />

      <BreadcrumbItem
        name="subscription"
        label="Subscription"
        to="/subscription"
      >
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Record }): string =>
            `${editLabel} ${record ? `"${record.plan}"` : '...'}`
          }
          to={({ record }: { record?: Record }): string =>
            record ? `${linkToRecord('/subscription', record.id)}/edit` : ''
          }
        />
        {/* subscription show breadcrumb */}
        <BreadcrumbItem
          name="show"
          label={
            ({ record }: { record?: Record }): string =>
              `${showLabel} ${record?.plan ? `"${record.plan}"` : '...'}` // form plan name undefined ... will shown
          }
          to={({ record }: { record?: Record }): string =>
            record ? `${linkToRecord('/subscription', record.id)}/show` : ''
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/subscription/create"
        />
      </BreadcrumbItem>

      <BreadcrumbItem name="symptoms" label="Symptoms" to="/symptoms">
        <BreadcrumbItem
          name="show"
          label={({ record }: { record?: Record }): string =>
            `${record ? `${record.id}` : '...'}`
          }
          to={({ record }: any) =>
            record && `${linkToRecord('/symptoms/', record?.id)}/show`
          }
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="blast-notification"
        label="Blast Notification"
        to="/blast-notification"
      >
        <BreadcrumbItem
          name="show"
          label={({ record }: { record?: Record }): string =>
            `${record ? `${record.notificationTitle}` : '...'}`
          }
          to={({ record }: any) =>
            record &&
            `${linkToRecord(
              '/blast-notification/',
              record?.notificationTitle
            )}/show`
          }
        />
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Record }): string =>
            `${editLabel} ${record ? `"${record.notificationTitle}"` : '...'}`
          }
          to={({ record }: { record?: Record }): string =>
            record
              ? `${linkToRecord('/blast-notification', record.id)}/edit`
              : ''
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/blast-notification/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem
        name="personalized-notification"
        label="Personalized Notification"
        to="/personalized-notification"
      >
        <BreadcrumbItem
          name="show"
          label={({ record }: { record?: Record }): string =>
            `${record ? `${record.notificationData[0].title}` : '...'}`
          }
          to={({ record }: any) =>
            record &&
            `${linkToRecord(
              '/personalized-notification/',
              record?.notificationTitle
            )}/show`
          }
        />
        <BreadcrumbItem
          name="edit"
          label={({ record }: { record?: Record }): string =>
            `${editLabel} ${
              record ? `"${record.notificationData[0].title}"` : '...'
            }`
          }
          to={({ record }: { record?: Record }): string =>
            record
              ? `${linkToRecord('/personalized-notification', record.id)}/edit`
              : ''
          }
        />
        <BreadcrumbItem
          name="create"
          label={createLabel}
          to="/personalized-notification/create"
        />
      </BreadcrumbItem>
      <BreadcrumbItem name="analytics" label="Analytics" to="/analytics" />
    </Breadcrumb>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    fontSize: 'small',
    // Display the Breadcrumb over the custom Layout of some pages by adding a zIndex and a maxWidth
    // @see "src/products/ProductList.tsx" or "src/visitors/VisitorList.tsx"
    maxWidth: '700px',
    zIndex: 1,
    '& a': {
      pointerEvents: 'visible',
    },
  },
}));

export default CustomBreadcrumb;
