//react
import { useEffect, useState } from 'react';

//react-admin
import { Labeled, SelectField, TextField, useRecordContext } from 'react-admin';

//mui
import { Grid } from '@mui/material';

const SubscriptionDetailsShowForm = (props: any) => {
  // state
  const [planType, setPlanType] = useState<string>('');

  // hook
  const record = useRecordContext();

  useEffect(() => {
    setPlanType(record?.type); // get the subscription plan type from record
  }, [record]);

  return (
    <Grid container columnSpacing={2} width={{ xs: '100%', lg: '50%' }}>
      <Grid item xs={6}>
        <Labeled label="Plan name">
          <SelectField
            source="plan"
            choices={[
              { id: 'FREEMIUM', name: 'Freemium' },
              { id: 'MONTHLY', name: 'Monthly' },
              { id: 'YEARLY', name: 'Yearly' },
            ]}
          />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Plan Type">
          <SelectField
            source="type"
            choices={[
              { id: 'FREE', name: 'Free' },
              { id: 'PAID', name: 'Paid' },
            ]}
          />
        </Labeled>
      </Grid>

      {planType === 'PAID' && (
        <Grid item xs={6}>
          <Labeled label="Price">
            <>
              <span>$</span>
              <TextField source="price" label="Price" />
            </>
          </Labeled>
        </Grid>
      )}
      <Grid item xs={6}>
        <Labeled label="Details">
          <TextField source="planDetails" label="Details" />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="In App Reference">
          <TextField source="InAppReference" label="In App Reference" />
        </Labeled>
      </Grid>
      <Grid item xs={6}>
        <Labeled label="Status">
          <SelectField
            source="isActive"
            choices={[
              { id: 1, name: 'Active' },
              { id: 0, name: 'Inactive' },
            ]}
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

export default SubscriptionDetailsShowForm;
