import { FC } from 'react';
import { FilterList, FilterListItem } from 'react-admin';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {
  endOfYesterday,
  format,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from 'date-fns';

const AccountCreated: FC = () => (
  <FilterList
    label="resources.users.filters.account_created"
    icon={<AccessTimeIcon />}
  >
    <FilterListItem
      label="resources.users.filters.today"
      value={{
        startDate: format(new Date().toISOString(), 'YYYY-MM-DD'),
        endDate: undefined,
      }}
    />
    <FilterListItem
      label="resources.users.filters.this_week"
      value={{
        startDate: format(startOfWeek(new Date()).toISOString(), 'YYYY-MM-DD'),
        endDate: undefined,
      }}
    />
    <FilterListItem
      label="resources.users.filters.last_week"
      value={{
        startDate: format(
          subWeeks(startOfWeek(new Date()), 1).toISOString(),
          'YYYY-MM-DD'
        ),
        endDate: format(startOfWeek(new Date()).toISOString(), 'YYYY-MM-DD'),
      }}
    />
    <FilterListItem
      label="resources.users.filters.this_month"
      value={{
        startDate: format(startOfMonth(new Date()).toISOString(), 'YYYY-MM-DD'),
        endDate: undefined,
      }}
    />
    <FilterListItem
      label="resources.users.filters.last_month"
      value={{
        startDate: format(
          subMonths(startOfMonth(new Date()), 1).toISOString(),
          'YYYY-MM-DD'
        ),
        endDate: format(startOfMonth(new Date()).toISOString(), 'YYYY-MM-DD'),
      }}
    />
    <FilterListItem
      label="resources.users.filters.earlier"
      value={{
        startDate: undefined,
        endDate: format(
          subMonths(startOfMonth(new Date()), 1).toISOString(),
          'YYYY-MM-DD'
        ),
      }}
    />
  </FilterList>
);

export default AccountCreated;
