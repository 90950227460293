// react
import { FC } from 'react';

// react admin
import {
  CreateButton,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  List,
  ListProps,
  SearchInput,
  TopToolbar,
} from 'react-admin';

// mui
import { Theme, useMediaQuery } from '@material-ui/core';

// rg admin
import { FilterWithSave } from '@rg-admin/ra-preferences';

// Components
import EmptyList from '../../component/EmptyList';
import PaginationWithCustomEmpty from '../../component/PaginationWithCustomEmpty';
import CustomBreadcrumb from '../../component/layout/Breadcrumb';
import AsideFilter from './components/AsideFilter';
import IsActiveSwitch from './components/IsActiveSwitch';

// helper function
import {
  renderContentSliced,
  renderUserType,
} from '../symptom/components/helperFunction';

// utils
import { dateFormat } from '../../utils/helper';

// ----------------------------------------------------------------------------------------------------

// UserFilter component for filtering the list of users
const UserFilter: FC = (props: any) => (
  <FilterWithSave {...props}>
    <SearchInput source="searchKey" alwaysOn />
  </FilterWithSave>
);

// component for list actions (top toolbar)
const ListActions = (props) => {
  const { className } = props;
  return (
    <TopToolbar className={className}>
      <CustomBreadcrumb variant="actions" />
      <CreateButton />
    </TopToolbar>
  );
};

/**
 * ListPersonalizedNotifications component for displaying a list of personalized notifications.
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX element representing the ListPersonalizedNotifications component
 */
const ListPersonalizedNotifications: FC<ListProps> = (props) => {
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={isSmall ? <UserFilter /> : undefined}
      aside={<AsideFilter />}
      actions={<ListActions />}
      pagination={<PaginationWithCustomEmpty />}
      title="Personalized Notifications"
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid
        rowClick="edit"
        empty={<EmptyList resource={'Personalized Notification'} />}
      >
        <TextField source="id" label="ID" key="id" />,
        <FunctionField
          label="Triggers"
          key="triggers"
          render={(record) => renderContentSliced(record?.triggerValue, 100)}
        />
        <FunctionField
          label="Title"
          key="title"
          render={(record) =>
            renderContentSliced(record?.notificationData[0].title, 20)
          }
        />
        ,
        {/* <FunctionField
          label="Content"
          key="content"
          render={(record) => renderContentSliced(record?.notificationContent)}
        />, */}
        <FunctionField
          sortBy="createdAt"
          label="Created At"
          render={(record: any) => dateFormat(record?.createdAt, 'MM-DD-YYYY')}
          key="createdAt"
          style={{ whiteSpace: 'nowrap' }}
        />
        ,
        <FunctionField
          label="User Type"
          key="userType"
          render={(record) => renderUserType(record?.userType)}
        />
        ,
        <IsActiveSwitch key="isActive" label="Is Active?" />,
        <EditButton key="show" />,
      </Datagrid>
    </List>
  );
};

export default ListPersonalizedNotifications;
