import Iconify from './iconify/Iconify';

const Loading = ({ width, height, sx }) => (
  <Iconify
    sx={{
      color: '#9A9B9E',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      ...sx,
    }}
    icon="svg-spinners:bars-scale-middle"
    width={width}
    height={height}
  />
);
export default Loading;
