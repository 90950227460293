import { CardContent, Card as MuiCard, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import { FC } from 'react';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import AccountCreated from '../../component/AccountCreated';
import AgeFilter from '../../component/AgeFilter';
import GenderFilter from '../../component/GenderFilter';

const Card = withStyles((theme) => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch source={'searchKey'} />
      <Typography variant="body2" style={{ color: 'grey' }}>
        Search by Name, Email
      </Typography>
      <FilterList
        label="resources.users.filters.status"
        icon={<VerifiedUserOutlinedIcon />}
      >
        <FilterListItem
          label="Active"
          value={{
            status: true,
          }}
        />
        <FilterListItem
          label="Inactive"
          value={{
            status: false,
          }}
        />
      </FilterList>

      <AccountCreated />

      <GenderFilter />

      <AgeFilter />
    </CardContent>
  </Card>
);

export default Aside;
