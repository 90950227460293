import { FC } from 'react';
import { FilterList, FilterListItem, FilterLiveSearch } from 'react-admin';
import { CardContent, Card as MuiCard, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@mui/material';
import Iconify from '../../../component/iconify/Iconify';
import { Symptoms } from '../../../utils/constants';

const Card = withStyles((theme) => ({
  root: {
    flexShrink: 0,
    [theme.breakpoints.up('sm')]: {
      order: -1,
      width: '15em',
      marginRight: '1em',
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))(MuiCard);

const Aside: FC = () => (
  <Card>
    <CardContent>
      <FilterLiveSearch source={'searchKey'} />
      <Typography variant="body2" style={{ color: 'grey' }}>
        Search by Names & Symptoms
      </Typography>
      <FilterList
        label="Symptoms"
        icon={
          <RenderIcon icon="streamline:interface-edit-copy-clipboard-copy-cut-paste" />
        }
      >
        <Box
          sx={{
            maxHeight: 365,
            overflowX: 'auto',
            '&::-webkit-scrollbar': {
              width: 5,
            },
            '&::-webkit-scrollbar-track': {
              borderRadius: 2,
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#c9cacb',
              borderRadius: 2,
            },
          }}
        >
          {Symptoms.map((symptom) => (
            <FilterListItem
              key={symptom.id}
              label={symptom.label}
              value={{
                symptoms: symptom.label,
              }}
            />
          ))}
        </Box>
      </FilterList>
    </CardContent>
  </Card>
);

export default Aside;

const RenderIcon = ({ icon }: any) => {
  return <Iconify icon={icon} />;
};
