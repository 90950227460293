import { SodiumPlus, X25519PublicKey, X25519SecretKey } from 'sodium-plus';

export default function decryption(value: any) {
  return new Promise(async (resolve, reject) => {
    const sodium = await SodiumPlus.auto();
    const nonceKey: any = process.env.REACT_APP_SODIUM_NONCE_KEY;
    const serverKey: any = process.env.REACT_APP_SERVER_SECRETKEY;

    value = Buffer.from(value, 'base64');
    const nonce = Buffer.from(nonceKey, 'hex');
    const clientKey: any = localStorage.getItem('secretKey');

    const imported_clientSecret = await Buffer.from(clientKey, 'base64');
    const imported_serverPublic = await Buffer.from(serverKey, 'base64');

    const decrypted = await sodium.crypto_box_open(
      value,
      nonce,
      new X25519SecretKey(imported_clientSecret),
      new X25519PublicKey(imported_serverPublic)
    );
    resolve(decrypted.toString());
  });
}
